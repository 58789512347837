import { PlacesDetails } from "./PlacesDetails";

const MODEL_NAMES = {
    "A453": "smart fortwo cabrio",
    "C117": "Mercedes-Benz CLA",
    "C451": "smart fortwo electric",
    "C453": "smart fortwo",
    "C4539": "smart fortwo electric (neu)",
    "W176": "Mercedes-Benz A-Klasse",
    "W177": "Mercedes-Benz A-Klasse (neu)",
    "W242": "Mercedes-Benz B-Klasse electric",
    "W246": "Mercedes-Benz B-Klasse",
    "W453": "smart forfour",
    "W4539": "smart forfour electric",
    "X156": "Mercedes-Benz GLA",
    "BMW_I3": "BMW i3",
    "BMW_1ER": "BMW 1er",
    "BMW_X1": "BMW X1",
    "BMW_X2": "BMW X2",
    "BMW_2ER_CABRIO": "BMW 2er Cabrio",
    "BMW_2ER_ACTIVE_TOURER": "BMW 2er Active Tourer",
    "MINI_CABRIO": "Mini Cabrio",
    "MINI_3-TUERER": "Mini 3-Türer",
    "MINI_5-TUERER": "Mini 5-Türer",
    "MINI_COUNTRYMAN": "Mini Countryman",
    "MINI_CLUBMAN": "Mini Clubman",
};

export class PlacesDetailsCar extends PlacesDetails {
    public getVendorName() {
        let name = 'CarSharing';
        this.categories.forEach((cat) => {
            if (cat.id === 'mv.car.stattauto') {
                name = 'STATTAUTO Station';
            }
            if (cat.id === 'mv.car.stattautoflexy') {
                name = 'STATTAUTO Flexy';
            }
            if (cat.id === 'mv.car.drivenow') {
                name = 'DriveNow';
            }
            if (cat.id === 'mv.car.sharenow') {
                name = 'ShareNow';
            }
            if (cat.id === 'mv.car.beezero') {
                name = 'Beezero';
            }
            if (cat.id === 'mv.car.car2go') {
                name = 'Car2Go';
            }
        });
        return name;
    }

    public getModelName(): string {
        if (!this.payload || !this.payload.carType) {
            return '';
        }
        if (MODEL_NAMES[this.payload.carType] !== undefined) {
            return MODEL_NAMES[this.payload.carType];
        } else {
            return this.payload.carType;
        }
    }

    public getCarID(): string {
        if (this.payload && this.payload.licensePlate) {
            return this.payload.licensePlate;
        } else {
            return '';
        }
    }
}
