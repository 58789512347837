import {Component, AfterViewInit, ElementRef, ViewChild, TemplateRef} from '@angular/core';
import {
    RouteDefinition, IShortInfoComponentFeatureSets, RoutingMode, LamppostCurrentValues
} from "../interfaces";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {IRoutable} from "../classes/IRoutable";
import {MVVRoutingService} from "../apis/mvv-routing.service";
import {PlacesDetails} from "../classes/PlacesDetails";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {Messages, ShortInfoMessages} from "../messages/messages";
import {PlacesEntry} from "../classes/PlacesEntry";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";
import {Lamppost} from "../classes/Lamppost";
import {SmartCityService} from "../apis/smart-city.service";
import { PlacesDetailsEScooter } from '../classes/PlacesDetailsEScooter';
import { PlacesDetailsCar } from '../classes/PlacesDetailsCar';
import { PlacesDetailsErdgas } from '../classes/PlacesDetailsErdgas';
import { PlacesDetailsBike } from '../classes/PlacesDetailsBike';

@Component({
    selector: 'short-info-box',
    templateUrl: 'short-info.component.html',
    styles: [],
})
export class ShortInfoComponent implements AfterViewInit {
    public activeContent: IShortInfoComponentContent = null;
    public allContents: IShortInfoComponentFeatureSets;
    public nextEntry: IShortInfoComponentContent = null;
    public prevEntry: IShortInfoComponentContent = null;
    public visible: boolean = false;
    public activeRoute: RouteDefinition = null;
    public activeRouteTime: string = "";
    public activeRouteLength: string = "";
    public expanded: boolean = false;
    public routingActive: boolean;
    public placeDetails: PlacesDetails = null;
    public lamppost: Lamppost = null;
    public lamppost_capabilities = [];
    public lamppost_values: LamppostCurrentValues = null;
    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;

    public activePlaceDetails: PlacesDetails = null;
    public activePlaceDetailsErdgas: PlacesDetailsErdgas = null;
    public activePlaceDetailsCar: PlacesDetailsCar = null;
    public activePlaceDetailsEScooter: PlacesDetailsEScooter = null;
    public activePlaceDetailsBike: PlacesDetailsBike = null;

    @ViewChild('entryTmpl') entryTmpl: TemplateRef<any>;
    @ViewChild('placeTmpl') placeTmpl: TemplateRef<any>;
    @ViewChild('carTmpl') carTmpl: TemplateRef<any>;
    @ViewChild('stattautoTmpl') stattautoTmpl: TemplateRef<any>;
    @ViewChild('mvgTmpl') mvgTmpl: TemplateRef<any>;
    @ViewChild('mobilityStationTmpl') mobilityStationTmpl: TemplateRef<any>;
    @ViewChild('chargingStationTmpl') chargingStationTmpl: TemplateRef<any>;
    @ViewChild('taxiStationTmpl') taxiStationTmpl: TemplateRef<any>;
    @ViewChild('erdgasTmpl') erdgasTmpl: TemplateRef<any>;
    @ViewChild('bikeFreefloaterTmpl') bikeFreefloaterTmpl: TemplateRef<any>;
    @ViewChild('bikeStationTmpl') bikeStationTmpl: TemplateRef<any>;
    @ViewChild('etrikeTmpl') etrikeTmpl: TemplateRef<any>;
    @ViewChild('pedelecTmpl') pedelecTmpl: TemplateRef<any>;
    @ViewChild('lamppostTmpl') lamppostTmpl: TemplateRef<any>;
    @ViewChild('eScooterTmpl') eScooterTmpl: TemplateRef<any>;

    constructor(private _mvvRouting: MVVRoutingService,
                private _routingService: RoutingService,
                private _appState: AppStateService,
                private _ep: EchtzeitproxyService,
                private _smartcity: SmartCityService,
                private elementRef: ElementRef) {

        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }

    private static addClass(element: Element, className: string) {
        let classes: string[] = element.getAttribute("class").split(" "),
            found: boolean = false;
        for (let i = 0; i < classes.length; i++) {
            if (classes[i].toLowerCase() == className.toLowerCase()) {
                found = true;
            }
        }
        if (!found) {
            classes.push(className);
        }
        element.setAttribute("class", classes.join(" "));
    }

    private static removeClass(element: Element, className: string) {
        let classes: string[] = element.getAttribute("class").split(" "),
            newClasses: string[] = [];
        for (let i = 0; i < classes.length; i++) {
            if (classes[i].toLowerCase() != className.toLowerCase()) {
                newClasses.push(classes[i]);
            }
        }
        element.setAttribute("class", newClasses.join(" "));
    }

    public getTemplate(entry: IShortInfoComponentContent) {
        if (!entry) {
            return this.entryTmpl;
        } else switch (entry.getClassType()) {
            case 'mvg_station':
                return this.mvgTmpl;
            case 'mobility_station':
                return this.mobilityStationTmpl;
            case 'charging_station':
                return this.chargingStationTmpl;
            case 'taxi':
                return this.taxiStationTmpl;
            case 'erdgas':
                return this.erdgasTmpl;
            case 'place':
                return this.placeTmpl;
            case 'car':
                let carPlace: PlacesEntry = <PlacesEntry> entry;
                if (carPlace.categories.find(cat => cat.id === 'mv.car.stattauto')) {
                    return this.stattautoTmpl;
                } else {
                    return this.carTmpl;
                }
            case 'escooter':
                return this.eScooterTmpl;
            case 'bike':
                let place: PlacesEntry = <PlacesEntry> entry;
                if (place.categories.find(cat => cat.id == 'mv.etrike')) {
                    return this.etrikeTmpl;
                } else if (place.categories.find(cat => cat.id == 'mv.pedelec')) {
                    return this.pedelecTmpl;
                } else if (place.categories.find(cat => cat.id == 'mv.bike.station' || cat.id == 'mv.pedelec')) {
                    return this.bikeStationTmpl;
                } else {
                    return this.bikeFreefloaterTmpl;
                }
            case 'lamppost':
                return this.lamppostTmpl;
            default:
                return this.entryTmpl;
        }
    }

    private setPos(offset: number, animate: boolean, checkIosScrollBug: boolean) {
        if (Math.abs(this.lastDeltaX - offset) > 100 && checkIosScrollBug) {
            console.log("Aborting setPos, it's probably an iPhone Scroll bug in the departure information");
            return;
        }

        let element: Element = this.elementRef.nativeElement.getElementsByClassName("short-info-box-list").item(0);

        ShortInfoComponent.removeClass(element, "animate");
        if (animate) {
            ShortInfoComponent.addClass(element, "animate");
        }

        let offsetPercent: string = (offset * 100 / (window.innerWidth * 3)).toString().replace(/,/, '.') + "%";
        let csstransform: string = "translate3d(" + offsetPercent + ",0,0)";
        element.setAttribute("style", "transform: " + csstransform);

        this.lastDeltaX = offset;
    }

    public isPannable(): boolean {
        if (this.expanded) {
            return false;
        }
        if (!this.activeContent) {
            return false;
        }
        if (this.allContents[this.activeContent.getClassType()].length <= 1) {
            return false;
        }
        return true;
    }

    private lastDeltaX = 0;

    public panRight(ev: any): void {
        if (!this.isPannable()) {
            return;
        }
        this.setPos(parseInt(ev.deltaX), false, true);
    }

    public panLeft(ev: any): void {
        if (!this.isPannable()) {
            return;
        }
        this.setPos(parseInt(ev.deltaX), false, true);
    }

    public panEnd(ev: any): void {
        if (!this.isPannable()) {
            return;
        }
        let off = (ev.deltaX / window.innerWidth);
        if (off < -0.3) {
            this.setPos(-window.innerWidth, true, false);
            setTimeout(() => {
                this._appState.openPlaceInShortInfo(this.nextEntry);
                this.setPos(0, false, false);
            }, 300);
        } else if (off > 0.3) {
            this.setPos(window.innerWidth, true, false);
            setTimeout(() => {
                this._appState.openPlaceInShortInfo(this.prevEntry);
                this.setPos(0, false, false);
            }, 300);
        } else {
            this.setPos(0, true, false);
        }
    }

    public setNext(): void {
        this.setPos(window.innerWidth, true, false);
        setTimeout(() => {
            this._appState.openPlaceInShortInfo(this.prevEntry);
            this.setPos(0, false, false);
        }, 300);
    }

    public setPrev(): void {
        this.setPos(-window.innerWidth, true, false);
        setTimeout(() => {
            this._appState.openPlaceInShortInfo(this.nextEntry);
            this.setPos(0, false, false);
        }, 300);
    }

    public setNextPrev(): void {
        if (!this.activeContent) {
            this.nextEntry = null;
            this.prevEntry = null;
            return;
        }
        let contents: IShortInfoComponentContent[] = this.allContents[this.activeContent.getClassType()];
        if (contents.length == 0) {
            this.nextEntry = null;
            this.prevEntry = null;
            return;
        }
        let foundIndex = -1;
        for (let i = 0; i < contents.length; i++) {
            if (contents[i].getId() == this.activeContent.getId()) {
                foundIndex = i;
            }
        }

        if (foundIndex > -1) {
            this.nextEntry = contents[(foundIndex + 1) % contents.length];
            this.prevEntry = contents[(foundIndex - 1 + contents.length) % contents.length];
        } else {
            this.nextEntry = contents[0];
            this.prevEntry = contents[contents.length - 1];
        }
    }

    /*
     openLink(data: PlacesEntry) {
     let newwin = window.open(data.mhpLink, '_blank');
     newwin.opener = null;
     }
     */

    public isLamppostExpandable() {
        let lamppost = <Lamppost>this.activeContent;
        return (lamppost.capabilities.length > 0);
    }

    public expandView() {
        this._appState.shortInfoComponentExpand(true);
    }

    public closeDetails(): void {
        this._appState.shortInfoComponentExpand(false);
    }

    public close(): void {
        this._appState.closePrettyMuchEverything();
    }

    public callRouting(data: IRoutable) {
        if (data.getRoutingTitle) {
            this._routingService.activate(data);
        } else {
            this._routingService.activate();
        }
    }

    public routingChanged() {
        if (this.activeContent.getRoutableObject()) {
            this._routingService.activate(this.activeContent.getRoutableObject());
        } else {
            this._routingService.activate();
        }
    }

    public ngAfterViewInit() {
        this._routingService.active$.subscribe((active: RoutingMode) => {
            this.routingActive = (active !== RoutingMode.NONE);
            this.visible = (this.activeContent && !this.routingActive);
        });
        this._appState.shortInfoComponentActive$.subscribe((activeContent: IShortInfoComponentContent) => {
            this.activeContent = activeContent;
            this.setNextPrev();
            this.visible = (this.activeContent && !this.routingActive);
            this.lamppost_capabilities = [];

            if (this.activeRoute && (!activeContent || this.activeRoute.to.getId() != activeContent.getId())) {
                this.activeRoute = null;
                this.activeRouteTime = "";
                this.activeRouteLength = "";
            }

            if (this.activeContent && this.activeContent.getClassType() == 'lamppost') {
                let lamppost: Lamppost = <Lamppost>this.activeContent;
                this.lamppost_capabilities = lamppost.capabilities.map((cap) => {
                    return {
                        'id': cap,
                        'icon': Lamppost.getCapabilityIconUrl(cap),
                        'title': Lamppost.getCapabilityName(cap, this.currentLanguage),
                    };
                }).filter((cap) => {
                    return (cap.id !== 'humidity' && cap.id !== 'temperature');
                });
                this.lamppost_values = null;
                this._smartcity.getLamppostCurrentValues(lamppost).subscribe((data) => {
                    this.lamppost_values = data;
                });
            }
            const loadDetails = ['charging_station', 'car', 'place', 'taxi', 'erdgas', 'escooter'];
            if (this.activeContent && loadDetails.indexOf(this.activeContent.getClassType()) !== -1) {
                this._ep.loadDetails(this.activeContent.getId(), this.currentLanguage)
                    .subscribe((details: PlacesDetails) => {
                        this.placeDetails = details;
                        this.activePlaceDetailsCar = (this.activeContent?.getClassType() === 'car' ? details as PlacesDetailsCar : null);
                        this.activePlaceDetailsErdgas = (this.activeContent?.getClassType() === 'erdgas' ? details as PlacesDetailsErdgas : null);
                        this.activePlaceDetailsBike = (this.activeContent?.getClassType() === 'bike' ? details as PlacesDetailsBike : null);
                        this.activePlaceDetailsEScooter = (this.activeContent?.getClassType() === 'escooter' ? details as PlacesDetailsEScooter : null);
                    });
            }
        });
        this._routingService.route$.subscribe((activeRoute: RouteDefinition) => {
            this.activeRoute = activeRoute;
            if (this.activeRoute && this.activeRoute.length > 0) {
                this.activeRouteTime = Math.ceil(this.activeRoute.duration / 60).toString() + ' Minuten';
                if (this.activeRoute.length >= 1000) {
                    let km: number = Math.floor(this.activeRoute.length / 1000),
                        m: number = (this.activeRoute.length - km * 1000);
                    this.activeRouteLength = km.toString() + ',' + Math.floor(m / 100).toString() + 'km';
                } else {
                    this.activeRouteLength = Math.round(this.activeRoute.length).toString() + 'm';
                }
                if (this.activeRoute.hints != '') {
                    this.activeRouteTime += "<br> " + this.activeRoute.hints;
                }
            } else {
                this.activeRouteTime = '';
                this.activeRouteLength = '';
            }
        });
        this._appState.shortInfoComponentContents$.subscribe((contents: IShortInfoComponentFeatureSets) => {
            this.allContents = contents;
            this.setNextPrev();
        });
        this._appState.shortInfoComponentExpanded$.subscribe((expanded: boolean) => {
            this.expanded = expanded;
            this.placeDetails = null;
            this.lamppost = null;
            if (expanded) {
                if (this.activeContent.getClassType() == 'place') {
                    this._ep.loadDetails(this.activeContent.getId(), this.currentLanguage)
                        .subscribe((details: PlacesDetails) => {
                            this.placeDetails = details;
                        });
                }
                if (this.activeContent.getClassType() == 'lamppost') {
                    this.lamppost = <Lamppost>this.activeContent;
                }
            }
        });
        this._appState.activePlaceDetails$.subscribe((details) => {
            this.activePlaceDetails = details;
            this.activePlaceDetailsCar = (this.activeContent?.getClassType() === 'car' ? details as PlacesDetailsCar : null);
            this.activePlaceDetailsErdgas = (this.activeContent?.getClassType() === 'erdgas' ? details as PlacesDetailsErdgas : null);
            this.activePlaceDetailsBike = (this.activeContent?.getClassType() === 'bike' ? details as PlacesDetailsBike : null);
            this.activePlaceDetailsEScooter = (this.activeContent?.getClassType() === 'escooter' ? details as PlacesDetailsEScooter : null);
        })
    }
}
