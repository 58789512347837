<div class="head">
    <ul class="type-list">
        <li [class.active]="chargingFilter == 'schuko'" *ngIf="hasSchuko">
            <img src="images/mobility_laden_schuko.svg" alt="Schuko" (click)="toggleChargingFilter('schuko')">
        </li>
        <li [class.active]="chargingFilter == 'combo'" *ngIf="hasCombo">
            <img src="images/mobility_laden_combo.svg" alt="Typ2" (click)="toggleChargingFilter('combo')">
        </li>
        <li [class.active]="chargingFilter == 'typ2'" *ngIf="hasTyp2">
            <img src="images/mobility_laden_typ2.svg" alt="Typ2" (click)="toggleChargingFilter('typ2')">
        </li>
        <li [class.active]="chargingFilter == 'chademo'" *ngIf="hasChademo">
            <img src="images/mobility_laden_chademo.svg" alt="Typ2" (click)="toggleChargingFilter('chademo')">
        </li>
    </ul>
</div>
<ul class="charging entry-list">
    <li *ngFor="let charging of filteredChargers">
        <div class="icon">
            <img src="images/mobility_laden_typ2.svg" alt="Station" *ngIf="charging.standard == 'IEC_62196_T2'">
            <img src="images/mobility_laden_schuko.svg" alt="Station" *ngIf="charging.standard == 'DOMESTIC_F'">
            <img src="images/mobility_laden_combo.svg" alt="Station" *ngIf="charging.standard == 'IEC_62196_T2_COMBO'">
            <img src="images/mobility_laden_chademo.svg" alt="Station" *ngIf="charging.standard == 'Chademo'">
        </div>
        <div class="title">
            <div class="title">{{ charging.name }}</div>
            <div class="charging-id">{{ charging.id }}</div>
        </div>
        <div class="availability">
            <span *ngIf="!charging.available">{{ messages.charging_not_available }}</span>
        </div>
        <!--
        <div class="order">
            <a href="">{{ messages.mobility_charging_order }}</a>
        </div>
        -->
    </li>
</ul>
