import {Messages} from "./messages";

export const MESSAGES_DE: Messages =
    {
        language: "de",
        search: {
            none: "Nichts gefunden",
            placeholder: "Was suchen Sie?",
        },
        sidebar: {
            title: "Karte auswählen",
            layer_mvv: "Nahverkehr / MVV",
            layer_rad: "MVG Rad",
            layer_escooter: "E-Scooter",
            layer_tour: "Tour-Vorschlag",
            layer_charging_stations: "Gastankstellen",
            tile_basis: "Kartenansicht",
            title_tiles: "Karte",
            tile_hausnummern: "Hausnummern",
            tile_luftbild: "Satellit",
            impressum: "Impressum",
            datenschutz: "Datenschutz",
            reset_categories: "Karte zurücksetzen",
            welcome_marker: "Willkommen in München"
        },
        routing: {
            title: "Route berechnen",
            from: "Start",
            from_placeholder: "Startpunkt auswählen",
            to: "Ziel",
            to_placeholder: "Ziel auswählen",
            public_title: "ÖPNV-Verbindungen",
            departure: "Abfahrt",
            calculating_route: "Berechne die Route...",
            autosuggest: "Vorschläge",
            show_route: "Route anzeigen",
        },
        shortinfo: {
            time_rel_hours: "Std.",
            time_rel_minutes: "Min.",
            opening_hours: "Heute geöffnet",
            bike_free: 'MVG Rad',
            bike_station: 'MVG Radstation',
            pedelec_station: 'MVG eRadstation',
            etrike_station: 'MVG eTrikestation',
            bike_no: 'MVG Rad-Nr.',
            bikes_available_pre: 'Noch',
            bikes_available: 'MVG Räder verfügbar',
            bike_available: 'MVG Rad verfügbar',
            pedelec_no: 'MVG eRad-Nr.',
            pedelecs_available_pre: 'Noch',
            pedelecs_available: 'MVG eRäder verfügbar',
            pedelec_available: 'MVG eRad verfügbar',
            etrike_no: 'MVG eTrike-Nr.',
            etrikes_available_pre: 'Noch',
            etrikes_available: 'MVG eTrikes verfügbar',
            etrike_available: 'MVG eTrike verfügbar',
            taxis_available_pre: 'Noch',
            taxis_available: 'Taxis verfügbar',
            taxis_available_none: 'Leider kein Taxi verfügbar',
            taxi_available: 'Taxi verfügbar',
            departures_loading: "Lade Abfahrtzeiten...",
            departures_next: "Nächste Abfahrten",
            departure_in: "In",
            stattauto_loading: "Lade Stattautos...",
            mobility_tab_public: "ÖPNV",
            mobility_tab_bike: "MVG Rad",
            mobility_tab_charging: "Gastankstelle",
            mobility_tab_car: "Carsharing",
            mobility_tab_districtbox: "Quartiersbox",
            mobility_bike_available_1: "1 Rad verfügbar",
            mobility_bike_available_x: "Räder verfügbar",
            mobility_bike_order: "Rad reservieren",
            mobility_charging_order: "Jetzt laden",
            mobility_car_order: "Beim Anbieter buchen",
            charging_not_available: "Nicht verfügbar",
        },
        details: {
            back: 'Zurück',
            loading: 'Lade',
            open_website: 'Website',
            open_mvg: 'MVG Anfahrt',
            open_telephone: 'Telefon',
            open_photoupload: 'Foto-Upload',
        },
        mwlan: {
            exploreTitle: 'München entdecken',
            topcatTourism: 'Tourismus',
            topcatMobility: 'Mobilität',
            topcatBranchen: 'Leben',
            topcatSmartServices: 'Smart Services',
            catSights: 'Sehenswürdigkeiten',
            catRestaurants: 'Restaurants',
            catPublicTransport: 'ÖPNV',
            catMuseum: 'Museen',
            catGuides: 'Stadtführungen',
            catBuildings: 'Bauwerke und Denkmäler',
            catRundfahrten: 'Stadtrundfahrten',
            catParks: "Parks und Gärten",
            catChurches: 'Kirchen und Klöster',
            catATM: 'Geldautomaten',
            catBikesharing: 'MVG Rad',
            catCarsharing: 'Carsharing',
            catMobilityStation: 'Mobilitätsstation',
            catDistrictbox: 'Quartiersboxen',
            catCharging: 'Gastankstelle',
            catLampposts: 'Intelligente Lichtmasten',
            catShopping: 'Einkaufen/Shopping',
            catDoctors: 'Ärzte',
            catPharmacy: 'Apotheken',
            catCafes: 'Cafés',
            catMWlan: 'M-WLAN Hotspots',
            catSensors: 'Sensoren',
            catStele: 'Orientierungsstelen',
            catTaxi: 'Taxi',
        }
    };
