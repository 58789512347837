import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Marker, Map, MarkerOptions } from 'mapbox-gl';


const MIN_ZOOM = 12;

//const MOBILITY_MIN_ZOOM = 14; // Synch map-carsharing, map-charging, map-bikesharing and map-mvv
//const MOBILITY_ALL_MIN_ZOOM = 17; // Synch map-carsharing, map-charging,map-bikesharing and map-mvv

@Injectable()
export class CarSharingLayerService {
    private map: Map;
    private foundCarLocations: PlacesEntry[] = [];
    private carActiveData: PlacesEntry = null;
    private markers: { [id: string]: Marker } = {};
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    //private Z_INDEX_OFFSET: 0;

    constructor(private _mapState: MapStateService,
                private _mvgState: MVGStateService,
                private _appState: AppStateService,
                private _epBBLoader: EpBoundingboxLoaderService,
                private _routingService: RoutingService) {
    }

    public init(map: Map) {
        this.map = map;

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() === 'car') {
                this.carActiveData = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.carActiveData = null;
            }
            this.markerCollection.setActiveElement(this.carActiveData);
        });

        this._epBBLoader.setListenerCategories('cars', []).subscribe((data) => {
            this.foundCarLocations = data.places;
            this._appState.placesLoaded('car', this.foundCarLocations);
            this.showCurrentMarkers();
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.carsharingActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.carsharingActive$.subscribe(this.showCurrentMarkers.bind(this));

        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        if (this._mvgState.carsharingActive$.getValue()) {
            if (this._mapState.zoom$.getValue() >= MIN_ZOOM) {
                this._epBBLoader.setListenerCategories('cars', ['mv.car'])
            } else {
                this._epBBLoader.setListenerCategories('cars', [])
            }
        } else {
            this._epBBLoader.setListenerCategories('cars', [])
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }
    }

    public addInactiveMarker(car: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        icon.classList.add('carsharing-marker');
        icon.classList.add('inactive');
        icon.style.width = '30px';
        icon.style.height = '42px';

        icon.src = null;
        for (let i in car.categories) {
            if (car.categories[i].id == 'mv.car.stattauto') {
                icon.src = 'mvgmore/ic_marker_station_stattauto@3x.png';
            }
            if (car.categories[i].id == 'mv.car.stattautoflexy') {
                icon.src = 'mvgmore/ic_marker_stattautoflexy.png';
            }
            if (car.categories[i].id == 'mv.car.car2go') {
                icon.src = 'mvgmore/ic_marker_car2go.png';
            }
            if (car.categories[i].id == 'mv.car.beezero') {
                icon.src = 'mvgmore/logo_bee_zero@3x.png';
            }
            if (car.categories[i].id == 'mv.car.drivenow') {
                icon.src = 'mvgmore/ic_marker_drivenow@3x.png';
            }
            if (car.categories[i].id == 'mv.car.sharenow') {
                icon.src = 'images/carsharing_sharenow.png';
            }
        }

        if (!icon.src) {
            return;
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(car);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(car.position);
        marker.addTo(this.map);
        this.markers[car.id] = marker;
    }


    public addActiveMarker(car: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        opts.offset = [-10, 5];
        icon.classList.add('carsharing-marker');
        icon.classList.add('active');
        icon.style.width = '40px';
        icon.style.height = '47px';

        icon.src = null;
        for (let i in car.categories) {
            if (car.categories[i].id == 'mv.car.stattauto') {
                icon.src = 'mvgmore/ic_marker_station_statt_auto_selected@2x.png';
            }
            if (car.categories[i].id == 'mv.car.stattautoflexy') {
                icon.src = 'mvgmore/ic_marker_stattautoflexy_selected.png';
            }
            if (car.categories[i].id == 'mv.car.car2go') {
                icon.src = 'mvgmore/ic_marker_car2go_selected@2x.png';
            }
            if (car.categories[i].id == 'mv.car.beezero') {
                icon.src = 'mvgmore/logo_bee_zero@3x.png';
            }
            if (car.categories[i].id == 'mv.car.drivenow') {
                icon.src = 'mvgmore/ic_marker_drive_now_selected@2x.png';
            }
            if (car.categories[i].id == 'mv.car.sharenow') {
                opts.anchor = 'center';
                icon.style.width = '24px';
                icon.style.height = '24px';
                icon.src = 'images/carsharing_sharenow.png'; // @TODO Get a selected icon
            }
        }

        if (!icon.src) {
            return;
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(car);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(car.position);
        marker.addTo(this.map);
        this.markers[car.id] = marker;
    }


    private showCurrentMarkers() {
        let active: boolean = this._mvgState.carsharingActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue()) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'car') {
                collection.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'car') {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (active && zoom >= MIN_ZOOM) {
                collection = this.foundCarLocations;
            }
        }
        if (this.carActiveData && collection.filter(place => place.id == this.carActiveData.id).length === 0) {
            collection.push(this.carActiveData);
        }
        this.markerCollection.setNewElementCollection(collection);
    }
}
