import { Injectable } from "@angular/core";
import { MapStateService } from "../services/map-state.service";
import { TILES } from "../interfaces";
import { Map, Style } from 'mapbox-gl';


@Injectable()
export class MapTilesService {
    private map: Map;
    public tiles = TILES;
    private defaultStyle = require('./map-gl-styles.json') as Style;
    private mTourismusStyle = require('./map-gl-styles-mtourismus.json') as Style;

    public constructor(private _mapActions: MapStateService) {
    }

    public init(map: Map) {
        this.map = map;

        console.log("tiles init", map);
        this._mapActions.tiles$.subscribe((tile: number) => {
            this.setTiles(tile);
        });
    }

    setTilesSwmLuftbild() {
        this.map.setStyle({
            "version": 8,
            "sources": {
                "raster-tiles": {
                    "type": "raster",
                    "tiles": [
                        'https://map-component-tiles.web.azrapp.swm.de/api/v1/xyz/lhm_luftbild/{z}/{x}/{y}.jpg'
                    ],
                    "tileSize": 256
                }
            },
            "layers": [{
                "id": "simple-tiles",
                "type": "raster",
                "source": "raster-tiles",
                "minzoom": 0,
                "maxzoom": 22
            }]
        });

        // @TODO '&copy; <a href="http://www.muenchen.de/rathaus/Stadtverwaltung/Kommunalreferat/vermessungsamt.html">Stadt M&uuml;nchen</a> &copy; Bayer. Vermessungsverw.</a>'

        if (this.map.getZoom() > 18) {
            this.map.setZoom(18);
        }
        this.map.setMaxZoom(18);
    }

    setTilesMapboxSmartCity() {
        this.map.setStyle(this.defaultStyle);

        if (this.map.getZoom() > 18) {
            this.map.setZoom(18);
        }
        this.map.setMaxZoom(18);
    }

    setTilesMapboxMTourismus() {
        if (this.mTourismusStyle === null) {
            return this.setTilesMapboxSmartCity(); // Workaround für lokale Entwicklungsumgebungen, wenn beim Projektwechsel ein anderes Tileset ausgewählt ist
        }
        this.map.setStyle(this.mTourismusStyle);

        this.map.setMaxZoom(18);
    }

    setTiles(tile: number) {
        switch (tile) {
            case TILES.SWM_LUFTBILD:
                this.setTilesSwmLuftbild();
                break;
            case TILES.MAPBOX_MTOURISMUS:
                this.setTilesMapboxMTourismus();
                break;
            case TILES.MAPBOX_SMARTCITY:
            default:
                this.setTilesMapboxSmartCity();
        }
    }
}
