import { Injectable } from "@angular/core";
import { LngLat, Map, Marker } from 'mapbox-gl';
import { MapStateService } from '../services/map-state.service';
import { AppStateService } from '../services/app-state.service';
import { Messages, SidebarMessages } from '../messages/messages';


@Injectable()
export class MyLocationLayerService {
    private map: Map;

    private _dotElement: HTMLElement = null;
    private _userLocationDotMarker: Marker = null;
    private watchId: number = null;

    private _welcomeElement: HTMLElement = null;
    private _welcomeMarker: Marker = null;

    private waitingForMyPosition = false;
    private lastFoundMyPos: LngLat = null;

    private messages: SidebarMessages;

    public constructor(
        private globalMapActions: MapStateService,
        private appState: AppStateService
    ) {
        this.appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.sidebar;

            if (this._welcomeElement) {
                this._welcomeElement.innerText = messages.sidebar.welcome_marker;
            }
        });
    }

    public init(map: Map) {
        this.map = map;

        this.globalMapActions.myLocationLngLat$.subscribe(this.showMarker.bind(this));
        this.globalMapActions.gotoMyPos$.subscribe(this.triggerGoTo.bind(this));

        this.map.on('click', () => {
            if (this._welcomeMarker) {
                this._welcomeMarker.remove();
                this._welcomeMarker = null;
            }
        });
    }

    private showMarker(pos: LngLat) {
        if (pos) {
            if (this.lastFoundMyPos) {
                // Actual user position
                if (!this._userLocationDotMarker) {
                    this._dotElement = document.createElement('div');
                    this._dotElement.classList.add('mapboxgl-user-location-dot');
                    this._userLocationDotMarker = new Marker(this._dotElement);
                }
                if (this._welcomeMarker) {
                    this._welcomeMarker.remove();
                    this._welcomeMarker = null;
                }
                this._userLocationDotMarker.setLngLat([pos.lng, pos.lat]).addTo(this.map);
            } else {
                if (!this._welcomeMarker) {
                    this._welcomeElement = document.createElement('div');
                    this._welcomeElement.classList.add('my-place-label');
                    this._welcomeElement.innerText = this.messages.welcome_marker;
                    const marker = document.createElement('div');
                    marker.classList.add('my-place-label');
                    marker.append(this._welcomeElement);
                    this._welcomeMarker = new Marker(marker);
                }
                if (this._userLocationDotMarker) {
                    this._userLocationDotMarker.remove();
                    this._userLocationDotMarker = null;
                }
                this._welcomeMarker.setLngLat([pos.lng, pos.lat]).addTo(this.map);
            }
        } else {
            if (this._userLocationDotMarker) {
                this._userLocationDotMarker.remove();
                this._userLocationDotMarker = null;
            }
            if (this._welcomeMarker) {
                this._welcomeMarker.remove();
                this._welcomeMarker = null;
            }
        }
    }

    public triggerGoTo() {
        window.navigator.geolocation.getCurrentPosition((event: Position) => {
            console.log('### geolocate', event);
            const pos = new LngLat(event.coords.longitude, event.coords.latitude);
            this.lastFoundMyPos = pos;
            this.globalMapActions.updateMyLocation(pos, event.coords.accuracy);
            this.globalMapActions.triggerSetCenterZoom(pos, null);
        }, () => {
            console.log('### geolocate error');
            this.globalMapActions.updateMyLocation(null, null);
        }, {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000
        });
    }

    public startWatching() {
        if (this.watchId) {
            return;
        }
        this.watchId = window.navigator.geolocation.watchPosition((event: Position) => {
            console.log('### geolocate (watch)', event);
            const pos = new LngLat(event.coords.longitude, event.coords.latitude);
            this.globalMapActions.updateMyLocation(pos, event.coords.accuracy);
        }, () => {
            console.log('### geolocate (watch) error', event);
            this.watchId = null;
            this.globalMapActions.updateMyLocation(null, null);
        }, {
            enableHighAccuracy: false,
            maximumAge: 0,
            timeout: 10000
        });
    }
}
