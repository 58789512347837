import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChargingStation, MobilityStationDetails} from "../classes/MobilityStationDetails";
import {Messages, ShortInfoMessages} from "../messages/messages";
import {PlacesDetails} from "../classes/PlacesDetails";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'short-info-chargers',
    templateUrl: 'short-info-chargers.component.html',
    styles: [],
})
export class ShortInfoChargersComponent implements OnChanges {
    @Input() stations: PlacesDetails[];

    public chargingFilter = null;
    public filteredChargers: ChargingStation[] = [];
    public messages: ShortInfoMessages = null;
    public hasSchuko = false;
    public hasTyp2 = false;
    public hasCombo = false;
    public hasChademo = false;

    constructor(private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
        });
        this.setFilteredChargers();
    }

    public toggleChargingFilter(filter) {
        if (this.chargingFilter == filter) {
            this.chargingFilter = null;
        } else {
            this.chargingFilter = filter;
        }
        this.setFilteredChargers();
    }

    public setFilteredChargers() {
        this.filteredChargers = [];
        this.hasSchuko = false;
        this.hasTyp2 = false;

        if (!this.stations) {
            return;
        }
        this.stations.forEach((station) => {
            MobilityStationDetails.parseChargingStationPayload(station).forEach((charger) => {
                if (charger.standard === 'DOMESTIC_F') {
                    this.hasSchuko = true;
                }
                if (charger.standard === 'IEC_62196_T2') {
                    this.hasTyp2 = true;
                }
                if (charger.standard === 'IEC_62196_T2_COMBO') {
                    this.hasCombo = true;
                }
                if (charger.standard === 'Chademo') {
                    this.hasChademo = true;
                }
                switch (this.chargingFilter) {
                    case 'schuko':
                        if (charger.standard === 'DOMESTIC_F') {
                            this.filteredChargers.push(charger);
                        }
                        break;
                    case 'typ2':
                        if (charger.standard === 'IEC_62196_T2') {
                            this.filteredChargers.push(charger);
                        }
                        break;
                    case 'combo':
                        if (charger.standard === 'IEC_62196_T2_COMBO') {
                            this.filteredChargers.push(charger);
                        }
                        break;
                    case 'chademo':
                        if (charger.standard === 'Chademo') {
                            this.filteredChargers.push(charger);
                        }
                        break;
                    default:
                        this.filteredChargers.push(charger);
                }
            })
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setFilteredChargers();
    }
}
