import {Component, Input} from "@angular/core";
import {MVGRouteDefinition, MVGRoutingTransportation, MVV_TYPE} from "../interfaces";

@Component({
    selector: 'mvg-routing-transportations',
    template: '<ol class="transportations">' +
    '<li *ngFor="let part of route.routeParts">' +
    '<img *ngIf="part.transportationType == mvgTypes.WALKING" src="https://app.muenchen.de/img/marker/category/fallback.svg" alt="Fußweg" class="transport-icon">' +
    '<img *ngIf="part.transportationType == mvgTypes.UBAHN" src="images/routing_ubahn.svg" alt="U-Bahn" class="transport-icon">' +
    '<img *ngIf="part.transportationType == mvgTypes.SBAHN" src="images/routing_sbahn.svg" alt="S-Bahn" class="transport-icon">' +
    '<img *ngIf="part.transportationType == mvgTypes.TRAM" src="images/routing_tram.svg" alt="Tram" class="transport-icon">' +
    '<img *ngIf="part.transportationType == mvgTypes.BUS" src="images/routing_bus.svg" alt="Bus" class="transport-icon">' +
    '<img *ngIf="part.transportationType == mvgTypes.BAHN" src="images/routing_bahn.svg" alt="Bus" class="transport-icon">' +
    '</li>' +
    '</ol>',
    styles: [],
})
export class MVGRoutingTransportationsComponent {
    @Input() route: MVGRouteDefinition;
    public mvgTypes = MVV_TYPE;
}
