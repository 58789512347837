import {Component, OnInit} from '@angular/core';
import {URLService} from "../services/url.service";
import {SettingsService} from "../services/settings.service";
import {MapStateService} from "../services/map-state.service";
import {OfflineCachingService} from "../services/offline-caching.service";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'poi-map-app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public sidebarShown: boolean;

    constructor(private url: URLService,
                private globalMapActions: MapStateService,
                private _routingService: RoutingService,
                private _mvgState: MVGStateService,
                private _appState: AppStateService,
                private offlineCaching: OfflineCachingService,
                private settings: SettingsService) {
    }

    initPosDelayed(): void {

        if (this.url.hasInitialShortInfo()) {
            this.url.handleShortInfoUrl();
        } else if (this.url.getInitialView()) {
            let initialView = this.url.getInitialView();
            this.globalMapActions.triggerSetCenterZoom(initialView.center, initialView.zoom);
        } else if (!this.offlineCaching.isOnline()) {
            this.globalMapActions.triggerSetCenterZoom(
                this.offlineCaching.getOfflineInitPos(),
                this.offlineCaching.getOfflineInitZoom()
            );
        }

        this.url.initUrlListener();
    }

    setInitialSettings(): void {
        let urlState = this.url.getCurrentState();

        if (urlState.place_limit) {
            this._appState.setPlacesMaxNumber(urlState.place_limit);
        }

        if (urlState.tiles !== null) {
            this.globalMapActions.setTiles(urlState.tiles, false);
        } else {
            this.globalMapActions.setTiles(this.settings.getTileset(), false);
        }

        if (urlState.language) {
            this._appState.setLanguage(urlState.language, false);
        } else {
            this._appState.setLanguage(this.settings.getLanguage(), false);
        }
    }

    ngOnInit(): void {
        this.offlineCaching.registerOnlineListener();
        this.setInitialSettings();

        if (this.offlineCaching.isAvailable()) {
            if (this.offlineCaching.isOnline()) {
                window.setTimeout(() => {
                    console.log("Starting downloading offline assets");
                    this.offlineCaching.doCacheById('marienplatz').then(() => {
                        console.log("Offline-Caching promise fulfilled");
                    });
                }, 3000);
            } else {
                console.log("Starting offline");
            }
        }

        this._appState.sidebarShown$.subscribe((shown) => {
            this.sidebarShown = shown;
        });

        window.setTimeout(() => {
            this.initPosDelayed();
        }, 0);

    }
}
