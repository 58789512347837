import {PlacesEntry} from "./classes/PlacesEntry";
import {IRoutable} from "./classes/IRoutable";
import {Lamppost} from "./classes/Lamppost";
import {SmartCityObject} from "./classes/SmartCityObject";
import {AddressAutosuggestEntry} from "./classes/AddressAutosuggestEntry";

export interface IShortInfoComponentFeatureSets {
    address: AddressAutosuggestEntry[],
    place: PlacesEntry[];
    bike: PlacesEntry[];
    car: PlacesEntry[];
    charging_station: PlacesEntry[];
    erdgas: PlacesEntry[];
    mvg_station: PlacesEntry[];
    lamppost: Lamppost[];
    smartcity: SmartCityObject[];
    mobility_station: PlacesEntry[];
    taxi: PlacesEntry[];
    escooter: PlacesEntry[];
}

export enum RoutingTransportation {
    MVG, BIKE, WALKING, CAR, SIGHTSEEING
}
export enum MVGRoutingTransportation {
    WALKING, BUS, TRAM, U, S
}
export const ALL_ROUTING_TRANSPORTATIONS = [
    RoutingTransportation.WALKING, RoutingTransportation.MVG, RoutingTransportation.BIKE, RoutingTransportation.CAR, RoutingTransportation.SIGHTSEEING
];
export enum RoutingMode {
    NONE, ACTIVE, FULLSCREEN
}

export const TILES = {
    'SWM_LUFTBILD': 3,
    'KOMMREF': 4,
    'MAPBOX_MTOURISMUS': 5,
    'MAPBOX_TRAFFIC': 6,
    'MAPBOX_SMARTCITY': 8,
};

export const DEFAULT_TILES = TILES.MAPBOX_MTOURISMUS;
export const DEFAULT_ORIENTATION = 0;
export const DEFAULT_PITCH = 0;

export const LANGUAGES = [
    'de', 'en'
];

export interface PlacesCategory {
    type: string;
    id: string;
    name: string;
    icon?: string;
}

export interface PlaceInlineCategory {
    id: string;
    title: string;
}

export interface PlacesCategoryMultilingual {
    type: string;
    id: number;
    names: {[language: string]: string};
    icon?: string;
}

export interface WalkingRoute {
    route: [number, number][];
}

export interface RouteDefinitionWithAlternatives {
    route: RouteDefinition,
    allRoutes: RouteDefinition[]
}

export interface RouteDefinition {
    from: IRoutable;
    to: IRoutable;
    start_address: string;
    end_address: string;
    overview_polyline: [number, number][];
    duration: number;
    length: number;
    hints: string;
    hasPublicTransportation: boolean;
    transportationType: RoutingTransportation;
    inbetweenPlaces: PlacesEntry[];
}

export interface MVGRouteDefinitionPart {
    polyline: [number, number][];
    transportationType: MVV_TYPE;
    transportationName: string;
    startTime: Date;
    endTime: Date;
    startName: string;
    endName: string;
    interchangeAfter: [number, number][];
}
export interface MVGRouteDefinition extends RouteDefinition {
    routeParts: MVGRouteDefinitionPart[];
    startTime: Date;
    endTime: Date;
}

export interface MVGStationDeparture {
    time: Date;
    type: MVV_TYPE;
    name: string;
    destination: string;
    lineBackgroundColor: string;
    explicitIcon: string;
}

export enum MVV_TYPE {
    BUS, UBAHN, SBAHN, TRAM, BAHN, WALKING, MOBILITY_STATION
}

export interface District {
    name: string;
    coordinates: [number, number][][];
}

export interface LamppostCurrentValues {
    temperature: number;
    humidity: number;
    wifi: boolean;
}

export interface PlaceImage {
    url: string;
    width: number;
    height: number;
    alt: string;
}
