import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {MobilityStationDetails} from "../classes/MobilityStationDetails";
import {Messages, ShortInfoMessages} from "../messages/messages";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'short-info-mobility-station',
    templateUrl: 'short-info-mobility-station.component.html',
    styles: [],
})
export class ShortInfoMobilityStationComponent {
    private _station: MobilityStationDetails = null;

    @Input()
    set station(station: any) {
        this._station = null;
        this._ep.loadDetails(station.id, "de").subscribe((details: MobilityStationDetails) => {
            this._station = details;
            if (details.hasPublicTransport()) {
                this.activeTab = 'publicTransportation';
            } else if (details.hasAnyBike()) {
                this.activeTab = 'bikes';
            } else if (details.hasDistrictbox()) {
                this.activeTab = 'districtbox';
            } else if (details.hasCars()) {
                this.activeTab = 'cars';
            } else if (details.hasChargingPoints()) {
                this.activeTab = 'chargingPoint';
            }
        });
    }

    get station() {
        return this._station;
    };

    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    public activeTab = 'publicTransportation';
    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;

    constructor(private _ep: EchtzeitproxyService, private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }

    public setActiveTab(newActive: string)
    {
        this.activeTab = newActive;
    }

    public closeMe() {
        console.log("close");
        this.close.emit(true);
    }
}
