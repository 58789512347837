import {IRoutable} from "./IRoutable";
import {IShortInfoComponentContent} from "./IShortInfoComponentContent";
import { LngLat } from 'mapbox-gl';

export class AddressAutosuggestEntry implements IShortInfoComponentContent, IRoutable {
    constructor(public position: LngLat,
                public id: string,
                public address: string) {
    }

    getClassType(): string {
        return 'address';
    }

    getShortInfoImage(): string {
        return null;
    }

    getShortInfoAddress(): string {
        return this.address;
    }

    getShortInfoTitle(): string {
        return this.address;
    }

    getUrlHash(): string {
        return 'address/' + encodeURIComponent(this.address);
    }

    /**
     * @param {AddressAutosuggestEntry} obj - ein noch nicht wirklich initialisiertes Objekt, das aus JSON.parse(JSON.stringify(richtigesObjekt)) aus "richtigesObjekt" hervorgegangen ist
     * @returns {AddressAutosuggestEntry}
     */
    public static unserialize(obj) {
        return new AddressAutosuggestEntry(
            new LngLat(obj.position.lng, obj.position.lat),
            obj.id,
            obj.title
        );
    }

    getId(): string {
        return 'address-' + encodeURIComponent(this.address.toLowerCase());
    }

    getPosition(): LngLat {
        return this.position;
    }

    getRoutingTitle(language: string): string {
        return this.address;
    }

    getRoutableObject(): IRoutable {
        return this;
    }

    public needsRoutingPlaceholderIcon() {
        return false;
    }

    getMvgRoutingString(): string {
        return this.getPosition().lat + "," + this.getPosition().lng;
    }
}
