<section class="big-info-box">
    <div class="controls-row control-row-top">
        <div class="back-holder">
            <button class="back-link btn btn-link btn-xs" (click)="onCloseClick()">
                <span class="glyphicon glyphicon-chevron-left"></span>
            </button>
        </div>
        <div class="title">
            <h2>{{ (lamppost)?.title }}</h2>
        </div>
    </div>
    <iframe [src]="iframeUrl" class="lamppost-iframe" *ngIf="iframeUrl"></iframe>
    <div class="controls-row control-row-bottom">
        <div class="back-holder">
            <button class="back-link btn btn-link btn-xs" (click)="onCloseClick()">
                <span class="glyphicon glyphicon-chevron-left"></span>
            </button>
        </div>
    </div>
</section>