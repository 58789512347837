import {Component, Input} from '@angular/core';
import {Messages, ShortInfoMessages} from "../messages/messages";
import {MobilityStationDetails} from "../classes/MobilityStationDetails";
import {PlacesDetailsBike} from "../classes/PlacesDetailsBike";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'short-info-bike-station',
    templateUrl: 'short-info-bike-station.component.html',
    styles: [],
})
export class ShortInfoBikeStationComponent {
    private _station: MobilityStationDetails = null;
    @Input()
    set station(station: MobilityStationDetails) {
        this._station = station;
    }

    get station() {
        return this._station;
    };

    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;
    public bikeFilter: string = null;

    constructor(private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }

    public toggleFilter(filter) {
        if (this.bikeFilter == filter) {
            this.bikeFilter = null;
        } else {
            this.bikeFilter = filter;
        }
    }

    public getBikeAvailabilityStr(bike: PlacesDetailsBike) {
        const available = bike.getStationAvailable();
        if (bike.categories.find(cat => cat.id === 'mv.pedelec')) {
            if (available == 1) {
                return available + " " + this.messages.pedelec_available;
            } else {
                return available + " " + this.messages.pedelecs_available;
            }
        } else if (bike.categories.find(cat => cat.id === 'mv.etrike')) {
            if (available == 1) {
                return available + " " + this.messages.etrike_available;
            } else {
                return available + " " + this.messages.etrikes_available;
            }
        } else {
            if (available == 1) {
                return this.messages.mobility_bike_available_1;
            } else {
                return available + " " + this.messages.mobility_bike_available_x;
            }
        }
    }
}
