import { Injectable } from '@angular/core';
import { Client } from 'elasticsearch-browser';

@Injectable()
export class ElasticsearchService {
    private client: Client;

    private queryalldocs = {
        'query': {
            'match_all': {}
        }
    };

    constructor() {
        if (!this.client) {
            this.connect();
        }
    }

    private connect() {
        this.client = new Client({
            host: 'https://elastic:3tVazCiGDqYYG1NI3Gqr@es.muenchenapis.de:9200',
            log: null
        });
    }

    isAvailable(): any {
        return this.client.ping({
            requestTimeout: 1000,
            body: "hello elasticsearch!"
        });
    }

    addToIndex(value): any {
        return this.client.create(value);
    }

    getAllDocuments(_index): any {
        return this.client.search({
            index: _index,
            body: this.queryalldocs,
            filterPath: ['hits.hits._source']
        });
    }

    getAllDocumentsWithScroll(_index, _size): any {
        return this.client.search({
            index: _index,
            scroll: '1m',
            filterPath: ['hits.hits._source', 'hits.total', '_scroll_id'],
            body: {
                'size': _size,
                'query': {
                    'match_all': {}
                },
                'sort': [
                    { '_uid': { 'order': 'asc' } }
                ]
            }
        });
    }

    getNextPage(scroll_id): any {
        return this.client.scroll({
            scrollId: scroll_id,
            scroll: '1m',
            filterPath: ['hits.hits._source', 'hits.total', '_scroll_id']
        });
    }

    /**
     * Text search in one single field
     * @param _index
     * @param _field
     * @param _queryText
     * @param _size
     */
    fullTextSearch(_index, _field, _queryText, _size = 10): any {
        return this.client.search({
            index: _index,
            filterPath: ['hits.hits._source', 'hits.total', '_scroll_id'],
            body: {
                size: _size,
                'query': {
                    'match': {
                        [_field]: _queryText,
                    }
                }
            }
        });
    }

    /**
     * Text search in multiple fields
     * @param _index
     * @param _fields
     * @param _queryText
     * @param _size
     */
    fullTextSearchMultiField(_index, _fields, _queryText, _size = 10): any {
        return this.client.search({
            index: _index,
            filterPath: ['hits.hits._source', 'hits.total', '_scroll_id'],
            body: {
                size: _size,
                'query': {
                    'multi_match': {
                        "analyzer": "german",
                        "query": _queryText,
                        "fields": _fields,
                        "operator": "or",
                        "type": "cross_fields",
                    }
                }
            }
        });
    }

    /**
     * Combined text search in multiple fields and in single array field
     * @param _index
     * @param _fieldsText
     * @param _fieldArray
     * @param _queryText
     * @param _size
     */
    fullTextSearchCombined(_index, _fieldsText, _fieldArray, _queryText, _size = 10): any {
        return this.client.search({
            index: _index,
            filterPath: ['hits.hits._source', 'hits.total', '_scroll_id'],
            body: {
                size: _size,
                'query': {
                    'bool': {
                        'should': [
                            {
                                "match": {
                                    [_fieldArray]: _queryText
                                }
                            },
                            {
                                'query_string': {
                                    "query": _queryText+"*",
                                    "fields": _fieldsText,
                                }
                            },
                            {
                                'match': {
                                    "city": "München"
                                }
                            }
                        ]
                    }
                }
            }
        });
    }

}