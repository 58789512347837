import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Marker, Map, MarkerOptions } from 'mapbox-gl';

const MIN_ZOOM = 14;

@Injectable()
export class TaxiLayerService {
    private map: Map;
    private foundTaxis: PlacesEntry[] = [];
    private taxiActiveData: PlacesEntry = null;
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private markers: { [id: string]: Marker } = {};
    //private Z_INDEX_OFFSET: 0;

    constructor(private _mapState: MapStateService,
                private _appState: AppStateService,
                private _mvgState: MVGStateService,
                private _routingService: RoutingService,
                private _epBBLoader: EpBoundingboxLoaderService) {
    }

    public init(map: Map) {
        this.map = map;

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() === 'taxi') {
                this.taxiActiveData = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.taxiActiveData = null;
            }
            this.markerCollection.setActiveElement(this.taxiActiveData);
        });

        this._epBBLoader.setListenerCategories('taxi', []).subscribe((data) => {
            this.foundTaxis = data.places;
            this._appState.placesLoaded('taxi', this.foundTaxis);
            this.showCurrentMarkers();
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.taxiActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.taxiActive$.subscribe(this.showCurrentMarkers.bind(this));

        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        if (this._mvgState.taxiActive$.getValue() || true) {
            if (this._mapState.zoom$.getValue() >= MIN_ZOOM) {
                this._epBBLoader.setListenerCategories('taxi', ['mv.taxi'])
            } else {
                this._epBBLoader.setListenerCategories('taxi', [])
            }
        } else {
            this._epBBLoader.setListenerCategories('taxi', [])
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }
    }

    public addInactiveMarker(taxi: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        icon.classList.add('taxi-marker');
        icon.classList.add('inactive');
        icon.style.width = '30px';
        icon.style.height = '42px';

        icon.src = null;
        for (let i in taxi.categories) {
            if (taxi.categories[i].id == 'mv.taxi') {
                if (taxi.categories.filter(cat => cat.id === 'mv.taxi.available').length > 0) {
                    icon.src = 'mvgmore/ic_taxi_station.png';
                } else {
                    icon.src = 'mvgmore/ic_taxi_station_empty.png';
                }
            }
        }
        if (icon.src === null) {
            return;
        }
        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(taxi);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(taxi.position);
        marker.addTo(this.map);
        this.markers[taxi.id] = marker;
    }


    public addActiveMarker(taxi: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        opts.offset = [-10, 5];
        icon.classList.add('taxi-marker');
        icon.classList.add('active');
        icon.style.width = '40px';
        icon.style.height = '47px';

        icon.src = null;
        for (let i in taxi.categories) {
            if (taxi.categories[i].id === 'mv.taxi') {
                if (taxi.categories.filter(cat => cat.id === 'mv.taxi.available').length > 0) {
                    icon.src = 'mvgmore/ic_taxi_station_selected.png';
                } else {
                    icon.src = 'mvgmore/ic_taxi_station_empty_selected.png';
                }
            }
        }
        if (icon.src === null) {
            return;
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(taxi);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(taxi.position);
        marker.addTo(this.map);
        this.markers[taxi.id] = marker;
    }


    private showCurrentMarkers() {
        let active: boolean = this._mvgState.taxiActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue()) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() === 'taxi') {
                collection.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() === 'taxi') {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (active && zoom >= MIN_ZOOM) {
                collection = this.foundTaxis;
            }
        }
        if (this.taxiActiveData && collection.filter(place => place.id == this.taxiActiveData.id).length === 0) {
            collection.push(this.taxiActiveData);
        }
        this.markerCollection.setNewElementCollection(collection);
    }
}
