import {Injectable} from "@angular/core";
import {MVV_TYPE} from "../interfaces";

import {MVVRoutingService} from "../apis/mvv-routing.service";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {MobilityStationDetails} from "../classes/MobilityStationDetails";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Map, Marker, MarkerOptions } from "mapbox-gl";

const MIN_ZOOM = 12; // Synch mit Lampposts

const BUS_MIN_ZOOM = 15;
const TRAM_MIN_ZOOM = 14;
//const MOBILITY_MIN_ZOOM = 14; // Synch map-carsharing, map-bikesharing and map-mvv
const MOBILITY_ALL_MIN_ZOOM = 15; // Synch map-carsharing, map-bikesharing and map-mvv

@Injectable()
export class MapMVVLayerService {
    //private mvvDBIcon: Icon;
    private map: Map;
    //private Z_INDEX_OFFSET: 30;
    private markers: { [id: string]: Marker } = {};
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private currentStations: PlacesEntry[] = [];
    private activeStation: PlacesEntry = null;

    private mobilityPolygons: { [id: string]: string } = {};

    constructor(private _mvgState: MVGStateService,
                private _mapState: MapStateService,
                private _appState: AppStateService,
                private _mvvrouting: MVVRoutingService,
                private _routingService: RoutingService,
                private _epBBLoader: EpBoundingboxLoaderService) {
    }

    public init(map: Map) {
        this.map = map;

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && ['mvg_station', 'mobility_station'].indexOf(shortInfoComponentActive.getClassType()) !== -1) {
                this.activeStation = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.activeStation = null;
            }
            this.markerCollection.setActiveElement(this.activeStation);
        });

        this._epBBLoader.setListenerCategories('mvg', []).subscribe((data) => {
            this.currentStations = data.places;
            this._appState.placesLoaded(
                'mvg_station',
                this.currentStations.filter(place => place.getClassType() === 'mvg_station')
            );
            this._appState.placesLoaded(
                'mobility_station',
                this.currentStations.filter(place => place.getClassType() === 'mobility_station')
            );
            this.showCurrentMarkers();
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.publicTransportActive$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.mobilityStationActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.publicTransportActive$.subscribe(this.showCurrentMarkers.bind(this));
        this._mvgState.mobilityStationActive$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        let zoom = this._mapState.zoom$.getValue(),
            cats = [];
        if (zoom >= MIN_ZOOM) {
            if (this._mvgState.mobilityStationActive$.getValue()) {
                cats.push('mv.mobility');
            }
            if (this._mvgState.publicTransportActive$.getValue()) {
                cats.push('mv.public.sbahn');
                cats.push('mv.public.ubahn');
                cats.push('mv.public.train');

                if (zoom >= TRAM_MIN_ZOOM) {
                    cats.push('mv.public.tram');
                }
                if (zoom >= BUS_MIN_ZOOM) {
                    cats.push('mv.public.bus');
                }
            }
        }
        this._epBBLoader.setListenerCategories('mvg', cats);

        return cats;
    }

    public addInactiveMarker(station: PlacesEntry) {
        const marker = this.getTransportsMarker(station, false);
        if (!marker) {
            return; // DB-Stationen
        }
        marker.setLngLat(station.position);
        marker.addTo(this.map);
        this.markers[station.id] = marker;

        if (this.mobilityPolygons[station.id]) {
            this.map.removeLayer(this.mobilityPolygons[station.id]);
            this.map.removeSource(this.mobilityPolygons[station.id]);
            this.map.removeLayer(this.mobilityPolygons[station.id] + ".outline");
            this.map.removeSource(this.mobilityPolygons[station.id] + ".outline");
            delete(this.mobilityPolygons[station.id]);
        }
    }

    public addActiveMarker(station: PlacesEntry) {
        const marker = this.getTransportsMarker(station, true);
        if (!marker) {
            return; // DB-Stationen
        }
        marker.setLngLat(station.position);
        marker.addTo(this.map);
        this.markers[station.id] = marker;

        if (station.type === 'mobility_station' && MobilityStationDetails.AREA_OUTLINES[station.id]) {
            const source: any = {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [MobilityStationDetails.AREA_OUTLINES[station.id]]
                    },
                },
            };
            this.map.addLayer({
                'id': 'polygon.' + station.id,
                'type': 'fill',
                'source': source,
                'layout': {},
                'paint': {
                    'fill-color': '#4562a2',
                    'fill-opacity': 0.15
                }
            });
            this.map.addLayer({
                'id': 'polygon.' + station.id + '.outline',
                'type': 'line',
                'source': source,
                'layout': {},
                'paint': {
                    'line-color': '#4562a2',
                    'line-width': 2
                }
            });
            this.mobilityPolygons[station.id] = 'polygon.' + station.id;
            /*
            this.mobilityPolygons[station.id] = new Polygon(MobilityStationDetails.AREA_OUTLINES[station.id], {
                stroke: true,
                color: '#4562a2',
                weight: 2,
                fill: true,
                fillColor: '#4562a2',
                fillOpacity: 0.15
            });
            */
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }

        if (this.mobilityPolygons[station.id]) {
            this.map.removeLayer(this.mobilityPolygons[station.id]);
            this.map.removeSource(this.mobilityPolygons[station.id]);
            this.map.removeLayer(this.mobilityPolygons[station.id] + ".outline");
            this.map.removeSource(this.mobilityPolygons[station.id] + ".outline");
            delete(this.mobilityPolygons[station.id]);
        }
    }

    private showCurrentMarkers() {
        let publicActive: boolean = this._mvgState.publicTransportActive$.getValue(),
            mobilityActive: boolean = this._mvgState.mobilityStationActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue()) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'car') {
                collection.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'car') {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (zoom > MIN_ZOOM) {
                collection = this.currentStations.filter((station) => {
                    if (station.categories.find(cat => cat.id === 'mv.mobility')) {
                        return mobilityActive;
                    } else {
                        if (publicActive)
                            if (station.mobilityStationId && mobilityActive) {
                                return (zoom >= MOBILITY_ALL_MIN_ZOOM);
                            } else {
                                return true;
                            }
                    }
                });
            }
        }
        if (this.activeStation && collection.filter(place => place.id == this.activeStation.id).length === 0) {
            collection.push(this.activeStation);
        }
        this.markerCollection.setNewElementCollection(collection);
    }

    /*
    drawMvvLine(lineData: any) {
        if (this.mvvLine) {
            for (let i = 0; i < this.mvvLine.length; i++) {
                this.map.removeLayer(this.mvvLine[i]);
            }
            this.mvvLine = [];
        }
        if (!lineData) {
            return;
        }

        this.mvvLine = [];
        for (let i = 0; i < lineData.length; i++) {
            let way = lineData[i].way,
                line = lineData[i].line,
                color = (line.tags.colour ? line.tags.colour : 'red'),
                latlngs = [];

            for (let j = 0; j < way.length; j++) {
                let currlatlng = [];
                for (let node in way[j].nodes) {
                    if (way[j].nodes.hasOwnProperty(node)) {
                        let point: [number, number] = [way[j].nodes[node].lat, way[j].nodes[node].lon];
                        currlatlng.push(point);
                    }
                }
                latlngs.push(currlatlng);
            }

            let options: PolylineOptions = {
                color: color, opacity: 1, weight: 4,
                className: 'mvv-line'
            };
            if (lineData.length > 2) {
                options.dashArray = "5, 10";
                options.dashOffset = i * 7 + "";
            }
            let poly: Polyline = <any>new Polyline(latlngs, options);
            this.mvvLine.push(poly);
            poly.addTo(this.map);
        }
    }
    */

    public getTransportsMarker(station: PlacesEntry, active: boolean): Marker {
        let opts: MarkerOptions = {},
            icon = document.createElement('img'),
            transports = EchtzeitproxyService.categories2mvgtype(station.categories);

        opts.element = icon;
        opts.draggable = false;
        icon.classList.add('mvv-marker');
        if (active) {
            icon.classList.add('active');
        } else {
            icon.classList.add('inactive');
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(station);
            ev.stopPropagation();
        });

        let sbahn = (transports.indexOf(MVV_TYPE.SBAHN) !== -1),
            ubahn = (transports.indexOf(MVV_TYPE.UBAHN) !== -1),
            tram = (transports.indexOf(MVV_TYPE.TRAM) !== -1),
            bus = (transports.indexOf(MVV_TYPE.BUS) !== -1),
            mobilityStation = (transports.indexOf(MVV_TYPE.MOBILITY_STATION) !== -1);
        let len = (sbahn ? 1 : 0) + (ubahn ? 1 : 0) + (tram ? 1 : 0) + (bus ? 1 : 0); // DB etc. ignorieren

        if (mobilityStation) {
            if (active) {
                icon.src = 'mvgmore/ic_marker_mobility_station_selected@2x.png';
                icon.style.width = '44px';
                icon.style.height = '48px';
                opts.offset = [10, 5];
                opts.anchor = 'bottom-right';
            } else {
                icon.src = 'mvgmore/ic_marker_mobility_station@3x.png';
                icon.style.width = '35px';
                icon.style.height = '44px';
                opts.anchor = 'bottom-right';
            }
            return new Marker(opts);
        }

        let iconStr = '';
        switch (len) {
            case 1:
                if (ubahn) {
                    iconStr = 'u';
                } else if (sbahn) {
                    iconStr = 's';
                } else if (tram) {
                    iconStr = 't';
                } else if (bus) {
                    iconStr = 'b';
                }
                break;
            case 2:
                if (tram && bus) {
                    iconStr = 'bt';
                } else if (tram && sbahn) {
                    iconStr = 'ts';
                } else if (ubahn && bus) {
                    iconStr = 'ub';
                } else if (ubahn && tram) {
                    iconStr = 'ut';
                } else if (sbahn && bus) {
                    iconStr = 'bs';
                } else if (sbahn && ubahn) {
                    iconStr = 'u';
                }
                break;
            case 3:
                if (ubahn && sbahn && bus) {
                    iconStr = 'ubs';
                } else if (ubahn && tram && bus) {
                    iconStr = 'ubt';
                } else if (sbahn && ubahn && tram) {
                    iconStr = 'ut'; // Den Fall, dass es keinen Bus gibt, gibt in der Praxis nicht
                } else if (bus && tram && sbahn) {
                    iconStr = 'bts';
                } else if (ubahn && bus && tram) {
                    iconStr = 'utb';
                }
                break;
            case 4:
                iconStr = 'ubts';
                break;
        }

        if (active) {
            opts.anchor = 'bottom-right';
            opts.offset = [10, 5];
            switch (iconStr.length) {
                case 1: // 171x225
                    icon.style.width = '46px';
                    icon.style.height = '49px';
                    break;
                case 2: // File size: 225x225
                    icon.style.width = '73px';
                    icon.style.height = '48px';
                    break;
                case 3: // File size: 225x264
                    icon.style.width = '101px';
                    icon.style.height = '48px';
                    break;
                case 4: // File size: 225x264
                    icon.style.width = '74px';
                    icon.style.height = '79px';
                    break;
            }
        } else {
            opts.anchor = 'bottom-right';
            switch (iconStr.length) {
                case 1: // File size: 132x132
                    icon.style.width = '35px';
                    icon.style.height = '44px';
                    break;
                case 2: // File size: 132x66
                    icon.style.width = '63px';
                    icon.style.height = '44px';
                    break;
                case 3: // File size: 132x132
                    icon.style.width = '91px';
                    icon.style.height = '44px';
                    break;
                case 4: // File size: 132x132
                    icon.style.width = '63px';
                    icon.style.height = '74px';
                    break;
            }
        }

        if (iconStr === '') {
            return null;
        }

        if (active) {
            icon.src = 'mvgmore/station_' + iconStr + '_selected@3x.png';
        } else {
            icon.src = 'mvgmore/station_' + iconStr + '@3x.png';
        }

        return new Marker(opts);
    }
}
