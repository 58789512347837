import {Component} from '@angular/core';
import {Messages, SidebarMessages} from "../messages/messages";
import {MapStateService} from "../services/map-state.service";
import {MVGStateService} from "../services/mvg-state.service";
import {AppStateService} from "../services/app-state.service";
import {TILES} from "../interfaces";


@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
    public bikeActive: boolean;
    public escooterActive: boolean;
    public carSharingActive: boolean;
    public chargingStationsActive: boolean;
    public publicActive: boolean;

    public lamppostsActive: boolean = null;
    public mobilitaetsstationenActive: boolean = null;
    public messages: SidebarMessages;
    public language: string;
    public tiles: number;
    public TILE_TYPES = TILES;

    constructor(private _globalMapActions: MapStateService,
                private _appState: AppStateService,
                private _mvgState: MVGStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.sidebar;
            this.language = messages.language;
        });

        this._mvgState.bikeActive$.subscribe((active) => {
            this.bikeActive = active;
        });
        this._mvgState.escooterActive$.subscribe((active) => {
            this.escooterActive = active;
        });
        this._mvgState.carsharingActive$.subscribe((active) => {
            this.carSharingActive = active;
        });
        this._mvgState.chargingStationsActive$.subscribe((active) => {
            this.chargingStationsActive = active;
        });
        this._mvgState.publicTransportActive$.subscribe((active) => {
            this.publicActive = active;
        });
        this._mvgState.lamppostActive$.subscribe((active: boolean) => {
            this.lamppostsActive = active;
        });
        this._mvgState.mobilityStationActive$.subscribe((active: boolean) => {
            this.mobilitaetsstationenActive = active;
        });
        this._globalMapActions.tiles$.subscribe((tile) => {
            this.tiles = tile;
        })
    }

    public selectMap() {
        if (this.tiles === TILES.SWM_LUFTBILD) {
            if (this._appState.tourismMode$.getValue() === true) {
                this._globalMapActions.setTiles(TILES.MAPBOX_MTOURISMUS, true);
            } else {
                this._globalMapActions.setTiles(TILES.MAPBOX_SMARTCITY, true);
            }
        }
    }

    public selectSatellite() {
        if (this.tiles !== TILES.SWM_LUFTBILD) {
            this._globalMapActions.setTiles(TILES.SWM_LUFTBILD, true);
        }
    }

    selectTiles(tile: number) {
        this._globalMapActions.setTiles(tile, true);
    }

    togglePublicActive() {
        this._mvgState.togglePublicTransportActive();
    }

    toggleMvgBikeActive() {
        this._mvgState.toggleBikeActive();
    }

    toggleEScooterActive() {
        this._mvgState.setEScooterActive(!this._mvgState.escooterActive$.getValue(), true);
    }

    toggleCarSharingActive() {
        this._mvgState.toggleCarActive();
    }

    toggleChargingStationsActive() {
        this._mvgState.toggleChargingStationActive()
    }

    setLanguage(lang: string) {
        this._appState.setLanguage(lang, true);
    }

    public closeSidebar() {
        this._appState.closeSidebar();
    }

    public resetCategories() {
        this._appState.setDefaultCategory();
        this._mvgState.reset();
    }
}
