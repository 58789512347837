import {Injectable} from "@angular/core";
import {Messages} from "../messages/messages";
import {MESSAGES_DE} from "../messages/messages.de";
import {MESSAGES_EN} from "../messages/messages.en";
import {SettingsService} from "./settings.service";
import {PlacesDetails} from "../classes/PlacesDetails";
import {IShortInfoComponentFeatureSets} from "../interfaces";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {PlacesEntry} from "../classes/PlacesEntry";
import { BehaviorSubject, Subject } from "rxjs";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {MobilityStationDetails} from "../classes/MobilityStationDetails";
import { LngLat } from 'mapbox-gl';

@Injectable()
export class AppStateService {
    constructor(
        private _settings: SettingsService,
        private _ezp: EchtzeitproxyService
        ) {
        this.overrideDefaultCategoriesFromUrl("smart");
        this.setDefaultCategory();
    }

    private DEFAULT_CATEGORIES = [];
    private resetUrl: string = null;

    public online$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public sidebarShown$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public categoriesSelected$: BehaviorSubject<string[]> = new BehaviorSubject(this.DEFAULT_CATEGORIES);
    public language$: BehaviorSubject<Messages> = new BehaviorSubject<Messages>(MESSAGES_DE);
    public placesMaxNumber$: BehaviorSubject<number> = new BehaviorSubject(10);
    public tourismMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public mwlanCategory$: BehaviorSubject<string> = new BehaviorSubject<string>('branchen');
    public extraCategory$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public shortInfoComponentActive$: BehaviorSubject<IShortInfoComponentContent> = new BehaviorSubject(null);
    public shortInfoComponentExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public shortInfoComponentContents$: BehaviorSubject<IShortInfoComponentFeatureSets> = new BehaviorSubject({
        address: [],
        place: [],
        bike: [],
        car: [],
        mt_site: [],
        mvg_station: [],
        smartcity: [],
        lamppost: [],
        mobility_station: [],
        charging_station: [],
        erdgas: [],
        taxi: [],
        escooter: []
    });
    public activePlaceDetails$: BehaviorSubject<PlacesDetails> = new BehaviorSubject(null);

    public closePrettyMuchEverythingEvent$: Subject<boolean> = new Subject<boolean>();
    public reloadPlacesEvent$: Subject<boolean> = new Subject<boolean>();


    public setOnlineState(online: boolean) {
        this.online$.next(online);
    }

    public toggleSidebar() {
        this.sidebarShown$.next(!this.sidebarShown$.getValue());
    }

    public closePrettyMuchEverything() {
        this.closeSidebar();
        this.placeClose();
        this.closePrettyMuchEverythingEvent$.next(true);
        this.reloadPlacesEvent$.next(true);
    }

    public closeSidebar() {
        if (this.sidebarShown$.getValue()) {
            this.sidebarShown$.next(false);
        }
    }

    public setCategoriesSelected(categories: string[]) {
        this.categoriesSelected$.next(categories);
        this.closePrettyMuchEverything();
    }

    public setLanguage(language: string, setSettings: boolean) {
        let messages: Messages;
        if (language == "en") {
            messages = MESSAGES_EN;
        } else {
            messages = MESSAGES_DE;
        }
        this.language$.next(messages);

        if (setSettings) {
            this._settings.setLanguage(language);
        }
    }

    public setPlacesMaxNumber(max: number) {
        this.placesMaxNumber$.next(max);
    }

    public shortInfoComponentExpand(expanded: boolean) {
        this.shortInfoComponentExpanded$.next(expanded);
    }

    public placeClose() {
        if (this.shortInfoComponentExpanded$.getValue()) {
            this.shortInfoComponentExpanded$.next(false);
        }
        if (this.activePlaceDetails$.getValue()) {
            this.activePlaceDetails$.next(null);
        }
        if (this.shortInfoComponentActive$.getValue()) {
            this.shortInfoComponentActive$.next(null);
        }
        this.closePrettyMuchEverythingEvent$.next(true);


    }

    public placesLoaded(type: string, places: PlacesEntry[]) {
        let override = {};
        override[type] = places;
        let contents: IShortInfoComponentFeatureSets = Object.assign({
            place: [],
            bike: [],
            car: [],
            taxi: [],
            mvg_station: [],
            lamppost: [],
            charging_station: [],
            mobility_station: [],
            erdgas: [],
        }, this.shortInfoComponentContents$.getValue(), override);
        this.shortInfoComponentContents$.next(contents);
    }

    public placesReloaded(places: PlacesEntry[]) {
        this.placesLoaded('place', places);
    }

    public bikesReloaded(places: PlacesEntry[]) {
        this.placesLoaded('bike', places);
    }

    public placeDetailsLoaded(details: PlacesDetails) {
        this.activePlaceDetails$.next(details);
    }

    public openPlaceInShortInfo(info: IShortInfoComponentContent) {
        this.closeSidebar();
        this.activePlaceDetails$.next(null);

        this.shortInfoComponentActive$.next(info);

        if (info.getClassType() == 'place') {
            let place = <PlacesEntry>info;
            this._ezp.loadDetails(place.id, "de").subscribe(details => this.placeDetailsLoaded(details));
        }
        if (info.getClassType() == 'bike') {
            let place = <PlacesEntry>info;
            this._ezp.loadDetails(place.id, "de").subscribe(details => this.placeDetailsLoaded(details));
        }
        if (info.getClassType() == 'escooter') {
            let place = <PlacesEntry>info;
            this._ezp.loadDetails(place.id, "de").subscribe(details => this.placeDetailsLoaded(details));
        }
    }

    public setTourismMode(active: boolean) {
        this.tourismMode$.next(active);
    }

    public overrideDefaultCategoriesFromUrl(url: string) {
        this.resetUrl = url;
        if (this.resetUrl === 'tourism') {
            this.DEFAULT_CATEGORIES = ['bb.2'];
        } else if (this.resetUrl === 'mobility') {
            this.DEFAULT_CATEGORIES = [];
        } else if (this.resetUrl === 'smart') {
            this.DEFAULT_CATEGORIES = ['bb.27445']; // M-WLAN
        } else { // branchen
            this.DEFAULT_CATEGORIES = [];
        }
    }

    public setDefaultCategory() {
        this.categoriesSelected$.next(this.DEFAULT_CATEGORIES);
        this.mwlanCategory$.next(this.resetUrl);
    }

    public setExtraCategory(cat: string) {
        this.extraCategory$.next(cat);
    }
    public removeExtraCategory() {
        this.extraCategory$.next(null);
    }
}
