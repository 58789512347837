import {Injectable} from "@angular/core";
import {AppStateService} from "./app-state.service";
import { LngLat } from 'mapbox-gl';

export interface ICacheSet {
    names: { [language: string]: string };
    zoom: number;
    min1: number;
    min2: number;
    max1: number;
    max2: number;
    tiles: number;
    url: string;
}

@Injectable()
export class OfflineCachingService {
    constructor(private _appState: AppStateService) {
    };

    public static MAP_SETS: { [id: string]: ICacheSet } = {
        "marienplatz": {
            "names": {
                "de": "Marienplatz"
            },
            "zoom": 18,
            "min1": 139490,
            "min2": 90967,
            "max1": 139510,
            "max2": 90982,
            "tiles": 5,
            "url": "https://api.mapbox.com/styles/v1/gradl/cizo6qkvd00352rqksnzl5h7u/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JhZGwiLCJhIjoiY2lrODRiZGpiMDAwaXZvbHcxZGlocjQ3diJ9.IqoqjID32zW6rQKJz32fvA"
        }
    };

    public isAvailable(): boolean {
        return false;
        /*
        return (typeof(window['ServiceWorker']) !== undefined);
        */
    }

    public isOnline(): boolean {
        if (typeof(navigator.onLine) !== 'boolean') {
            return true;
        }
        return navigator.onLine;
    }

    public getOfflineInitZoom(): number {
        return 18;
    }

    public getOfflineInitPos(): LngLat {
        return new LngLat(11.577465534210205, 48.13758290638434);
    }

    private loadSingleFile(url: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let fullfill = function () {
                if (image.naturalWidth) {
                    resolve(image);
                } else {
                    console.log("Rejected: ", url);
                    reject(image);
                }
                image.removeEventListener('load', fullfill);
                image.removeEventListener('error', fullfill);
            };

            let image = new Image();
            image.src = url;
            if (image.naturalWidth) {
                resolve();
            } else if (image.complete) {
                console.log("Rejected: ", url);
                reject();
            } else {
                image.addEventListener('load', fullfill);
                image.addEventListener('error', fullfill);
            }
        });
    }

    public doCache(cacheSet: ICacheSet): Promise<any> {
        return new Promise((resolve, reject) => {
            let promises: Promise<any>[] = [];
            for (let v1 = cacheSet.min1; v1 <= cacheSet.max1; v1++) {
                for (let v2 = cacheSet.min2; v2 <= cacheSet.max2; v2++) {
                    let url = cacheSet.url
                        .replace(/{z}/, cacheSet.zoom.toString())
                        .replace(/{x}/, v1.toString())
                        .replace(/{y}/, v2.toString());
                    promises.push(this.loadSingleFile(url));
                }
            }
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    }

    public doCacheById(id: string): Promise<any> {
        let cacheSet: ICacheSet = OfflineCachingService.MAP_SETS[id];
        return this.doCache(cacheSet);
    }

    public registerOnlineListener() {
        let updateOnlineStatus = () => {
            console.log("Setting Online Status to:", navigator.onLine);
            this._appState.setOnlineState(navigator.onLine);
        };
        window.addEventListener('load', () => {
            window.addEventListener('online', updateOnlineStatus);
            window.addEventListener('offline', updateOnlineStatus);
        });
        updateOnlineStatus();
    }
}
