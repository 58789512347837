import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Lamppost} from "../classes/Lamppost";
import {Messages, ShortInfoMessages} from "../messages/messages";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {AppStateService} from "../services/app-state.service";


@Component({
    selector: 'lamppost-details',
    templateUrl: 'lamppost-details.component.html',
    styles: [],
})
export class LamppostDetailsComponent {
    private _lamppost: Lamppost = null;
    @Input()
    set lamppost(lamppost: Lamppost) {
        this._lamppost = lamppost;
        if (lamppost) {
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                'https://mvg-mobilitaetsstation.de/smartcity-iframe/' +
                '?lamppost=' + lamppost.getId() + '&language=' + this.currentLanguage + '&stele=1'
            );
        }
    }

    get lamppost(): Lamppost {
        return this._lamppost;
    };

    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    public iframeUrl: SafeResourceUrl = null;
    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;


    constructor(private _appState: AppStateService, private sanitizer: DomSanitizer) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }


    onCloseClick(): void {
        this.close.emit(true);
    }
}
