import {Component, AfterViewInit} from '@angular/core';
import {
    RouteDefinition, RoutingTransportation, MVGRouteDefinition,
    MVGRoutingTransportation, RoutingMode
} from "../interfaces";
import {IRoutable} from "../classes/IRoutable";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {Messages, RoutingMessages} from "../messages/messages";
import {MapStateService} from "../services/map-state.service";
import {Routing} from "../classes/Routing";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'routing-panel',
    templateUrl: 'routing-panel.component.html',
    styles: [],
})
export class RoutingPanelComponent implements AfterViewInit {
    public messages: RoutingMessages;
    public language: string = 'de';

    public routeFrom: IRoutable = null;
    public routeTo: IRoutable = null;
    public customRoutingLocation: IRoutable;

    public formattedRouteTimes: { [transport: number]: string } = {};
    public mvgTypes = MVGRoutingTransportation;

    public transportation: RoutingTransportation;
    public allTransportationRoutes: { [transport: number]: RouteDefinition };
    public activeMVGRoute: MVGRouteDefinition = null;
    public allMvgRoutes: MVGRouteDefinition[];
    public routesLoaded: boolean = false;
    public routesLoading: boolean = false;

    public infoDistanceTimeHtml: string = '';
    public bottomViewShown: number = 0;

    public openedConnection: number = null;
    public switcherVisible: boolean = false;
    public formVisible: boolean = false;

    constructor(private _globalMapActions: MapStateService,
                private _appState: AppStateService,
                private _routingService: RoutingService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.routing;
            this.language = messages.language;
        });
    }

    public closeAndGotoShortInfo() {
        let gotoPlace: any = this.routeTo;
        if (gotoPlace) {
            this._appState.openPlaceInShortInfo(gotoPlace);
        } else {
            this._routingService.closePanel();
        }
    }

    public openForm() {
        this.switcherVisible = false;
        this.formVisible = true;
    }

    public switchFromTo() {
        this._routingService.switchFromTo(true);
    }

    /* Called from the UI */
    public routableFromChanged() {
        this._routingService.setFrom(this.routeFrom, true);
    }

    /* Called from the UI */
    public routableToChanged() {
        this._routingService.setTo(this.routeTo, true);
    }

    /* Called from the UI */
    public showRouteFullscreen() {
        this._routingService.setFullscreen();
    }

    public expandMvgRoutes() {
        this.bottomViewShown = 2;
    }

    public selectAlternativeMvgRoute(connection: MVGRouteDefinition) {
        this.bottomViewShown = 1;
        this._routingService.switchMvgRoute(connection);
    }

    public formatDuration(seconds: number) {
        let h = Math.floor(seconds / 3600),
            m = Math.floor((seconds - (h * 3600)) / 60),
            m2 = (m < 10 ? "0" + m.toString() : m.toString());

        if (h > 0) {
            if (this.language == 'de') {
                return h + ':' + m2 + ' Std.';
            } else {
                return h + ':' + m2 + ' h';
            }
        } else {
            if (this.language == 'de') {
                return m2 + ' Min.';
            } else {
                return m2 + ' min.';
            }
        }
    }

    public static formatLength(meter: number) {
        let km: number = Math.floor(meter / 1000);
        return km + "," + Math.floor((meter - km * 1000) / 100) + "km";
    }

    public recalcRoutingInformation() {
        let currRoute: RouteDefinition = this.allTransportationRoutes[this.transportation];

        if (!currRoute) {
            this.infoDistanceTimeHtml = "";
            this.bottomViewShown = 0;
            this.activeMVGRoute = null;
            return;
        }
        this.infoDistanceTimeHtml = this.formatDuration(currRoute.duration) + " - ";
        this.infoDistanceTimeHtml += "(" + RoutingPanelComponent.formatLength(currRoute.length) + ")";

        if (currRoute.transportationType == RoutingTransportation.MVG) {
            this.activeMVGRoute = <MVGRouteDefinition>currRoute;
        } else {
            this.activeMVGRoute = null;
        }

        this.bottomViewShown = 1;
    }

    public toggleOpenedConnection(idx: number) {
        if (this.openedConnection === idx) {
            this.openedConnection = null;
        } else {
            this.openedConnection = idx;
        }
    }

    private setRoutingInfo(routes: { [transport: number]: RouteDefinition }) {
        this.allTransportationRoutes = routes;
        if (routes && Object.keys(routes).length > 0) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else if (this.routeFrom == null || this.routeTo == null) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else if (this.routeFrom.getId() == this.routeTo.getId()) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else {
            this.routesLoaded = false;
            this.routesLoading = true;
        }
    }

    public currentlyAvailableRoutings() {
        return Routing.currentlyAvailableRoutings(this.routeFrom, this.routeTo);
    }

    ngAfterViewInit() {
        this.transportation = this._routingService.transportation$.getValue();
        this._routingService.transportation$.subscribe((trans: RoutingTransportation) => {
            this.transportation = trans;
            if (trans !== null && this.allTransportationRoutes) {
                this.recalcRoutingInformation();
            }
        });
        this._routingService.active$.subscribe((active: RoutingMode) => {
            if (active == RoutingMode.FULLSCREEN) {
                this.switcherVisible = false;
                this.formVisible = false;
                this.bottomViewShown = 0;
            } else if (active == RoutingMode.ACTIVE) {
                if (!this.formVisible && !this.switcherVisible) {
                    this.switcherVisible = true;
                }
            } else {
                this.bottomViewShown = 0;
                this.formVisible = false;
                this.switcherVisible = false;
            }
        });
        this._routingService.from$.subscribe((routeFrom: IRoutable) => {
            this.routeFrom = routeFrom;
        });
        this._routingService.to$.subscribe((routeTo: IRoutable) => {
            this.routeTo = routeTo;
        });
        this._routingService.allMvgRoutes$.subscribe((routes: MVGRouteDefinition[]) => {
            this.allMvgRoutes = routes;
        });
        this._routingService.allTransportationRoutes$.subscribe(this.setRoutingInfo.bind(this));
        this._appState.shortInfoComponentActive$.subscribe((info: IShortInfoComponentContent) => {
            window.setTimeout(() => {
                if (info) {
                    this.customRoutingLocation = info.getRoutableObject();
                } else {
                    this.customRoutingLocation = null;
                }
            }, 0);
        });
    }
}
