<div class="screen-darkener" *ngIf="expanded" [class.visible]="expanded" (click)="closeDetails()"></div>

<aside class="short-info-box-list" [class.visible]="visible"
       [class.mt_site]="activeContent?.getClassType() == 'mt_site'"
       [class.bike]="activeContent?.getClassType() == 'bike'"
       [class.escooter]="activeContent?.getClassType() == 'escooter'"
       [class.place]="activeContent?.getClassType() == 'place'"
       [class.mvg_station]="activeContent?.getClassType() == 'mvg_station'"
       [class.mobility_station]="activeContent?.getClassType() == 'mobility_station'"
       [class.charging_station]="activeContent?.getClassType() == 'charging_station'"
       [class.taxi]="activeContent?.getClassType() == 'taxi'"
       [class.erdgas]="activeContent?.getClassType() == 'erdgas'"
       [class.car]="activeContent?.getClassType() == 'car'"
       [class.expanded]="expanded"
       (panleft)="panLeft($event)" (panright)="panRight($event)" (panend)="panEnd($event)">
    <section class="short-info-box-holder carousel-prev">
        <ng-template [ngTemplateOutlet]="getTemplate(prevEntry)"
                     [ngTemplateOutletContext]="{ $implicit: prevEntry, iamexpanded: false, isprimary: false }"></ng-template>
    </section>
    <section class="short-info-box-holder carousel-current">
        <ng-template [ngTemplateOutlet]="getTemplate(activeContent)"
                     [ngTemplateOutletContext]="{ $implicit: activeContent, iamexpanded: expanded, isprimary: true }"></ng-template>
    </section>
    <section class="short-info-box-holder carousel-next">
        <ng-template [ngTemplateOutlet]="getTemplate(nextEntry)"
                     [ngTemplateOutletContext]="{ $implicit: nextEntry, iamexpanded: false, isprimary: false }"></ng-template>
    </section>
</aside>

<ng-template #placeTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box place info-box-place" [class.expanded]="iamexpanded" [class.not-expanded]="!iamexpanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section *ngIf="!iamexpanded" class="short-info-box place">
            <div class="data">
                <div class="data__content">

                    <div class="logo-holder">
                        <a [href]="entry?.mhpLink" target="_blank" rel="noopener" class="logo-link">
                            <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                                 [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                            <img *ngIf="!entry?.getShortInfoImage() && entry.needsFallbackImage()" src="images/fallback_image.jpg"
                                 alt="Keine Abbildung vorhanden" class="logo logo-fallback">
                        </a>
                    </div>

                    <!-- <a [href]="entry?.mhpLink" target="_blank" rel="noopener" class="detail-link"> -->
                        <h2>
                            {{ entry?.getShortInfoTitle() }}
                        </h2>
                        <div class="opening-hours" *ngIf="isprimary && activePlaceDetails?.hasOpeningHours()">
                            <img src="images/Icon-Uhr.svg" alt="Öffnungszeiten">
                            <span class="opening-hours-label">{{messages.opening_hours}}</span>
                            <span class="opening-hours-time">{{ activePlaceDetails?.getOpeningHoursFormatted() }}</span>
                        </div>
                    <!-- </a> -->
                </div>
                <div class="data__meta">
                    <routing-switcher (change)="routingChanged()"></routing-switcher>

                    <a *ngIf="entry.mhpLink" [href]="entry?.mhpLink" target="_blank" rel="noopener" class="detail-link">
                        <img src="images/Icon-Info.svg" width="24" alt="Details">&nbsp;&nbsp;Details anzeigen
                    </a>
                    <!--
                    <a [href]="entry?.mhpLink" target="_blank" rel="noopener" class="detail-link">
                        <img src="images/Icon-Info.svg" width="24" alt="Details">&nbsp;&nbsp;
                        Details anzeigen
                    </a>
                    -->
                </div>
            </div>
        </section>
        <place-details *ngIf="iamexpanded" [placeDetails]="placeDetails" (close)="closeDetails()"></place-details>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #eScooterTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-escooter">
            <div class="data">
                <img src="images/logo_tier.png" alt="TIER Logo" class="logo">
                <h2>{{ activePlaceDetailsEScooter?.getShortInfoTitle() }}</h2>
                <div class="battery-level" *ngIf="isprimary">
                    <img src="images/batteriefuellstand-01.svg" alt="Batteriefüllstand" class="batterie">
                    {{ activePlaceDetailsEScooter?.getBatteryLevel() }}%
                </div>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #bikeFreefloaterTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-bike short-info-box-bike-freefloater">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.bike_free }}</h2>
                <div class="bike-id">
                    {{ messages.bike_no }} {{ activePlaceDetailsBike?.getBikeId() }}
                </div>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #bikeStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.bike_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="activePlaceDetailsBike">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.bike_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.bikes_available }}</span>
                </div>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #pedelecTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.pedelec_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="available">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.pedelec_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.pedelecs_available }}</span>
                </div>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #etrikeTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.etrike_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="available">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.etrike_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.etrike_available }}</span>
                </div>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #carTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-car not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <ng-container *ngIf="activePlaceDetailsCar?.getVendorName">
                    <h2>{{ activePlaceDetailsCar?.getVendorName() }}</h2>
                    <div class="model" [innerHtml]="activePlaceDetailsCar?.getModelName()"></div>
                    <div class="license" [innerHtml]="activePlaceDetailsCar?.getCarID()"></div>
                </ng-container>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #stattautoTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-car info-box-stattauto not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-stattauto">
            <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                 [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
            <h2>{{ entry?.getShortInfoTitle() }}</h2>

            <short-info-stattauto [station]="entry"></short-info-stattauto>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #mobilityStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-mobility-station not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <short-info-mobility-station [station]="entry" (close)="close()"></short-info-mobility-station>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #chargingStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-charging-station not-expanded mvg-entry-list">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <h2>{{ entry?.getShortInfoTitle() }}</h2>
        <short-info-chargers [stations]="[placeDetails]" *ngIf="placeDetails"></short-info-chargers>
        <routing-switcher (change)="routingChanged()"></routing-switcher>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #taxiStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-taxi not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box  short-info-box-taxi">
            <div class="data">
                <img src="images/card-taxi_bunt.svg" class="logo">
                <h2>{{ entry?.getShortInfoTitle() }}</h2>
                <div class="available" *ngIf="placeDetails?.payload?.available">
                    {{ messages.taxis_available_pre }}
                    {{ placeDetails?.payload?.available }}
                    <span *ngIf="placeDetails.payload.available == 1">{{ messages.taxi_available }}</span>
                    <span *ngIf="placeDetails.payload.available > 1">{{ messages.taxis_available }}</span>
                </div>
                <div class="available" *ngIf="placeDetails?.payload?.available == 0">
                    {{ messages.taxis_available_none }}
                </div>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #erdgasTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-erdgas not-expanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box">
            <div class="data" style="margin: 20px">
                <img src="images/logo_erdgas.png" alt="CNG Erdgas" class="logo" style="max-width:70px; float: right">
                <h2>{{ placeDetails?.getName() }}</h2>
                <div class="brand">{{ placeDetails?.street }}</div>
                <div class="opening-hours" style="margin-top:20px">
                    <span class="descriptionTitle">Öffnungszeiten:</span>
                    {{ placeDetails?.payload.oeffnungszeiten }}h
                </div>
                <div class="payment-methods">
                    <span class="descriptionTitle">Zahlungsmittel:</span>
                    {{ placeDetails?.payload.zahlung }}
                </div>
                <div class="gas">
                    <span class="descriptionTitle">Gas:</span>
                    {{ placeDetails?.payload.bio_bezeichnung }}, {{ placeDetails?.payload.gasqualitaet }}
                </div>
                <div class="status">
                    <span class="descriptionTitle">Status:</span>&nbsp;
                    <ng-container *ngIf="placeDetails?.payload.tanken_ok == 'j'">Verfügbar</ng-container>
                    <ng-container *ngIf="placeDetails?.payload.tanken_ok == 'n'">Nicht verfügbar</ng-container>
                </div>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #entryTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box" [class.expanded]="iamexpanded" [class.not-expanded]="!iamexpanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section *ngIf="!iamexpanded" class="short-info-box">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <div class="call-to-actions">
                    <button class="btn btn-link btn-routing" (click)="callRouting(entry)" title="Routenplaner">
                        <img src="images/Icon-Route.svg" alt="Routing">
                    </button>
                </div>
                <h2>{{ entry?.getShortInfoTitle() }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoAddress()"></div>
            </div>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>

<ng-template #mvgTmpl let-entry let-isprimary="isprimary">
    <article class="info-box">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section class="short-info-box short-info-box-mvg">
            <short-info-mvv-departures [station]="entry" [minimalistic]="false"></short-info-mvv-departures>
            <routing-switcher (change)="routingChanged()"></routing-switcher>
        </section>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>


<ng-template #lamppostTmpl let-entry let-iamexpanded="iamexpanded">
    <article class="info-box info-box-lamppost" [class.expanded]="iamexpanded" [class.not-expanded]="!iamexpanded">
        <div *ngIf="isPannable()" (click)="setPrev()" class="short-info-slider short-info-slider-left"></div>
        <section *ngIf="!iamexpanded" class="short-info-box short-info-box-lamppost"
                 [class.clickable]="isLamppostExpandable()" (click)="isLamppostExpandable() && expandView()">
            <div class="data">
                <h2>{{ entry?.getShortInfoTitle() }}</h2>
                <div class="features">
                    <div class="important">
                        <span *ngIf="lamppost_values && lamppost_values.temperature">
                            <img src="./images/Icons-Wetter-Temperatur.svg" alt="Temperatur" width="20">
                            <span>{{ lamppost_values.temperature }} °C</span>
                        </span>
                        <span *ngIf="lamppost_values && lamppost_values.humidity">
                            <img src="./images/Icons-Wetter-Luftfeuchte.svg" alt="Temperatur" width="20">
                            <span>{{ lamppost_values.humidity }} %</span>
                        </span>
                        <span *ngIf="lamppost_values && lamppost_values.wifi">
                            <img src="./images/lamppost_wifi_colored.png" alt="WiFi" width="35">
                        </span>
                    </div>
                    <ul class="not-important">
                        <li *ngFor="let feature of lamppost_capabilities">
                            <img [src]="feature.icon" [alt]="feature.title" width="20">
                        </li>
                    </ul>
                </div>
                <div class="zeigehand" *ngIf="lamppost_capabilities.length > 1">
                    <img src="images/zeigehand.png" height="40" alt="Klick mich">
                </div>
            </div>
        </section>
        <lamppost-details *ngIf="iamexpanded" [lamppost]="lamppost" (close)="closeDetails()"></lamppost-details>
        <div *ngIf="isPannable()" (click)="setNext()" class="short-info-slider short-info-slider-right"></div>
    </article>
</ng-template>
