<div id="map_sidebar_holder" [class.revealed]="sidebarShown">
    <main>
        <map-gl></map-gl>
        <search-bar></search-bar>
        <short-info-box></short-info-box>
        <routing-panel></routing-panel>
        <mwlan-explorer></mwlan-explorer>
    </main>
    <sidebar></sidebar>
</div>
