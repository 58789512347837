import {RoutingTransportation} from "../interfaces";
import {IRoutable} from "./IRoutable";
import { GeoFunctions } from './GeoFunctions';

export class Routing {
    public static AVAILABLE_ROUTINGS = [
        {
            'id': RoutingTransportation.WALKING,
            'alt': 'Fußgänger',
            'img_inactive': 'images/Icon-Fussgaenger.svg',
            'img_active': 'images/Icon-Fussgaenger-active.svg',
        },
        {
            'id': RoutingTransportation.SIGHTSEEING,
            'alt': 'Sightseeing',
            'img_inactive': 'images/routing_sightseing_grey.svg',
            'img_active': 'images/routing_sightseing_orange.svg',
        },
        {
            'id': RoutingTransportation.BIKE,
            'alt': 'Fahrrad',
            'img_inactive': 'images/Icon-Rad.svg',
            'img_active': 'images/Icon-Rad-active.svg',
        },
        {
            'id': RoutingTransportation.MVG,
            'alt': 'Öffentlich',
            'img_inactive': 'images/Icon-Nahverkehr.svg',
            'img_active': 'images/Icon-Nahverkehr-active.svg',
        },
        {
            'id': RoutingTransportation.CAR,
            'alt': 'Auto',
            'img_inactive': 'images/Icon-Auto.svg',
            'img_active': 'images/Icon-Auto-active.svg',
        },
    ];

    public static currentlyAvailableRoutings(routeFrom: IRoutable, routeTo: IRoutable) {
        let distance = 0;
        if (routeTo && routeFrom) {
            distance = GeoFunctions.getDistance(routeFrom.getPosition(), routeTo.getPosition());
        }
        let available = [];
        Routing.AVAILABLE_ROUTINGS.forEach((routing) => {
            if (routing.id == RoutingTransportation.MVG) {
                return;
            }
            if (routing.id == RoutingTransportation.WALKING && distance > 8000) {
                return;
            }
            if (routing.id == RoutingTransportation.SIGHTSEEING) {
                return;
            }
            if (routing.id == RoutingTransportation.CAR && distance < 8000) {
                return;
            }
            available.push(routing);
        });
        return available;
    }
}