import {Component} from '@angular/core';
import {Messages, MWLANMessages} from "../messages/messages";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import {MVGStateService} from "../services/mvg-state.service";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import { EchtzeitproxyService } from '../apis/echtzeitproxy';
import { MyLocation } from '../classes/MyLocation';
import { LngLat } from 'mapbox-gl';

@Component({
    selector: 'mwlan-explorer',
    templateUrl: 'mwlan-explorer.component.html',
    styles: [],
})
export class MwlanExplorerComponent {
    public messages: MWLANMessages;
    public language = 'de';
    public revealed = false;
    public hidden = false;
    public categorySelected;
    public itemsSelected = [];
    public myLocation: LngLat;

    constructor(private _globalMapActions: MapStateService,
                private _mvgActions: MVGStateService,
                private _mapState: MapStateService,
                private _appState: AppStateService,
                private _ezp: EchtzeitproxyService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.mwlan;
            this.language = messages.language;
        });
        this._appState.closePrettyMuchEverythingEvent$.subscribe(() => {
            this.revealed = false;
        });
        this._appState.shortInfoComponentActive$.subscribe((component: IShortInfoComponentContent) => {
            if (component) {
                this.revealed = false;
                this.hidden = true;
            } else {
                this.hidden = false;
            }
        });
        this._appState.mwlanCategory$.subscribe(category => this.categorySelected = category);
        this._mvgActions.mvgComponentChanged.subscribe(() => {
            this.rebuildItemsSelected();
        });
        this._appState.categoriesSelected$.subscribe(() => {
            this.rebuildItemsSelected();
        });
        this._mapState.myLocationLngLat$.subscribe((pos) => {
            this.myLocation = pos;
        });

        this.rebuildItemsSelected();
    }

    public reveal() {
        this.revealed = true;
    }

    public hide() {
        this.revealed = false;
    }

    public toggle() {
        this.revealed = !this.revealed;
    }

    public selectCategory(cat: string) {
        this.categorySelected = cat;

        // Falls der Kartenstil bei anderen Kategorien geändert werden soll:
        this._appState.setTourismMode(cat === 'tourism');
    }

    private setSelection(newItems: string[]) {
        this._mvgActions.setLamppostActive(newItems.indexOf('lampposts') !== -1);
        this._mvgActions.setPublicTransportActive(newItems.indexOf('publictransport') !== -1);
        this._mvgActions.setChargingStationActive(newItems.indexOf('charging') !== -1);
        this._mvgActions.setCarsharingActive(newItems.indexOf('carsharing') !== -1);
        this._mvgActions.setMobilityStationActive(newItems.indexOf('mobility') !== -1);
        this._mvgActions.setBikeActive(newItems.indexOf('bikesharing') !== -1);
        this._mvgActions.setTaxiActive(newItems.indexOf('taxi') !== -1);

        let bbCats = newItems.filter(val => val.match(/^\w\w\./));
        this._appState.setCategoriesSelected(bbCats);
    }

    private rebuildItemsSelected() {
        this.itemsSelected = Object.assign([], this._appState.categoriesSelected$.getValue());
        if (this._mvgActions.lamppostActive$.getValue()) {
            this.itemsSelected.push('lampposts');
        }
        if (this._mvgActions.publicTransportActive$.getValue()) {
            this.itemsSelected.push('publictransport');
        }
        if (this._mvgActions.chargingStationsActive$.getValue()) {
            this.itemsSelected.push('charging');
        }
        if (this._mvgActions.carsharingActive$.getValue()) {
            this.itemsSelected.push('carsharing');
        }
        if (this._mvgActions.mobilityStationActive$.getValue()) {
            this.itemsSelected.push('mobility');
        }
        if (this._mvgActions.bikeActive$.getValue()) {
            this.itemsSelected.push('bikesharing');
        }
        if (this._mvgActions.taxiActive$.getValue()) {
            this.itemsSelected.push('taxi');
        }
    }

    public selectItem(it: string) {
        let added;
        let newItems = Object.assign([], this.itemsSelected);
        if (newItems.indexOf(it) === -1) {
            newItems.push(it);
            added = true;
        } else {
            newItems = newItems.filter(sel => sel !== it);
            added = false;
        }
        this.setSelection(newItems);
        if (it === 'lampposts' && added) {
            this._mapState.triggerSetCenterZoom(new LngLat(11.411287, 48.141163), 17);
        } else if (it === 'bikesharing' && added) {
            this.gotoClosestBike();
        } else {
            // this._mapState.triggerGotoPos();
        }
    }

    private gotoClosestBike() {
        if (!this.myLocation) {
            return;
        }
        this._ezp.getClosestPlace('alias.mv.all-bikes', this.myLocation.lat, this.myLocation.lng, this.language).subscribe(place => {
            if (place) {
                this._mapState.triggerSetCenterZoom(place.position, 18);
            }
        })
    }

    public isItemSelected(cat: string): boolean {
        return this.itemsSelected.indexOf(cat) !== -1;
    }

    public resetCategories() {
        this._appState.setDefaultCategory();
        this._mvgActions.reset();
    }
}
