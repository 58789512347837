import {PlacesDetails} from "./PlacesDetails";

export class PlacesDetailsBike extends PlacesDetails {
    public isStation() {
        return this.categories.find(cat => cat.id == 'mv.bike.station') !== undefined;
    }
    public isPedelec() {
        return this.categories.find(cat => cat.id == 'mv.pedelec') !== undefined;
    }
    public isEtrike() {
        return this.categories.find(cat => cat.id == 'mv.etrike') !== undefined;
    }
    public isFreefloater() {
        return this.categories.find(cat => cat.id == 'mv.bike.free') !== undefined;
    }
    public getBikeId() {
        return this.id.replace('mv.bike', '');
    }
    public getStationAvailable() {
        if (!this.payload || this.payload['available'] === undefined) {
            return null;
        }
        return this.payload['available'];
    }
}
