import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Marker, Map, MarkerOptions, LngLat } from 'mapbox-gl';
import { ClusteredPlaces } from '../apis/echtzeitproxy';

const SHOW_CLUSTER_OUTLINES = false;

const MIN_ZOOM = 9;
//const MOBILITY_MIN_ZOOM = 14; // Synch map-carsharing, map-charging, map-bikesharing and map-mvv
//const MOBILITY_ALL_MIN_ZOOM = 17; // Synch map-carsharing, map-charging,map-bikesharing and map-mvv

@Injectable()
export class EScooterLayerService {
    private map: Map;
    private foundEScooterLocations: PlacesEntry[] = [];
    private escooterActiveData: PlacesEntry = null;
    private markers: { [id: string]: Marker } = {};
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    //private Z_INDEX_OFFSET: 0;

    private clustersShapes: string[] = [];
    private clusterMarkers: { [id: string]: Marker } = {};

    constructor(private _mapState: MapStateService,
                private _mvgState: MVGStateService,
                private _appState: AppStateService,
                private _epBBLoader: EpBoundingboxLoaderService,
                private _routingService: RoutingService) {
    }

    public init(map: Map) {
        this.map = map;
        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() === 'escooter') {
                this.escooterActiveData = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.escooterActiveData = null;
            }
            this.markerCollection.setActiveElement(this.escooterActiveData);
        });

        this._epBBLoader.setListenerCategories('escooter', []).subscribe((data) => {
            this.foundEScooterLocations = data.places.filter(place => !place.isClusteredPlace);
            this.setCurrentClusters(data.clusters);
            this._appState.placesLoaded('escooter', this.foundEScooterLocations);
            this.showCurrentMarkers();
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.escooterActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.escooterActive$.subscribe(this.showCurrentMarkers.bind(this));

        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        if (this._mvgState.escooterActive$.getValue()) {
            if (this._mapState.zoom$.getValue() >= MIN_ZOOM) {
                this._epBBLoader.setListenerCategories('escooter', ['mv.escooter'])
            } else {
                this._epBBLoader.setListenerCategories('escooter', [])
            }
        } else {
            this._epBBLoader.setListenerCategories('escooter', [])
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }
    }

    public addInactiveMarker(escooter: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        icon.classList.add('escooter-marker');
        icon.classList.add('inactive');
        icon.style.width = '30px';
        icon.style.height = '42px';

        icon.src = null;
        for (let i in escooter.categories) {
            if (escooter.categories[i].id == 'mv.escooter.tier') {
                icon.src = 'images/ic_marker_erollertier-2.png';
            }
        }

        if (!icon.src) {
            return;
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(escooter);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(escooter.position);
        marker.addTo(this.map);
        this.markers[escooter.id] = marker;
    }


    public addActiveMarker(escooter: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        opts.offset = [-11, 5];
        icon.classList.add('escooter-marker');
        icon.classList.add('active');
        icon.style.width = '42px';
        icon.style.height = '47px';

        icon.src = null;
        for (let i in escooter.categories) {
            if (escooter.categories[i].id == 'mv.escooter.tier') {
                icon.src = 'images/ic_marker_erollertier_selected@3x.png';
            }
        }

        if (!icon.src) {
            return;
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(escooter);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(escooter.position);
        marker.addTo(this.map);
        this.markers[escooter.id] = marker;
    }

    /*
     * The center should be somewhere within the center square of the tile: 1/4 padding at each cordner
     * Should be random, but always at the same place within a given tile
     */
    private getRandomizedCenter(places: ClusteredPlaces): LngLat {
        const latWidth = places.bounds.northwest.lat - places.bounds.southeast.lat,
            lngHeight = places.bounds.southeast.lng - places.bounds.northwest.lng,

            // Random numbers fixed for each bounding box
            hashBase = JSON.stringify(places.bounds).split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0),
            percent1 = (hashBase % 7) / 7,
            percent2 = (hashBase % 6) / 6,
            rndOffsetWidth = Math.abs((latWidth / 2) * percent1),
            rndOffsetHeight = Math.abs((lngHeight / 2) * percent2);

        return new LngLat(
            places.bounds.northwest.lng + (lngHeight / 4) + rndOffsetHeight,
            places.bounds.southeast.lat + (latWidth / 4) + rndOffsetWidth
        );
    }

    private createClusterOutline(cluster: ClusteredPlaces, id: string): void {
        const geojson: any = {
            'id': id,
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': [
                            [cluster.bounds.northwest.lng, cluster.bounds.northwest.lat],
                            [cluster.bounds.northwest.lng, cluster.bounds.southeast.lat],
                            [cluster.bounds.southeast.lng, cluster.bounds.southeast.lat],
                            [cluster.bounds.southeast.lng, cluster.bounds.northwest.lat],
                            [cluster.bounds.northwest.lng, cluster.bounds.northwest.lat],
                        ]
                    }
                },
            },
            'paint': {
                'line-width': 0.3,
                'line-color': 'blue'
            }
        };

        this.map.addLayer(geojson);
    }

    private createClusterIcon(cluster: ClusteredPlaces): Marker {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        const center = this.getRandomizedCenter(cluster);

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'center';
        icon.classList.add('escooter-cluster-marker');
        icon.classList.add('active');
        icon.style.width = '35px';
        icon.style.height = '49px';

        icon.src = 'images/ic_marker_erollertier_kreis_2.png';

        icon.addEventListener('click', ev => {
            this._mapState.triggerSetCenterZoom(center, this.map.getZoom() + 1);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(center);
        marker.addTo(this.map);

        return marker;
    }

    private setCurrentClusters(clusters: ClusteredPlaces[]) {
        this.clustersShapes.forEach(shape => {
            this.map.removeLayer(shape);
        });

        this.clustersShapes = [];

        const activeClusters = [];
        if (!this._routingService.active$.getValue()) {
            clusters.forEach(cluster => {
                const clusterId = JSON.stringify(cluster.bounds).replace(/[^\d]/g, '');
                activeClusters.push(clusterId);
                if (!this.clusterMarkers[clusterId]) {
                    console.log("Building marker: " + clusterId);
                    this.clusterMarkers[clusterId] = this.createClusterIcon(cluster);
                }

                if (SHOW_CLUSTER_OUTLINES) {
                    const clusterId = 'outline_' + Math.round(Math.random() * 100000000).toString(10);
                    this.createClusterOutline(cluster, clusterId);
                    this.clustersShapes.push(clusterId);
                }
            });
        }

        Object.keys(this.clusterMarkers).forEach(markerKey => {
            if (activeClusters.indexOf(markerKey) === -1) {
                this.clusterMarkers[markerKey].remove();
                delete(this.clusterMarkers[markerKey]);
            }
        });
    }


    private showCurrentMarkers() {
        let active: boolean = this._mvgState.escooterActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue()) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'escooter') {
                collection.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'escooter') {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (active && zoom >= MIN_ZOOM) {
                collection = this.foundEScooterLocations;
            }
        }
        if (this.escooterActiveData && collection.filter(place => place.id == this.escooterActiveData.id).length === 0) {
            collection.push(this.escooterActiveData);
        }
        this.markerCollection.setNewElementCollection(collection);
    }
}
