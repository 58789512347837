import {Injectable} from "@angular/core";
import {SightseeingRoute} from "../classes/SightseeingRoute";
import {IRoutable} from "../classes/IRoutable";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import { LngLatBounds } from 'mapbox-gl';
import { GeoFunctions } from '../classes/GeoFunctions';


@Injectable()
export class WalkingRouteService {
    constructor(private _places: EchtzeitproxyService) {}

    private static calcAdditionalRoutes(currRoute: SightseeingRoute, places: PlacesEntry[], level: number): SightseeingRoute[] {
        let pre = '';
        for (let i = 0; i < level; i++) {
            pre += '  ';
        }

        let routes = [currRoute];
        for (let i = 0; i < places.length; i++) {
            if (currRoute.shouldBeAdded(places[i])) {
                let newRoute: SightseeingRoute = currRoute.addInbetweenPlace(places[i]),
                    placesWithout: PlacesEntry[] = [];
                for (let j = 0; j < places.length; j++) {
                    if (j !== i) {
                        placesWithout.push(places[j]);
                    }
                }
                let subRoutes = WalkingRouteService.calcAdditionalRoutes(newRoute, placesWithout, level + 1);
                for (let j = 0; j < subRoutes.length; j++) {
                    routes.push(subRoutes[j]);
                }
            }
        }
        return routes;
    }

    public static calculateBestTour(from: IRoutable, to: IRoutable, places: PlacesEntry[]): SightseeingRoute {
        let startRoute = new SightseeingRoute(from, to, []);
        let allRoutes = WalkingRouteService.calcAdditionalRoutes(startRoute, places, 0);
        let bestRoute: SightseeingRoute = null;
        allRoutes.forEach((route: SightseeingRoute) => {
            if (bestRoute === null || route.value < bestRoute.value) {
                bestRoute = route;
            }
        });

        return bestRoute;
    }

    public getBestWalkingTour(from: IRoutable, to: IRoutable): Promise<SightseeingRoute> {
        return new Promise<SightseeingRoute>((accept, reject) => {
            let bounds: LngLatBounds = GeoFunctions.lngLatsToBounds(from.getPosition(), to.getPosition());
            let boundsExt: LngLatBounds = GeoFunctions.extendBoundingboxLngLat(bounds, 0.1);

            this._places.getNumSightsForBoundingBox(boundsExt, 6, "de").subscribe((places: PlacesEntry[]) => {
                let route = WalkingRouteService.calculateBestTour(from, to, places);
                accept(route);
            });
        });
    }
}
