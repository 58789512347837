import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PlacesDetails} from "../classes/PlacesDetails";
import {SpeechService} from "../services/speech.service";
import {MapStateService} from "../services/map-state.service";
import {DetailsMessages, Messages} from "../messages/messages";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'place-details',
    templateUrl: 'place-details.component.html',
    styles: [],
})
export class PlaceDetailsComponent implements OnChanges {
    @Input() placeDetails: PlacesDetails;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    public messages: DetailsMessages;
    public disabledWww: boolean = true;
    public disabledPhone: boolean = true;
    public disabledPhotoupload: boolean = true;
    public disabledMvg: boolean = true;
    public iframeUrl: SafeResourceUrl = null;

    constructor(
        private speechService: SpeechService,
        private _globalMapActions: MapStateService,
        private _appState: AppStateService,
        private sanitizer: DomSanitizer
    ) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.details;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['placeDetails'] && this.placeDetails) {
            this.disabledWww = (this.placeDetails.mhpLink == '');
            this.disabledPhone = true;
            this.disabledMvg = true;
            this.disabledPhotoupload = false;
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.placeDetails.mhpLink + '?device=smartcity-web'
            );
        } else {
            this.iframeUrl = null;
        }
    }

    public onCloseClick(): void {
        this.close.emit(true);
    }

    public playPlaceDescription(language: string = 'de') {
        if (!this.placeDetails) {
            return;
        }
        let text = this.placeDetails.getName(language) + "\n";
        text += this.placeDetails.getDescription(language).replace(/<br *\/?>/gi, "\n");
        this.speechService.playText(text, language);
    }

    public clickWebsite(): void {
        window.open(this.placeDetails.mhpLink, '_blank');
    }

    public clickMvg(): void {

    }

    public clickTelephone(): void {

    }

    public clickPhotoupload(): void {

    }
}
