import {Injectable} from "@angular/core";
import {Observable, of} from 'rxjs';
import {map} from "rxjs/operators";
import {Lamppost} from "../classes/Lamppost";
import {SmartCityObject} from "../classes/SmartCityObject";
import {HttpClient, HttpParams} from "@angular/common/http";
import {LamppostCurrentValues} from "../interfaces";
import { LngLat, LngLatBounds } from 'mapbox-gl';

const MOBILITAETSSTATIONEN = [
    /*
    // Projekt: ST
    {
        position: new LatLng(48.146359, 11.413565),
        title: "Wiesentfelser Straße",
        address: "Wiesentfelser Straße 53",
    },
    {
        position: new LatLng(48.155612, 11.414180),
        title: "Aubing",
        address: "Colmdorfstraße 34",
    },
    {
        position: new LatLng(48.142897, 11.423263),
        title: "Neuaubing",
        address: "Bodenseestraße 238",
    },
    {
        position: new LatLng(48.148578, 11.441896), // Inoffizielle Koordinaten
        title: "Westkreuz",
        address: "Friedrichshafener Straße 11",
    },
    {
        position: new LatLng(48.149130, 11.434231),
        title: "Mainaustraße",
        address: "Mainaustraße 73",
    },
    {
        position: new LatLng(48.147359, 11.419573),
        title: "Freienfelsstraße",
        address: "Wiesentfelser Straße 16",
    },
    {
        position: new LatLng(48.155092, 11.427931),
        title: "Leienfelsstraße",
        address: "Ilse-Fehling-Straße, Wendehammer",
    },
    {
        position: new LatLng(48.139356, 11.410981),
        title: "Freiham",
        address: "Hans-Stützle-Straße 10",
    },

    // Projekt: C2S
    {
        position: new LatLng(48.126419, 11.566893),
        title: "Am Glockenbach",
        address: "Am Glockenbach 14",
    },
    {
        position: new LatLng(48.124504, 11.555587),
        title: "Zenettiplatz",
        address: "Zenettiplatz 2",
    },
    {
        position: new LatLng(48.129196, 11.558318),
        title: "Goetheplatz (Nord)",
        address: "Lindwurmstraße 85",
    },
    {
        position: new LatLng(48.128939, 11.558337),
        title: "Goetheplatz (Süd)",
        address: "",
    },
    {
        position: new LatLng(48.116864, 11.541665),
        title: "Harras",
        address: "Kidlerplatz",
    },
    */
];


const STADTTEILLABORE = [
    {
        position: new LngLat(11.4355147, 48.1497092),
        title: 'Stadtteillabor'
    }
];

const BUERGERBUEROS = [
    {
        position: new LngLat(11.4626188, 48.1472666),
        title: 'Bürgerbüro'
    }
];

@Injectable()
export class SmartCityService {
    public url: string;

    constructor(private http: HttpClient) {
        if (window.location.href.indexOf('app.muenchen-k.de') !== -1) {
            this.url = 'https://app.muenchen-k.de/smartcity-map/iframe/cip-proxy.php';
        } else if (window.location.href.indexOf('.local') !== -1) {
            this.url = 'https://poi-map-lamppost.local/cip-proxy.php';
        } else {
            this.url = 'https://mvg-mobilitaetsstation.de/smartcity-iframe/cip-proxy.php';
        }
    }

    loadLamppost(id: string): Observable<Lamppost> {
        let params = new HttpParams()
            .set('operation', 'get-lamppost')
            .set('id', id);

        return this.http.get(this.url, {params: params}).pipe(map((input: any): Lamppost => {
            return new Lamppost(input);
        }));
    }

    getLampposts(box: LngLatBounds): Observable<Lamppost[]> {
        let params = new HttpParams()
            .set('operation', 'get-lampposts');

        return this.http.get(this.url, {params: params}).pipe(map((input: Array<any>): any => {
            let posts: Lamppost[] = [];
            input.forEach((inp) => {
                posts.push(new Lamppost(inp));
            });

            return posts;
        }));
    }

    getLamppostCurrentValues(lamppost: Lamppost): Observable<LamppostCurrentValues> {
        let params = new HttpParams()
            .set('operation', 'get-current-values')
            .set('id', lamppost.id)
            .set('sensors', lamppost.connectedSensorIds.join(","));

        return this.http.get(this.url, {params: params}).pipe(map((data: any): LamppostCurrentValues => {
            if (lamppost.connectedSensorIds.length === 0) {
                return {
                    "temperature": null,
                    "humidity": null,
                    "wifi": lamppost.hasWifi,
                };
            } else {
                return {
                    "temperature": data['temperature'],
                    "humidity": data['humidity'],
                    "wifi": lamppost.hasWifi,
                };
            }
        }));
    }

    /*
    private temperatureLoaderId: string = null;
    private temperatureLoaderPeriod: string = null;
    private temperatureLoader$: BehaviorSubject<{ temperatures: LamppostTemperature[], humidities: LamppostHumidity[] }> = null;

    private reloadLamppostTemperatures() {
        let now: Date = new Date();
        let from: Date;
        let observer = this.temperatureLoader$;
        let id: string = this.temperatureLoaderId;
        let period: string = this.temperatureLoaderPeriod;
        let interval: string;
        switch (period) {
            case 'hour':
                from = new Date(now.getTime() - 3600 * 1000);
                interval = 'minute';
                break;
            case 'day':
                from = new Date(now.getTime() - 24 * 3600 * 1000);
                interval = 'hour';
                break;
            case 'month':
                from = new Date(now.getTime() - 31 * 24 * 3600 * 1000);
                interval = 'day';
                break;
            default:
            case 'week':
                from = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
                interval = 'hour';
                break;
        }
        this.getLamppostTemperatures(this.temperatureLoaderId, from, now, interval).subscribe((data) => {
            observer.next(data);

            window.setTimeout(() => {
                if (this.temperatureLoaderId == id && this.temperatureLoaderPeriod == period) {
                    this.reloadLamppostTemperatures();
                }
            }, 5000);
        });
    }

    public getTemperatureLoader(lamppost: Lamppost, period: string): BehaviorSubject<{ temperatures: LamppostTemperature[], humidities: LamppostHumidity[] }> {
        if (this.temperatureLoaderId != lamppost.id || this.temperatureLoaderPeriod != period) {
            this.temperatureLoader$ = new BehaviorSubject<{ temperatures: LamppostTemperature[], humidities: LamppostHumidity[] }>(null);
            this.temperatureLoaderId = lamppost.id;
            this.temperatureLoaderPeriod = period;
            this.reloadLamppostTemperatures();
        }
        return this.temperatureLoader$;
    }

    getLamppostTemperatures(lamppostId: string, from: Date, to: Date, interval: string): Observable<{ temperatures: LamppostTemperature[], humidities: LamppostHumidity[] }> {
        console.log("Loading: ", lamppostId, interval);
        let params = new HttpParams()
            .set('operation', 'get-values')
            .set('id', lamppostId)
            .set('interval', interval)
            .set('from', from.getTime().toString())
            .set('to', to.getTime().toString());

        return this.http.get(this.url, {params: params}).pipe(map((input: Array<any>): any => {
            console.log(this.url, input);
            let temperatures: LamppostTemperature[] = [],
                humidities: LamppostHumidity[] = [];

            input.forEach((res) => {
                temperatures.push({
                    "time": new Date(res['timestamp']),
                    "temperature": res['temperature']
                });
                humidities.push({
                    "time": new Date(res['timestamp']),
                    "humidity": res['humidity']
                });
            });

            return {
                'temperatures': temperatures,
                'humidities': humidities,
            };
        }));
    }
    */

    public getMobilitaetsstationen(): Observable<SmartCityObject[]> {
        let places: SmartCityObject[] = [];
        let counter = 0;
        MOBILITAETSSTATIONEN.forEach((pos) => {
            counter++;
            places.push(new SmartCityObject('mobilitaetsstation', 'smartcity' + counter, 'Mobilitätsstation', pos.title, pos.position));
        });
        return of(places);
    }

    public getQuartiersboxen(): Observable<SmartCityObject[]> {
        let places: SmartCityObject[] = [];
        let counter = 0;
        BUERGERBUEROS.forEach((pos) => {
            counter++;
            places.push(new SmartCityObject('buergerbuero', 'smartcity' + counter, 'Bürgerbüro', pos.title, pos.position));
        });
        STADTTEILLABORE.forEach((pos) => {
            counter++;
            places.push(new SmartCityObject('stadtteillabor', 'smartcity' + counter, 'Stadtteillabor', pos.title, pos.position));
        });

        return of(places);
    }
}
