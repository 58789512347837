import {Injectable} from "@angular/core";
import {SettingsService} from "./settings.service";
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MVGStateService {
    constructor(
        private _settings: SettingsService) {
    }

    public publicTransportActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public bikeActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public escooterActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public carsharingActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public chargingStationsActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public mobilityStationActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public lamppostActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public taxiActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public mvgComponentChanged: Subject<boolean> = new Subject();
    private resetUrl: string;

    public setBikeActive(active: boolean, setSettings: boolean = true) {
        this.bikeActive$.next(active);
        this.mvgComponentChanged.next(true);
        if (setSettings) {
            this._settings.setBikeActive(active);
        }
    }

    public toggleBikeActive(setSettings: boolean = true) {
        let newActive = !this.bikeActive$.getValue();
        this.setBikeActive(newActive);
        if (setSettings) {
            this._settings.setBikeActive(newActive);
        }
    }

    public toggleEScooterActive(setSettings: boolean = true) {
        let newActive = !this.escooterActive$.getValue();
        this.setEScooterActive(newActive);
    }

    public setEScooterActive(active: boolean, setSettings: boolean = true) {
        this.escooterActive$.next(active);
        this.mvgComponentChanged.next(true);
        if (setSettings) {
            this._settings.setEScooterActive(active);
        }
    }

    public setCarsharingActive(active: boolean, setSettings: boolean = true) {
        this.carsharingActive$.next(active);
        this.mvgComponentChanged.next(true);
        if (setSettings) {
            this._settings.setCarActive(active);
        }
    }

    public toggleCarActive(setSettings: boolean = true) {
        let newActive = !this.carsharingActive$.getValue();
        this.setCarsharingActive(newActive);
        if (setSettings) {
            this._settings.setCarActive(newActive);
        }
    }

    public setChargingStationActive(active: boolean, setSettings: boolean = true) {
        this.chargingStationsActive$.next(active);
        this.mvgComponentChanged.next(true);
        if (setSettings) {
            this._settings.setChargingStationsActive(active);
        }
    }

    public toggleChargingStationActive(setSettings: boolean = true) {
        let newActive = !this.chargingStationsActive$.getValue();
        this.setChargingStationActive(newActive);
        if (setSettings) {
            this._settings.setChargingStationsActive(newActive);
        }
    }

    public setPublicTransportActive(active: boolean, setSettings: boolean = true) {
        this.publicTransportActive$.next(active);
        this.mvgComponentChanged.next(true);
        if (setSettings) {
            this._settings.setPublicTransportActive(active);
        }
    }

    public togglePublicTransportActive(setSettings: boolean = true) {
        let newActive = !this.publicTransportActive$.getValue();
        this.setPublicTransportActive(newActive);
        if (setSettings) {
            this._settings.setPublicTransportActive(newActive);
        }
    }

    public setMobilityStationActive(active: boolean) {
        this.mobilityStationActive$.next(active);
        this.mvgComponentChanged.next(true);
    }

    public toggleMobilityStationActive() {
        let newActive = !this.mobilityStationActive$.getValue();
        this.setMobilityStationActive(newActive);
    }

    public setLamppostActive(active: boolean) {
        this.lamppostActive$.next(active);
        this.mvgComponentChanged.next(true);
    }

    public toggleLamppostActive() {
        let newActive = !this.lamppostActive$.getValue();
        this.setLamppostActive(newActive);
    }

    public setTaxiActive(active: boolean) {
        this.taxiActive$.next(active);
    }

    public toggleTaxisActive() {
        let newActive = !this.taxiActive$.getValue();
        this.taxiActive$.next(newActive);
    }

    public reset() {
        this.setPublicTransportActive(this.resetUrl === 'mobility');
        this.setBikeActive(false);
        this.setCarsharingActive(false);
        this.setChargingStationActive(false);
        this.setMobilityStationActive(false);
        this.setMobilityStationActive(false);
        this.setTaxiActive(false);
    }

    public setResetFunction(url: string) {
        this.resetUrl = url;
    }
}
