import { NgModule } from '@angular/core';

import { TypeaheadModule } from './typeahead/typeahead.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { ComponentsHelper } from './utils/components-helper.service';

@NgModule({
  exports: [
    TypeaheadModule, DropdownModule
  ],
  providers: [
    {provide: ComponentsHelper, useClass: ComponentsHelper}
  ]
})
export class Ng2BootstrapModule {
}
