import {Injectable} from "@angular/core";
import {MapStateService} from "./map-state.service";
import {Observable, Subject} from "rxjs";
import {PlacesEntry} from "../classes/PlacesEntry";
import { CategorizedPlacesWithClusters, EchtzeitproxyService, PlacesWithClusters } from "../apis/echtzeitproxy";
import {AppStateService} from "./app-state.service";
import { LngLatBounds } from 'mapbox-gl';

interface ListenerComponent {
    askedCategories: string[];
    observer: Observable<PlacesWithClusters>;
    subject: Subject<PlacesWithClusters>;
}

@Injectable()
export class EpBoundingboxLoaderService {
    private listeners: { [id: string]: ListenerComponent } = {};
    private zoom: number = null;
    private bounds: LngLatBounds = null;

    private isLoading: boolean = false;
    private delayedLoadAttempt: boolean = false;

    private loadedData: CategorizedPlacesWithClusters = null;
    private loadedDataKey: string = null;

    constructor(private _globalActions: MapStateService, private _ep: EchtzeitproxyService, private _app: AppStateService) {
        this._globalActions.throttledMapChanges$.subscribe((data) => {
            this.zoom = data.zoom;
            this.bounds = data.bounds;
            this.reload();
        });
        this._app.reloadPlacesEvent$.subscribe(() => {
            this.reload();
        });
    };

    private reload() {
        if (this.zoom === null || this.bounds === null) {
            return;
        }

        if (this.isLoading) {
            this.delayedLoadAttempt = true;
            return;
        } else {
            this.delayedLoadAttempt = false;
            this.isLoading = true;
        }

        let categories: string[] = [];
        Object.keys(this.listeners).forEach((id) => {
            this.listeners[id].askedCategories.forEach((cat) => {
                if (categories.indexOf(cat) === -1) {
                    categories.push(cat);
                }
            });
        });

        const sendData = (data: CategorizedPlacesWithClusters) => {
            this.sendReceivedData(data);
            this.loadedDataKey = dataKey;

            this.isLoading = false;
            if (this.delayedLoadAttempt) {
                this.reload();
            }
        };

        const dataKey = this.bounds.getNorth() + "-" + this.bounds.getSouth() + "-" +
            this.bounds.getWest() + "-" + this.bounds.getEast() + "-" + this.zoom + "-" +
            categories.join("-");
        if (this.loadedDataKey === dataKey) {
            sendData(this.loadedData);
        } else {
            this._ep.getCategoriesPlacesForBoundingBox(categories, this.bounds, this.zoom).subscribe(data => {
                this.loadedDataKey = dataKey;
                this.loadedData = data;
                sendData(data);
            }, (error) => {
                console.log(error);
                this.isLoading = false;
            });
        }
    }

    private sendReceivedData(data: CategorizedPlacesWithClusters) {
        Object.keys(this.listeners).forEach((id) => {
            let sendPlaces: {[placeId: string]: PlacesEntry} = {};
            const placesWithClusters: PlacesWithClusters = {
                places: [],
                clusters: [],
            };

            this.listeners[id].askedCategories.forEach((cat) => {
                if (data.places[cat] !== undefined) {
                    data.places[cat].forEach(place => sendPlaces[place.id] = place);
                } else {
                    console.log("Did not receive response for: " + cat);
                }
                if (data.clusters[cat] !== undefined) {
                    data.clusters[cat].forEach(cluster =>placesWithClusters.clusters.push(cluster));
                }
            });
            Object.keys(sendPlaces).forEach(key => {
                placesWithClusters.places.push(sendPlaces[key]);
            });
            this.listeners[id].subject.next(placesWithClusters);
        });
    }

    public setListenerCategories(id: string, categories: string[]): Observable<PlacesWithClusters> {
        if (this.listeners[id] === undefined) {
            this.listeners[id] = {
                askedCategories: null,
                subject: new Subject(),
                observer: null
            };
            this.listeners[id].observer = this.listeners[id].subject.asObservable();
        }
        this.listeners[id].askedCategories = categories;

        this.reload();

        return this.listeners[id].observer;
    }
}
