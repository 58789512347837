import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {PlacesEntry} from "../classes/PlacesEntry";
import {OpeningHours, PlacesDetails} from "../classes/PlacesDetails";
import {GeoFunctions} from "../classes/GeoFunctions";
import {A} from "../services/Auth";
import {sha3} from "../services/sha256";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MVGStationDeparture, MVV_TYPE, PlaceInlineCategory} from "../interfaces";
import {PlacesDetailsBike} from "../classes/PlacesDetailsBike";
import {MobilityStationDetails} from "../classes/MobilityStationDetails";
import {PlacesDetailsCar} from "../classes/PlacesDetailsCar";
import {PlacesDetailsErdgas} from "../classes/PlacesDetailsErdgas";
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { PlacesDetailsEScooter } from '../classes/PlacesDetailsEScooter';

let getAppId = function (): string {
    return 'mwlan-lp';
};

const HOST = 'https://ep.muenchenapis.de';

class ClusteredPlacesPos {
    lat: number;
    lng: number;
}
export class ClusteredPlacesBounds {
    northwest: ClusteredPlacesPos;
    southeast: ClusteredPlacesPos;
}
export class ClusteredPlaces {
    places: number;
    bounds: ClusteredPlacesBounds;
}
export class PlacesWithClusters {
    places: PlacesEntry[];
    clusters: ClusteredPlaces[];
}
export class CategorizedPlacesWithClusters {
    places: { [categoryId: string]: PlacesEntry[] };
    clusters: { [categoryId: string]: ClusteredPlaces[] };
}

@Injectable()
export class EchtzeitproxyService {
    private ak;

    constructor(private _http: HttpClient) {
        this.ak = sha3(PlacesDetails.K1() + A.p2() + PlacesEntry.k3() + GeoFunctions.m9());
    }

    private setBoundingBoxOptParams(params: HttpParams): HttpParams {
        let zoomParams = window.location.hash.match(/zoom\-(\d+)\-places\-(\d+)/);
        if (zoomParams) {
            return params.set('zoomLevelDistributorTileplaces', zoomParams[2])
                .set('zoomLevelDistributorTilediff', zoomParams[1]);
        } else {
            return params;
        }
    }

    public getCategoriesPlacesForBoundingBox(categories: string[], box: LngLatBounds, zoomLevel: number): Observable<CategorizedPlacesWithClusters> {
        let extendedBox = GeoFunctions.extendBoundingboxLngLat(box, 0.3),
            endpoint: string = '/api/v3/places/boundingbox.json';

        zoomLevel = Math.round(zoomLevel);

        let lat1: number = extendedBox.getNorth(),
            lng1: number = extendedBox.getWest(),
            lat2: number = extendedBox.getSouth(),
            lng2: number = extendedBox.getEast();

        let params = new HttpParams()
            .set('categories', categories.join(','))
            .set('minLat', (lat1 < lat2 ? lat1 : lat2).toString())
            .set('maxLat', (lat1 > lat2 ? lat1 : lat2).toString())
            .set('minLng', (lng1 < lng2 ? lng1 : lng2).toString())
            .set('maxLng', (lng1 > lng2 ? lng1 : lng2).toString())
            .set('limit', '100')
            .set('includeImages', 'listPictures750,listPictures178')
            .set('includeClustering', 'true')
            .set('zoomLevel', zoomLevel.toString())
            .set('appId', getAppId());
        params = this.setBoundingBoxOptParams(params);

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map((body) => {
                let places: { [categoryId: string]: PlacesEntry[] } = {};
                categories.forEach((category) => {
                    places[category] = EchtzeitproxyService.extractData(body['results'][category]);
                });
                return {
                    places: places,
                    clusters: body['clusters'],
                };
            }));
    }

    public getCategoryPlacesForBoundingBox(category: string, box: LngLatBounds, zoomLevel: number): Observable<PlacesEntry[]> {
        let extendedBox = GeoFunctions.extendBoundingboxLngLat(box, 0.3),
            endpoint: string = '/api/v3/categories/' + category + '/places/boundingbox.json';

        zoomLevel = Math.round(zoomLevel);

        let lat1: number = extendedBox.getNorth(),
            lng1: number = extendedBox.getWest(),
            lat2: number = extendedBox.getSouth(),
            lng2: number = extendedBox.getEast();

        let params = new HttpParams()
            .set('minLat', (lat1 < lat2 ? lat1 : lat2).toString())
            .set('maxLat', (lat1 > lat2 ? lat1 : lat2).toString())
            .set('minLng', (lng1 < lng2 ? lng1 : lng2).toString())
            .set('maxLng', (lng1 > lng2 ? lng1 : lng2).toString())
            .set('includeImages', 'listPictures750,listPictures178')
            .set('zoomLevel', zoomLevel.toString())
            .set('includeClustering', 'true')
            .set('appId', getAppId());
        params = this.setBoundingBoxOptParams(params);

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map(body => EchtzeitproxyService.extractData(body['results'])));
    }

    public getNumSightsForBoundingBox(box: LngLatBounds, limit: number, language: string): Observable<PlacesEntry[]> {
        let endpoint = '/api/v3/categories/bb.27560/places/boundingbox.json';
        let params = new HttpParams()
            .set('minLat', box.getSouth().toString())
            .set('language', language)
            .set('maxLat', box.getNorth().toString())
            .set('minLng', box.getWest().toString())
            .set('maxLng', box.getEast().toString())
            .set('includeImages', 'listPictures750,listPictures178')
            .set('limit', limit.toString())
            .set('sort', 'relevance')
            .set('appId', getAppId());

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map(body => EchtzeitproxyService.extractData(body['results'])));
    }

    public getClosestPlace(category: string, lat: number, lng: number, language: string): Observable<PlacesEntry> {
        let endpoint = '/api/v3/categories/' + category + '/places/knn.json';
        let params = new HttpParams()
            .set('language', language)
            .set('lat', lat.toString(10))
            .set('lng', lng.toString(10))
            .set('includeImages', 'listPictures750,listPictures178')
            .set('limit', "1")
            .set('appId', getAppId());

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map(body => {
                if (body['results'].length > 0) {
                    return EchtzeitproxyService.extractData(body['results'])[0];
                } else {
                    return null;
                }
            }));
    }

    public static setHardcodedImage(categories: any[], images: any) {
        categories.forEach((cat) => {
            if (cat['id'] === 'mv.car.drivenow') {
                images = {'listPictures178': [{'url': './images/carsharing_drivenow.png'}]};
            }
            if (cat['id'] === 'mv.car.sharenow') {
                images = {'listPictures178': [{'url': './images/carsharing_sharenow.png'}]};
            }
            if (cat['id'] === 'mv.car.car2go') {
                images = {'listPictures178': [{'url': './images/carsharing_car2go.png'}]};
            }
            if (cat['id'] === 'mv.car.stattauto') {
                images = {'listPictures178': [{'url': './images/carsharing_statt_auto.png'}]};
            }
            if (cat['id'] === 'mv.car.stattautoflexy') {
                images = {'listPictures178': [{'url': './images/carsharing_statt_auto_flexy.png'}]};
            }
            if (cat['id'] === 'mv.car.beezero') {
                images = {'listPictures178': [{'url': './images/carsharing_beezero.png'}]};
            }
            if (cat['id'] === 'mv.bike.station.available') {
                images = {'listPictures178': [{'url': './images/pt_mvg_rad_freefloater.png'}]};
            }
            if (cat['id'] === 'mv.bike.station.notavailable') {
                images = {'listPictures178': [{'url': './images/pt_mvg_rad_freefloater.png'}]};
            }
            if (cat['id'] === 'mv.etrike.station.available') {
                images = {'listPictures178': [{'url': './images/mvg_etrike.svg'}]};
            }
            if (cat['id'] === 'mv.etrike.station.notavailable') {
                images = {'listPictures178': [{'url': './images/mvg_etrike_empty.svg'}]};
            }
            if (cat['id'] === 'mv.pedelec.station') {
                images = {'listPictures178': [{'url': './images/mvg_pedelec.svg'}]};
            }
            if (cat['id'] === 'mv.bike.free') {
                images = {'listPictures178': [{'url': './images/pt_mvg_rad_freefloater.png'}]};
            }
        });
        return images;
    }

    private static extractData(placesIn: object[]): PlacesEntry[] {
        let results: PlacesEntry[] = [];

        for (let idx in placesIn) {
            let place = placesIn[idx];

            let address = place['street'];
            if (place['streetNumber']) {
                address += " " + place['streetNumber'];
            }

            let images = EchtzeitproxyService.setHardcodedImage(place['categories'], place['images']);

            const placeObj = new PlacesEntry(
                new LngLat(place['location'].lng, place['location'].lat),
                (place['urlInPortal'] ? place['urlInPortal'].replace('hidenav=1&','') : null),
                place['id'],
                place['title'],
                place['showLabel'],
                (place['categoryIconBaseUrl'] ? place['categoryIconBaseUrl'] : null),
                images,
                place['city'],
                address,
                place['categories'],
                (place['mobilityStation'] ? place['mobilityStation'] : null)
            );

            if (place['isClusteredTile']) {
                placeObj.setIsClustered(true);
            }

            results.push(placeObj);
        }

        return results;
    }


    public loadDetails(id: string, language: string):
        Observable<PlacesDetails | PlacesDetailsBike | PlacesDetailsCar | MobilityStationDetails | PlacesDetailsErdgas> {
        let endpoint = '/api/v3/places/' + id + '.json';

        let params = new HttpParams()
            .set('language', language)
            .set('includeOpeningHours', 'true')
            .set('appId', getAppId());

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map((data) => EchtzeitproxyService.extractDetails(data, language)));
    }

    private static extractDetails(data, language: string): PlacesDetails {
        let body = data['result'];

        let names: { [language: string]: string } = {},
            descriptions: { [language: string]: string } = {},
            city: { [language: string]: string } = {};

        if (typeof(body.city) == "string") {
            city[language] = body.city;
        } else if (body.city) {
            city = body.city;
        }
        if (typeof(body.title) == "string") {
            names[language] = body.title;
        } else if (body.title) {
            names = body.title;
        }
        if (typeof(body.description) == "string") {
            descriptions[language] = body.description;
        } else if (body.description) {
            descriptions = body.description;
        }

        let openingHours: OpeningHours[] = [];
        if (body.openingHours) {
            body.openingHours.forEach((obj) => {
                for (let i = 0; i < 10; i++) {
                    if (obj['von' + i] !== undefined && obj['status' + i] == 'offen') {
                        let open: OpeningHours = {
                            day: obj['day'],
                            from: obj['von' + i],
                            to: obj['bis' + i],
                            status: obj['status' + i]
                        };
                        openingHours.push(open);
                    }
                }
            });
        }

        const isBike = body['categories'].find(cat => ['mv.bike', 'mv.etrike', 'mv.pedelec'].indexOf(cat.id) !== -1),
            isEScooter = body['categories'].find(cat => cat.id === 'mv.escooter'),
            isCar = body['categories'].find(cat => cat.id === 'mv.car'),
            isErdgas = body['categories'].find(cat => cat.id === 'gg.erdgas'),
            isMobilityStation = body['categories'].find(cat => cat.id === 'mv.mobility');

        let constr;
        if (isBike) {
            constr = PlacesDetailsBike;
        } else if (isEScooter) {
            constr = PlacesDetailsEScooter;
        } else if (isCar) {
            constr = PlacesDetailsCar;
        } else if (isMobilityStation) {
            constr = MobilityStationDetails;
        } else if (isErdgas) {
            constr = PlacesDetailsErdgas;
        } else {
            constr = PlacesDetails;
        }

        let images = EchtzeitproxyService.setHardcodedImage(body['categories'], body['images']);
        let obj = new (constr.bind(null, new LngLat(body.location.lng, body.location.lat),
            (body['urlInPortal'] ? body['urlInPortal'].replace('hidenav=1&','') : null),
            body.id,
            names,
            (body['categoryIconBaseUrl'] ? body['categoryIconBaseUrl'] : null),
            images,
            city,
            body['streetName'],
            descriptions,
            openingHours,
            body['categories'],
            body['payload']));

        if (isMobilityStation) {
            let entries: PlacesDetails[] = [];
            body['mobilityStationEntries'].forEach((mobilityEntry) => {
                mobilityEntry['openingHours'] = [];
                entries.push(EchtzeitproxyService.extractDetails({'result': mobilityEntry}, language));
            });
            obj.mobilityEntries = entries;
        }
        if (isErdgas) {
            obj.street = body['street'];
            obj.streetNumber = body['streetNumber'];
            obj.title = body['title'];
        }

        return obj;
    }

    public static categories2mvgtype(cats: PlaceInlineCategory[]): MVV_TYPE[] {
        let types: MVV_TYPE[] = [];
        cats.forEach((cat) => {
            switch (cat.id) {
                case 'mv.public.sbahn':
                    types.push(MVV_TYPE.SBAHN);
                    break;
                case 'mv.public.ubahn':
                    types.push(MVV_TYPE.UBAHN);
                    break;
                case 'mv.public.tram':
                    types.push(MVV_TYPE.TRAM);
                    break;
                case 'mv.public.bus':
                    types.push(MVV_TYPE.BUS);
                    break;
                case 'mv.public.train':
                    types.push(MVV_TYPE.BAHN);
                    break;
                case 'mv.mobility':
                    types.push(MVV_TYPE.MOBILITY_STATION);
                    break;
            }
        });
        return types;
    }

    public static S_BAHN_ICONS = {
        '1': 'images/Linie_S1.svg',
        '2': 'images/Linie_S2.svg',
        '3': 'images/Linie_S3.svg',
        '4': 'images/Linie_S4.svg',
        '6': 'images/Linie_S6.svg',
        '7': 'images/Linie_S7.svg',
        '8': 'images/Linie_S8.svg',
        '20': 'images/Linie_S20.svg',
    };
    public static U_BAHN_ICONS = {
        '1': 'images/Linie_U1.svg',
        '2': 'images/Linie_U2.svg',
        '3': 'images/Linie_U3.svg',
        '4': 'images/Linie_U4.svg',
        '5': 'images/Linie_U5.svg',
        '6': 'images/Linie_U6.svg',
        '7': 'images/Linie_U7.svg',
        '8': 'images/Linie_U8.svg',
    };

    public loadStationDepartures(id: string, limit: number): Observable<MVGStationDeparture[]> {
        let endpoint = '/api/v3/mvg/departures/' + id + '.json';

        let params = new HttpParams().set('appId', getAppId());

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map(data => {
                return data['result'].slice(0, limit).map(res => {
                    let type: MVV_TYPE = null,
                        explicitIcon = null;
                    if (res['product'] == 's') {
                        type = MVV_TYPE.SBAHN;
                        if (EchtzeitproxyService.S_BAHN_ICONS[res['label']]) {
                            explicitIcon = EchtzeitproxyService.S_BAHN_ICONS[res['label']];
                        }
                    }
                    if (res['product'] == 'u') {
                        type = MVV_TYPE.UBAHN;
                        if (res['label'] == '') {
                            res['label'] = 'U';
                        }
                        if (EchtzeitproxyService.U_BAHN_ICONS[res['label']]) {
                            explicitIcon = EchtzeitproxyService.U_BAHN_ICONS[res['label']];
                        }
                    }
                    if (res['product'] == 't') {
                        type = MVV_TYPE.TRAM;
                    }
                    if (res['product'] == 'b') {
                        type = MVV_TYPE.BUS;
                    }
                    return {
                        time: new Date(res['departureTime']),
                        type: type,
                        name: res['label'],
                        destination: res['destination'],
                        lineBackgroundColor: res['lineBackgroundColor'],
                        explicitIcon: explicitIcon
                    };
                });
            }));
    }


    public loadStattautoCars(id: string): Observable<any[]> {
        let endpoint = '/api/v3/mvg/stattauto-cars/' + id + '.json';

        let params = new HttpParams().set('appId', getAppId());

        return this._http
            .get(HOST + endpoint, A.getOpts('GET', endpoint, params, this.ak))
            .pipe(map(data => {
                return data['result'];
            }));
    };
}
