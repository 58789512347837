<div class="search-bar" *ngIf="!hidden">
    <div class="toggle-sidebar-holder z-index-search-bar">
        <button class="btn btn-lg btn-default" type="button" (click)="toggleSidebar()">
            <img src="images/Icon-Menu.svg" alt="Sidebar" class="icon" width="25">
        </button>
    </div>

    <div class="goto-current-pos-holder z-index-search-bar">
        <button class="btn btn-lg btn-default" type="button" (click)="onGotoCurrentPosition()">
            <img src="images/Icon-Fadenkreuz.svg" alt="Mein Standort" class="icon">
        </button>
    </div>

    <div class="search-holder" [class.active]="searchActive">
        <div class="input-group input-group-lg">
            <input [(ngModel)]="asyncSelected"
                   [typeahead]="dataSource"
                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   [typeaheadOptionsLimit]="10"
                   [typeaheadOptionField]="'name'"
                   [typeaheadMinLength]="0"
                   [typeaheadPlacement]="typeaheadPlacement"
                   [placeholder]="messages.placeholder"
                   class="form-control">
                <button class="typeahead-clear-button" (click)="clearInput()"
                        *ngIf="typeaheadLoading===false && asyncSelected !== ''">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
        </div>

        <div *ngIf="typeaheadLoading===true" class="typeahead-loading-indicator z-index-search-bar">
            <i class="glyphicon glyphicon-refresh ng-hide" style=""></i>
        </div>
        <div *ngIf="typeaheadNoResults===true" class="typeahead-nothing-found z-index-search-bar" style="">
            <i class="glyphicon glyphicon-remove"></i> {{ messages.none }}
        </div>
    </div>

    <div class="toggle-search-holder">
        <button class="btn btn-lg btn-default" type="button" (click)="toggleSearch()">
            <i class="glyphicon glyphicon-search"></i>
        </button>
    </div>
</div>
