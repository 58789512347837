import {IShortInfoComponentContent} from "./IShortInfoComponentContent";
import {IRoutable} from "./IRoutable";
import {IMapObject} from "./IMapObject";
import {District, PlaceImage, PlaceInlineCategory} from "../interfaces";
import {GeoFunctions} from "./GeoFunctions";
import { LngLat } from 'mapbox-gl';

export class PlacesEntry implements IShortInfoComponentContent, IRoutable, IMapObject {
    public district: District = null;
    public type: string;
    public payload: any = null;
    public isClusteredPlace = false;

    constructor(public position: LngLat,
                public mhpLink: string,
                public id: string,
                public name: string,
                public showLabel: boolean,
                public iconUrlBase: string,
                public images: { [imageType: string]: PlaceImage[] },
                public city?: string,
                public street?: string,
                public categories?: PlaceInlineCategory[],
                public mobilityStationId?: string) {

        this.setPlaceType();
    }

    public setPayload(payload) {
        this.payload = payload;
    }

    public setIsClustered(clustered: boolean) {
        this.isClusteredPlace = clustered;
    }

    private setPlaceType() {
        this.type = 'place';
        for (let idx in this.categories) {
            if (this.categories[idx]['id'] === 'mv.public') {
                this.type = 'mvg_station';
            }
            if (this.categories[idx]['id'] === 'mv.bike') {
                this.type = 'bike';
            }
            if (this.categories[idx]['id'] === 'mv.pedelec') {
                this.type = 'bike';
            }
            if (this.categories[idx]['id'] === 'mv.etrike') {
                this.type = 'bike';
            }
            if (this.categories[idx]['id'] === 'mv.escooter') {
                this.type = 'escooter';
            }
            if (this.categories[idx]['id'] === 'mv.car') {
                this.type = 'car';
            }
            if (this.categories[idx]['id'] === 'mv.charging') {
                this.type = 'charging_station';
            }
            if (this.categories[idx]['id'] === 'gg.erdgas') {
                this.type = 'erdgas';
            }
            if (this.categories[idx]['id'] === 'mv.taxi') {
                this.type = 'taxi';
            }
            if (this.categories[idx]['id'] === 'mv.mobility') {
                this.type = 'mobility_station';
            }
        }
    }

    /**
     * @param {PlacesEntry} obj - ein noch nicht wirklich initialisiertes Objekt, das aus JSON.parse(JSON.stringify(richtigesObjekt)) aus "richtigesObjekt" hervorgegangen ist
     * @returns {PlacesEntry}
     */
    public static unserialize(obj: PlacesEntry): PlacesEntry {
        return new PlacesEntry(
            new LngLat(obj.position.lng, obj.position.lat),
            obj.mhpLink,
            obj.id,
            obj.name,
            (obj.showLabel ? obj.showLabel : false),
            (obj.iconUrlBase ? obj.iconUrlBase : null),
            obj.images,
            obj.city,
            obj.street,
            obj.categories
        );
    }

    public getClassType(): string {
        return this.type;
    }

    public getId(): string {
        return this.id;
    }

    public getShortInfoImage(): string {
        if (this.images['listPictures178'] && this.images['listPictures178'].length > 0) {
            return this.images['listPictures178'][0].url;
        }
        if (this.images['listPictures750'] && this.images['listPictures750'].length > 0) {
            return this.images['listPictures750'][0].url;
        }
        return null;
    }

    public needsFallbackImage(): boolean {
        let isTopSight = false;
        this.categories.forEach((cat: PlaceInlineCategory) => {
            if (cat.id === 'bb.27560') {
                isTopSight = true;
            }
        });
        return isTopSight;
    }

    public getShortInfoTitle(): string {
        return this.name;
    }

    public getShortInfoAddress(): string {
        return (this.street ? this.street + "<br>" : "") + (this.city ? this.city : "");
    }

    public getPosition(): LngLat {
        return this.position;
    }

    public getRoutingTitle(language: string): string {
        return this.name;
    }

    public getUrlHash(urlSubparts: string[]): string {
        let url = "place/" + this.id;
        if (urlSubparts.length > 0) {
            url += "/" + urlSubparts.join("/");
        }
        return url;
    }

    public getRoutableObject(): IRoutable {
        return this;
    }

    public needsRoutingPlaceholderIcon() {
        return false;
    }

    public getDistrict(): District {
        if (this.district === null) {
            this.district = GeoFunctions.getMunichDistrictForPoint(this.position);
        }
        return this.district;
    }

    // Wird von muenchen-places.service.ts verwendet; ein bisschen Obfuscation
    // 3. Teil des Keys, rot13
    public static k3() {
        return '953sq54n73';
    }

    getMvgRoutingString(): string {
        if (this.getClassType() === 'mvg_station') {
            return this.name;
        }
        if (this.street) {
            if (this.city === "München") {
                return this.street;
            } else {
                return this.street + ", " + this.city;
            }
        } else {
            return this.getPosition().lat + "," + this.getPosition().lng;
        }
    }
}
