<aside id="sidebar">
    <div class="sidebar-section logo">
        <button class="btn-link btn-close" type="button" (click)="closeSidebar()">
            <img src="images/Icon-50px-close.png" alt="Close">
        </button>
        <img src="images/logo_lhm_en.png" alt="Landeshauptstadt München" width="170" *ngIf="language == 'en'">
        <img src="images/logo_lhm.svg" alt="Landeshauptstadt München" width="170" *ngIf="language == 'de'">
    </div>
    <div class="sidebar-section language_chooser">
        <div class="language" (click)="setLanguage('de')" [class.active]="language == 'de'">
            <img src="images/flag-de.svg" alt="DE" width="30">
        </div>
        <div class="language" (click)="setLanguage('en')" [class.active]="language == 'en'">
            <img src="images/flag-uk.svg" alt="EN" width="30">
        </div>
    </div>
    <div class="sidebar-section tiles_chooser">
        <h2>{{ messages.title_tiles }}</h2>
        <ul>
            <li class="tile radio-row" (click)="selectTiles(TILE_TYPES.MAPBOX_MTOURISMUS)" [class.active]="tiles == TILE_TYPES.MAPBOX_MTOURISMUS">
                {{ messages.tile_basis }}
                <span class="active-indicator"></span>
            </li>
            <li class="tile radio-row" (click)="selectTiles(TILE_TYPES.SWM_LUFTBILD)" [class.active]="tiles == TILE_TYPES.SWM_LUFTBILD">
                {{ messages.tile_luftbild }}
                <span class="active-indicator"></span>
            </li>
        </ul>
    </div>
    <section class="sidebar-section actions">
        <a href="" (click)="resetCategories()">{{ messages.reset_categories}}</a>
    </section>
    <section class="sidebar-section links">
        <h2>Links</h2>
        <ul>
            <li><a href="https://www.muenchen.digital/" target="_blank" rel="noopener">München Digital</a></li>
            <li><a href="https://muenchen.travel/" target="_blank" rel="noopener">München Tourismus</a></li>
            <li><a href="https://www.muenchen.de/online-services" target="_blank" rel="noopener">München Online Services</a></li>
            <li><a href="https://www.muenchen.de/rathaus/Kontakt/Impressum.html" target="_blank" rel="noopener">{{ messages.impressum }}</a></li>
            <li><a href="./static/datenschutzhinweis.html" target="_blank" rel="noopener">{{ messages.datenschutz }}</a></li>
        </ul>
    </section>
</aside>
<div class="sidebar_click_catcher" (click)="closeSidebar()" (swiperight)="closeSidebar()"></div>
