import {Injectable} from "@angular/core";
import {auditTime, distinctUntilChanged} from "rxjs/operators";
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {SettingsService} from "./settings.service";
import {AppStateService} from "./app-state.service";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MyLocation} from "../classes/MyLocation";
import { DEFAULT_ORIENTATION, DEFAULT_PITCH, DEFAULT_TILES, TILES } from "../interfaces";
import { LngLat, LngLatBounds } from 'mapbox-gl';

@Injectable()
export class MapStateService {
    constructor(private _settings: SettingsService, private _appState: AppStateService) {
        this._appState.shortInfoComponentActive$.subscribe(this.onShortInfoComponent.bind(this));
        this._appState.tourismMode$.subscribe(this.onTourismChanged.bind(this));
    }

    public gotoMyPos$: Subject<any> = new Subject();
    public gotoBoundsLngLat$: Subject<LngLatBounds> = new Subject();
    public tiles$: BehaviorSubject<number> = new BehaviorSubject<number>(DEFAULT_TILES);

    public myLocationLngLat$: BehaviorSubject<LngLat> = new BehaviorSubject(null);
    public myLocationAccuracy$: BehaviorSubject<number> = new BehaviorSubject<number>(10);

    public boundingboxLngLat$: BehaviorSubject<LngLatBounds> = new BehaviorSubject<LngLatBounds>(null);

    // Triggers actually moving the map
    public setCenterZoomLngLat$: Subject<{ position: LngLat, zoom: number }> = new Subject();

    // Triggered by moving the map - stores current center (or activated place)
    public centerLngLat$: BehaviorSubject<LngLat> = new BehaviorSubject<LngLat>(new LngLat(11.576006,48.137079));
    // Triggered by moving the map - storres current zoom level
    public zoom$: BehaviorSubject<number> = new BehaviorSubject<number>(16);

    public orientation$: BehaviorSubject<number> = new BehaviorSubject(DEFAULT_ORIENTATION);
    public pitch$: BehaviorSubject<number> = new BehaviorSubject(DEFAULT_PITCH);
    public resetOrientation$: Subject<boolean> = new Subject();


    private _mapChanges: Subject<{ bounds: LngLatBounds, zoom: number }> = new Subject();
    public throttledMapChanges$: Observable<{ bounds: LngLatBounds, zoom: number }> =
        //this._mapChanges.asObservable().distinctUntilChanged().throttleTime(1000);
        //this._mapChanges.asObservable().distinctUntilChanged().auditTime(300);
        this._mapChanges.asObservable()
            .pipe(distinctUntilChanged())
            .pipe(auditTime(300));


    // noinspection JSUnusedGlobalSymbols
    public triggerSearchMyPos() {
        this.gotoMyPos$.next({});
    }

    public triggerMapChange(bound: LngLatBounds, zoom: number) {
        this._mapChanges.next({bounds: bound, zoom: zoom});
    }

    public triggerGotoBounds(bounds: LngLatBounds) {
        this.gotoBoundsLngLat$.next(bounds);
    }

    public triggerSetCenterZoom(pos: LngLat, zoom: number) {
        let changeZoom = true,
            changePos = true;
        if (zoom === null) {
            zoom = this.zoom$.getValue();
            changeZoom = false;
        }
        if (pos === null) {
            pos = this.centerLngLat$.getValue();
            changePos = true;
        }

        this.setCenterZoomLngLat$.next({position: pos, zoom: zoom});
        if (changeZoom) {
            this.zoom$.next(zoom);
        }
        if (changePos) {
            this.centerLngLat$.next(pos);
        }
    }

    public triggerSetCenterZoomLngLat(pos: LngLat, zoom: number) {
        this.setCenterZoomLngLat$.next({position: pos, zoom: zoom});
    }

    public setTiles(tiles: number, setSettings: boolean) {
        this.tiles$.next(tiles);
        this._appState.closePrettyMuchEverything();
        if (setSettings) {
            this._settings.setTileset(tiles);
        }
    }

    public updateMyLocation(location: LngLat, accuracy: number) {
        this.myLocationLngLat$.next(location);
        this.myLocationAccuracy$.next(accuracy);
        new MyLocation(location, false);
    }

    mapMoved(center: LngLat, boundingbox: LngLatBounds, zoomlevel: number) {
        if (center !== this.centerLngLat$.getValue()) {
            this.centerLngLat$.next(center);
        }
        if (zoomlevel !== this.zoom$.getValue()) {
            this.zoom$.next(zoomlevel);
        }
        if (boundingbox !== this.boundingboxLngLat$.getValue()) {
            this.boundingboxLngLat$.next(boundingbox);
        }
    }

    private onShortInfoComponent(info: IShortInfoComponentContent) {
        if (info) {
            this.triggerSetCenterZoom(info.getPosition(), null);
        }
    }

    private onTourismChanged(tourismActive: boolean) {
        /*
        if (this.tiles$.getValue() !== TILES.SWM_LUFTBILD) {
            if (tourismActive) {
                this.tiles$.next(TILES.MAPBOX_MTOURISMUS); // Don't trigger closeEverything
            } else {
                this.tiles$.next(TILES.MAPBOX_SMARTCITY); // Don't trigger closeEverything
            }
        }
        */
    }
}
