import {Injectable} from "@angular/core";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {PlacesEntry} from "../classes/PlacesEntry";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {RoutingMode} from "../interfaces";
import { Map, Marker } from "mapbox-gl";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";
import {MapStateService} from "../services/map-state.service";

const CUSTOMERS_ABOVE_ZOOM = 19;
const NO_PLACES_BELOW_ZOOM = 13;

@Injectable()
export class MapPlacesLayerService {
    private map: Map;
    //private Z_INDEX_OFFSET: 0;
    private categoriesSelected: string[];
    private markers: { [id: string]: Marker } = {};
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private zoom: number = null;
    private language: string = 'de';
    private currentPlaces: PlacesEntry[] = [];

    constructor(private _mapState: MapStateService,
                private _places: EchtzeitproxyService,
                private _epBBLoader: EpBoundingboxLoaderService,
                private _appState: AppStateService,
                private _routingService: RoutingService) {
    }

    public init(map: Map) {
        this.map = map;

        this._appState.language$.subscribe((language) => {
            this.language = language.language;
        });

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addPlaceAsInactiveRichMarker.bind(this),
            this.delPlaceMarker.bind(this),
            this.addPlaceAsActiveRichMarker.bind(this),
            this.delPlaceMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() == 'place') {
                this.markerCollection.setActiveElement(<PlacesEntry> shortInfoComponentActive);
            } else {
                this.markerCollection.setActiveElement(null);
            }
        });
        this._appState.categoriesSelected$.subscribe((categoriesSelected: string[]) => {
            if (this.categoriesSelected != categoriesSelected) {
                this.categoriesSelected = categoriesSelected;
                this.setCategoryBBListener();
            }
        });
        this._appState.extraCategory$.subscribe(() => {
            this.setCategoryBBListener();
        });
        this._epBBLoader.setListenerCategories('places', this.categoriesSelected).subscribe((data) => {
            this.receivedPlaces(data.places);
        });
        this._routingService.active$.subscribe(this.routingChanged.bind(this));
        this._routingService.from$.subscribe(this.routingChanged.bind(this));
        this._routingService.to$.subscribe(this.routingChanged.bind(this));

        this.initPlaceFlushListener();
    }

    private setCategoryBBListener() {
        const catsCustomers = [];
        const catsSelected = [...this.categoriesSelected];
        if (this._appState.extraCategory$.getValue()) {
            catsSelected.push(this._appState.extraCategory$.getValue());
        }
        if (this.zoom > CUSTOMERS_ABOVE_ZOOM) {
            this._epBBLoader.setListenerCategories('places', [...catsSelected, ...catsCustomers]);
        } else if (this.zoom <= NO_PLACES_BELOW_ZOOM) {
            this._epBBLoader.setListenerCategories('places', []);
        } else {
            this._epBBLoader.setListenerCategories('places', catsSelected);
        }
    }

    routingChanged() {
        this.showCurrentPlaces();
    }

    private delPlaceMarkerById(markerId: string) {
        // Es kann auch sein, dass Marker in addPlaceAsInactiveRichMarker dann doch nicht angezeigt werden, wegen Kollission
        // In diesem Fall gibt es hier dann keinen Eintrag, obwohl es noch in der MarkerCollection registriert ist
        if (this.markers[markerId]) {
            this.markers[markerId].remove();
            delete(this.markers[markerId]);
        }
    }

    private delPlaceMarker(markerData: PlacesEntry) {
        this.delPlaceMarkerById(markerData.id);
    }

    private createMarker(iconUrl: string, classes: string[], markerData: PlacesEntry, onClick: () => any) {
        const el = document.createElement('div');
        classes.forEach(cla => {
            el.classList.add(cla);
        });
        const img = document.createElement('img');
        img.src = iconUrl;
        img.alt = '◉';
        el.appendChild(img);
        const title = document.createElement('div');
        title.classList.add('title');
        title.classList.add('movable');
        title.innerText = markerData.getShortInfoTitle();
        el.appendChild(title);

        el.addEventListener('click', ev => {
            ev.stopPropagation();
            onClick();
        });

        // make a marker for each feature and add to the map
        const marker = new Marker(el);
        marker.setLngLat(markerData.position);
        marker.addTo(this.map);

        return marker;
    }

    private showLabelForMarker(markerData: PlacesEntry): boolean {
        return markerData.categories.filter(cat => cat.id === 'bb.27480').length === 0; // Geldautomaten => false
    }

    private addPlaceAsInactiveRichMarker(markerData: PlacesEntry) {
        let iconUrl: string = 'https://app.muenchen.de/img/marker/category/fallback.svg';
        if (markerData.iconUrlBase) {
            iconUrl = markerData.iconUrlBase + '.svg';
        }

        this.markers[markerData.id] = this.createMarker(iconUrl, ['place-marker', 'pos-right'], markerData, () => {
            this._appState.openPlaceInShortInfo(markerData);
        });
    }

    private addPlaceAsActiveRichMarker(markerData: PlacesEntry) {
        let iconUrl: string = 'https://app.muenchen.de/img/marker/category/fallback-active.svg';
        if (markerData.iconUrlBase) {
            iconUrl = markerData.iconUrlBase + '-active.svg';
        }

        this.markers[markerData.id] = this.createMarker(iconUrl, ['place-marker-active', 'pos-right'], markerData, () => {
            this._appState.openPlaceInShortInfo(markerData);
        });
    }

    private insertActivePlacesIfMissing(places: PlacesEntry[]) {
        let ids: string[] = [];
        places.forEach((place: PlacesEntry) => {
            ids.push(place.getId());
        });

        let active = this._appState.shortInfoComponentActive$.getValue();
        if (active && active.getClassType() == 'place' && ids.indexOf(active.getId()) === -1) {
            places.unshift(<PlacesEntry>active);
            ids.push(active.getId());
        }

        return places;
    }

    private initPlaceFlushListener() {
        this._mapState.zoom$.subscribe((zoom: number) => {
            if (!zoom || zoom === this.zoom) {
                return;
            }
            this.zoom = zoom;
            this.setCategoryBBListener();
            /*
            Vor/Nachteil:
            Hier leeren forciert eine neue Kollissionsvermeidung und sorgt beim Herauszoomen daher für weniger Überlappungen
            Dafür flackert es beim Zoomen etwas mehr.
            this.markerCollection.setNewElementCollection(
                this.insertActivePlacesIfMissing([])
            );
            */
        });
    }

    private showCurrentPlaces() {
        if (this._routingService.active$.getValue() !== RoutingMode.NONE) {
            let toBeShown: PlacesEntry[] = [];
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'place') {
                toBeShown.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'place') {
                toBeShown.push(<PlacesEntry>to);
            }
            this.markerCollection.setNewElementCollection(toBeShown);
        } else {
            this.markerCollection.setNewElementCollection(this.currentPlaces);
        }
    }

    private receivedPlaces(places: PlacesEntry[]) {
        // places = places.sort(RichMarkerPos.sortPlacesToAvoidLabelCollissions);
        this.currentPlaces = this.insertActivePlacesIfMissing(places);
        this._appState.placesReloaded(this.currentPlaces);
        this.showCurrentPlaces();
    }
}
