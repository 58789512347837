<div>
    <input [(ngModel)]="asyncSelected"
           [typeahead]="dataSource"
           (typeaheadLoading)="changeTypeaheadLoading($event)"
           (typeaheadNoResults)="changeTypeaheadNoResults($event)"
           (typeaheadOnSelect)="typeaheadOnSelect($event)"
           [typeaheadOptionsLimit]="10"
           [typeaheadOptionField]="'name'"
           [typeaheadMinLength]="0"
           (focus)="onFocus()"
           (blur)="onBlur()"
           [placeholder]="placeholder"
           class="routing-typeahead-input">
    <button class="typeahead-clear-button" (click)="clearInput()"
            *ngIf="typeaheadLoading===false && asyncSelected !== ''">
        <span class="glyphicon glyphicon-remove"></span>
    </button>
</div>

<div *ngIf="typeaheadLoading===true" class="typeahead-loading-indicator">
    <i class="glyphicon glyphicon-refresh ng-hide" style=""></i>
</div>
<div *ngIf="typeaheadNoResults===true && asyncSelected !== '' && typeaheadLoading===false" class="typeahead-nothing-found">
    <i class="glyphicon glyphicon-remove"></i> Nothing found
</div>