import {Messages} from "./messages";

export const MESSAGES_EN: Messages =
    {
        language: "en",
        search: {
            none: "Nothing found",
            placeholder: "Search on muenchen.de",
        },
        sidebar: {
            title: "Choose tileset",
            layer_mvv: "Public transport",
            layer_rad: "MVG bike-sharing",
            layer_escooter: "E-Scooter",
            layer_tour: "Proposed tour",
            layer_charging_stations: "E-charging stations",
            tile_basis: "Map",
            title_tiles: "Map view",
            tile_hausnummern: "House numbers",
            tile_luftbild: "Satellite",
            impressum: "Imprint",
            datenschutz: "Protection of privacy",
            reset_categories: "Reset map",
            welcome_marker: "Welcome in Munich",
        },
        routing: {
            title: "Calculate route",
            from: "From",
            from_placeholder: "Choose starting point",
            to: "To",
            to_placeholder: "Choose destination",
            public_title: "Public transport",
            departure: "Departure",
            calculating_route: "Calculate the route ...",
            autosuggest: "Suggested",
            show_route: "Show route",
        },
        shortinfo: {
            time_rel_hours: "h",
            time_rel_minutes: "m",
            opening_hours: "Open today",
            bike_free: 'MVG bike',
            bike_station: 'MVG bike station',
            etrike_station: 'MVG eTrike station',
            pedelec_station: 'MVG eBike station',
            bike_no: 'MVG Bike no.',
            bikes_available_pre: 'Still',
            bikes_available: 'MVG bikes available',
            bike_available: 'MVG bike available',
            pedelec_no: 'MVG eBike no.',
            pedelecs_available_pre: 'Still',
            pedelecs_available: 'MVG eBikes available',
            pedelec_available: 'MVG eBike available',
            etrike_no: 'MVG eTrike No.',
            etrikes_available_pre: 'Still',
            etrikes_available: 'MVG eTrikes available',
            etrike_available: 'MVG eTrike available',
            taxis_available_pre: 'Still',
            taxis_available: 'Taxis available',
            taxis_available_none: 'No taxi available',
            taxi_available: 'Taxi available',
            departures_loading: "Loading next departures",
            departures_next: "Next departures",
            departure_in: "In",
            stattauto_loading: "Loading stattautos...",
            mobility_tab_public: "Public",
            mobility_tab_bike: "MVG Bike",
            mobility_tab_charging: "Charging",
            mobility_tab_car: "Car sharing",
            mobility_tab_districtbox: "District box",
            mobility_bike_available_1: "1 bike available",
            mobility_bike_available_x: "bikes available",
            mobility_bike_order: "Order bike",
            mobility_charging_order: "Charge now",
            mobility_car_order: "Order car",
            charging_not_available: "Not available",
        },
        details: {
            back: 'Back',
            loading: 'Loading',
            open_website: 'Website',
            open_mvg: 'Public Transport',
            open_telephone: 'Phone',
            open_photoupload: 'Photo-Upload'
        },
        mwlan: {
            exploreTitle: 'Discover munich',
            topcatTourism: 'Tourism',
            topcatMobility: 'Mobility',
            topcatBranchen: 'Life',
            topcatSmartServices: 'Smart Services',
            catSights: 'Places of interests',
            catRestaurants: 'Restaurants',
            catPublicTransport: 'Public transportation',
            catMuseum: 'Museums',
            catGuides: 'Guided tours',
            catBuildings: 'Buildings and monuments',
            catRundfahrten: 'City tours',
            catChurches: 'Churches and Monasteries',
            catParks: "Parks and gardens",
            catATM: 'ATMs',
            catBikesharing: 'MVG Bike',
            catCarsharing: 'Car sharing',
            catMobilityStation: 'Mobility station',
            catDistrictbox: 'District boxes',
            catCharging: 'E-Charging',
            catLampposts: 'Intelligent Lampposts',
            catShopping: 'Shopping',
            catDoctors: 'Doctors',
            catPharmacy: 'Pharmacies',
            catCafes: 'Cafés',
            catMWlan: 'M-WLAN Hotspots',
            catSensors: 'Sensors',
            catStele: 'Orientation steles',
            catTaxi: 'Taxi',
        }
    };
