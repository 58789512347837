import {Injectable} from "@angular/core";
import {PlacesCategory, PlacesCategoryMultilingual} from "../interfaces";
import {PlacesEntry} from "../classes/PlacesEntry";
import {LastSearchesService, LastSearchEntry} from "../services/last-searches.service";
import { LngLat } from 'mapbox-gl';
import { ElasticsearchService } from '../services/elasticsearch.service';
import {environment} from "../environments/environment";

interface EntriesAndCategories {
    entries: PlacesEntry[],
    categories: PlacesCategory[]
}

@Injectable()
export class ElasticsearchPlacesService {
    private index = 'places-k';

    constructor(private lastSearches: LastSearchesService, private es: ElasticsearchService) {
        if (environment.production) {
            this.index = 'places';
        }
    }

    private static elastic2BranchenbuchEntry(entry: any): PlacesEntry {
        return new PlacesEntry(
            new LngLat(entry._geoloc.lng, entry._geoloc.lat),
            (entry.urlInPortal ? entry.urlInPortal.replace('hidenav=1&','') : null),
            entry.id,
            entry.title['de'],
            true,
            entry.categoryIconBaseUrl,
            entry.images,
            entry.city,
            entry.street + " " + entry.streetNumber,
            entry.categories.map(cat => {
                return {
                    id: cat.id,
                    title: cat.title.de,
                };
            }),
        );
    }

    searchPlacesByQuery(query: string, language: string, center?: LngLat): Promise<EntriesAndCategories> {
        return new Promise<EntriesAndCategories>((resolve, reject) => {

            this.es.fullTextSearchCombined(
                this.index,
                ['title.de^3', 'title.en', 'description.de', 'description.en', 'street', 'city', 'tagline.de'],
                'categories.title.de',
                query,
                20).then(response => {
                let catPool = [];
                let content = response.hits;
                let result: EntriesAndCategories = {
                    entries: [],
                    categories: []
                };

                if (content.hits) {
                    for (let i = 0; i < content.hits.length; i++) {
                        if (i < 10) {
                            result.entries.push(ElasticsearchPlacesService.elastic2BranchenbuchEntry(content.hits[i]._source));
                        }

                        if (content.hits[i]._source.categoriesData) {
                            let facets = content.hits[i]._source.categoriesData;

                            facets.forEach(function (value) {
                                let parts = value.split("---");
                                if (catPool.indexOf(parts[0]) === -1) {
                                    catPool.push(parts[0]);

                                    let name = parts[1];
                                    if (language == 'en') {
                                        name = parts[2];
                                    }
                                    let iconUrl = 'https://app.muenchen.de/img/marker/category/' + parts[3];

                                    result.categories.push({
                                        'type': 'category',
                                        'id': parts[0],
                                        'name': name,
                                        'icon': iconUrl,
                                    });
                                }
                            });
                        }
                    }
                }
                resolve(result);

            }, error => {
                console.error(error);
            });
        });
    }

    searchPlacesByCategory(query: string): Promise<PlacesEntry[]> {
        return new Promise<PlacesEntry[]>((resolve, reject) => {
            this.es.fullTextSearch('places-k','title.de', query).then(response => {
                resolve(response.hits);
            });
        });
    }

    static getDefaultCategories(): PlacesCategoryMultilingual[] {
        return <PlacesCategoryMultilingual[]>window['MAP_CONFIG']['DEFAULT_CATEGORIES'];
    }

    searchPlacesAndCategoriesByQuery(query: string, language: string, center?: LngLat): Promise<{ entries: any[], categories: any[], lastSearches?: any }> {
        return new Promise<any>((resolve, reject) => {
            if (query == '') {
                let cats: PlacesCategoryMultilingual[] = ElasticsearchPlacesService.getDefaultCategories();
                let result = {
                    'entries': [],
                    'categories': cats.map((cat: PlacesCategoryMultilingual) => {
                        return {
                            'type': 'category',
                            'id': cat.id,
                            'icon': cat.icon,
                            'name': (cat.names[language] ? cat.names[language] : cat.names['de']),
                        };
                    }),
                    'lastSearches': this.lastSearches.getEntries().map((entry: LastSearchEntry) => {
                        return {
                            'type': 'last-search',
                            'id': entry.id,
                            'icon': entry.icon,
                            'name': entry.name,
                            'item': entry.item
                        }
                    })
                };
                resolve(result);

            } else {
                this.searchPlacesByQuery(query, language, center).then((results: EntriesAndCategories) => {
                    resolve({
                        'entries': results.entries.map((item: PlacesEntry) => {
                            return {
                                'type': 'entry',
                                'id': item.id,
                                'name': item.name,
                                'item': item
                            }
                        }),
                        'categories': results.categories.map((cat: PlacesCategory) => {
                            return {
                                'type': 'category',
                                'id': cat.id,
                                'icon': cat.icon,
                                'name': cat.name
                            };
                        })
                    });
                });
            }
        });
    }

}
