import {Pipe, PipeTransform} from "@angular/core";
import {Messages} from "../messages/messages";
import {AppStateService} from "../services/app-state.service";

@Pipe({
    name: 'relativetime'
})
export class RelativeTimePipe implements PipeTransform {
    public messages: Messages;
    public language: string;

    constructor(private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages;
            this.language = messages.language;
        });
    }
    transform(time: Date): string {
        let seconds = Math.round((time.getTime() - (new Date()).getTime()) / 1000);
        if (seconds < 60) {
            //return seconds + "s";
            return "-";
        } else if (seconds < 3600) {
            return this.messages.shortinfo.departure_in + " " +
                Math.floor(seconds / 60).toString() +  " " + this.messages.shortinfo.time_rel_minutes;
        } else {
            let h = Math.floor(seconds / 3600);
            return this.messages.shortinfo.departure_in + " " + h.toString() + ":" +
                Math.floor((seconds - (h * 3600)) / 60) + " " + this.messages.shortinfo.time_rel_hours;
        }
    }
}
