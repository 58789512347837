import {Component, Input} from '@angular/core';
import {PlacesDetails} from "../classes/PlacesDetails";
import {Messages, ShortInfoMessages} from "../messages/messages";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'short-info-stattauto',
    templateUrl: 'short-info-stattauto.component.html',
    styles: [],
})
export class ShortInfoStattautoComponent {
    private _station: PlacesDetails = null;
    @Input()
    set station(station: any) {
        this._station = station;
        this.initStattautoStationContent();
    }

    get station() {
        return this._station;
    };

    public stattautoCars: any[] = null;

    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;

    constructor(private _appState: AppStateService, private _ep: EchtzeitproxyService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }


    public initStattautoStationContent() {
        if (this._station) {
            this._ep.loadStattautoCars(this._station.id).subscribe((data) => {
                this.stattautoCars = data;
            });
        } else {
            this.stattautoCars = null;
        }
    }
}
