import {sha256} from "./sha256";
import {gk} from "../classes/PlacesDetails";
import {HttpParams} from "@angular/common/http";

export class A {
    public static getOpts(method: string, url: string, params: HttpParams, sec: string) {
        let complete = url + "?" + params.toString();
        let base = method.toUpperCase() + complete + sec;

        let newParams: HttpParams = params.set(gk(), sha256(base));

        return {
            'params': newParams,
            //'headers': authHeader
        };
    }

    // Wird von muenchen-places.service.ts verwendet; ein bisschen Obfuscation
    // 2. Teil des Keys, rot13
    public static p2(): string {
        return '1q084672071';
    }
}
