import {IRoutable} from "./IRoutable";
import {PlacesEntry} from "./PlacesEntry";
import { GeoFunctions } from './GeoFunctions';

export class SightseeingRoute {
    public value: number;
    public overallDistance: number;
    public lastDistance: number = 0;
    public debugstr = '';

    public constructor(public from: IRoutable, public to: IRoutable, public inbetweenPlaces: PlacesEntry[]) {
        let length: number = this.inbetweenPlaces.length;

        if (length > 0) {
            this.overallDistance = GeoFunctions.getDistance(from.getPosition(), this.inbetweenPlaces[0].getPosition());
        } else {
            this.overallDistance = GeoFunctions.getDistance(from.getPosition(), to.getPosition());
        }

        for (let i = 1; i < length; i++) {
            this.overallDistance += GeoFunctions.getDistance(this.inbetweenPlaces[i - 1].getPosition(), this.inbetweenPlaces[i].getPosition());
        }

        if (length > 0) {
            this.overallDistance += GeoFunctions.getDistance(this.inbetweenPlaces[length - 1].getPosition(), this.to.getPosition());
        }

        let lastItem: IRoutable = (length > 0 ? this.inbetweenPlaces[length - 1] : this.from);
        this.lastDistance = GeoFunctions.getDistance(lastItem.getPosition(), this.to.getPosition());
        this.calcValue();
    }

    private calcValue() {
        let maxDetourForPlacePerKm = 200; // in meters
        let directDistance = GeoFunctions.getDistance(this.from.getPosition(), this.to.getPosition());
        let maxDetourPerPlace = (directDistance / 1000) * maxDetourForPlacePerKm;

        this.value = this.overallDistance - this.inbetweenPlaces.length * maxDetourPerPlace;

        this.debugstr = this.inbetweenPlaces.map((place: PlacesEntry) => { return place.getShortInfoTitle(); }).join(' => ');
        this.debugstr += ' (Länge: ' + this.overallDistance + ' ./. ' + directDistance + ', Umweg / POI: ' + maxDetourPerPlace + ')';
    }

    public addInbetweenPlace(place: PlacesEntry): SightseeingRoute {
        let newList: PlacesEntry[] = [];
        for (let i = 0; i < this.inbetweenPlaces.length; i++) {
            newList.push(this.inbetweenPlaces[i]);
        }
        newList.push(place);
        return new SightseeingRoute(this.from, this.to, newList);
    }

    public shouldBeAdded(place: PlacesEntry): boolean {
        if (this.from.getId() == place.getId()) {
            return false;
        }
        if (this.to.getId() == place.getId()) {
            return false;
        }
        return (GeoFunctions.getDistance(place.getPosition(), this.to.getPosition()) <= this.lastDistance);
    }
}
