import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(seconds: number): string {
        if (seconds < 60) {
            return seconds + "s";
        } else if (seconds < 3600) {
            return Math.floor(seconds / 60).toString() + "min";
        } else {
            let h = Math.floor(seconds / 3600);
            return h.toString() + "h, " + Math.floor((seconds - (h * 3600)) / 60) + "m";
        }
    }
}
