import {Injectable, OnDestroy} from "@angular/core";

@Injectable()
export class SpeechService implements OnDestroy {
    private synth: SpeechSynthesis = null;
    private utterance: SpeechSynthesisUtterance = null;

    constructor() {
        if (this.isAvailable()) {
            this.synth = window.speechSynthesis;
            this.utterance = new SpeechSynthesisUtterance();
            this.initEvents();
        }
    }

    ngOnDestroy(): void {
        console.log("ngOnDetroy");
        if (this.synth) {
            this.synth.cancel();
            console.log("canceled");
        }
    }

    initEvents() {
        window.addEventListener('unload', () => {
            this.synth.cancel();
        });

        this.utterance.onstart = (event) => {
            console.log('speech started');
        };

        this.utterance.onpause = (event) => {
            console.log('speech paused');
        };

        this.utterance.onresume = (event) => {
            console.log('speech resumed');
        };

        this.utterance.onerror = () => {
            console.log('speech error');
        };

        this.utterance.onend = (event) => {
            console.log('speech end');
        };
    }

    private isAvailable() {
        return ('speechSynthesis' in window);
    }

    togglePause(): boolean {
        if (this.synth.paused) {
            this.synth.resume();
            return true;
        } else {
            this.synth.pause();
            return false;
        }
    }

    playText(text: string, language: string) {
        if (!this.isAvailable()) {
            console.log("Speech is not available");
            return;
        }

        if (this.synth.speaking) {
            this.synth.cancel();
            return;
        }

        this.utterance.lang = language;
        this.utterance.rate = 0.9;
        this.utterance.pitch = 1;
        this.utterance.volume = 1;

        this.utterance.text = text.trim();
        console.log("playing: ", text.trim());
        this.synth.speak(this.utterance);
    }
}