import {Component, Input, Output, EventEmitter, SimpleChange, OnChanges, AfterViewInit} from "@angular/core";
import {Observable} from "rxjs";
import {TypeaheadMatch} from "../ng2-bootstrap/typeahead/typeahead-match.class";
import {IRoutable} from "../classes/IRoutable";
import {MyLocation} from "../classes/MyLocation";
import {AddressAutosuggestService} from "../apis/address-autosuggest.service";
import {ElasticsearchPlacesService} from "../apis/elasticsearch-places.service";
import {Messages, SearchMessages} from "../messages/messages";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { LngLat } from 'mapbox-gl';

@Component({
    selector: 'routing-typeahead',
    templateUrl: 'routing-typeahead.component.html',
    styles: [],
})
export class RoutingTypeaheadComponent implements OnChanges, AfterViewInit {
    @Input() customLocation: IRoutable;
    @Input() routable: IRoutable;
    @Input() placeholder: string;
    @Output() routableChange = new EventEmitter<IRoutable>();

    public myLocation: LngLat = null;
    public language: string;
    public messages: SearchMessages;
    public mapCenter: LngLat;

    public dataSource: Observable<TypeaheadMatch[]>;
    public asyncSelected: string = '';
    public typeaheadLoading: boolean = false;
    public typeaheadNoResults: boolean = false;

    constructor(private _globalMapActions: MapStateService,
                private _appState: AppStateService,
                private _addressAutosuggest: AddressAutosuggestService,
                private _branchenbuch: ElasticsearchPlacesService) {
        this.dataSource = new Observable<TypeaheadMatch[]>(this.createResults.bind(this));

        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.search;
            this.language = messages.language;
        });
    }

    ngAfterViewInit(): void {
        this._globalMapActions.myLocationLngLat$.subscribe((location: LngLat) => {
            this.myLocation = location;
        });
        this._globalMapActions.centerLngLat$.subscribe((location: LngLat) => {
            this.mapCenter = location;
        });
    }

    private createResults(observer: any) {
        /*
        let newresults = {
            entries: this.createEntriesFromSuggests([])
        };
        observer.next(newresults);

        if (this.asyncSelected != "") {
            this.mapboxSuggest.getAutoSuggests(this.asyncSelected).then((suggests: any[]) => {
                newresults.entries = this.createEntriesFromSuggests(suggests);
                observer.next(newresults);
            });
        }
        */
        let promise1: Promise<any> = this._branchenbuch.searchPlacesAndCategoriesByQuery(this.asyncSelected, this.language, this.mapCenter),
            promise2: Promise<any> = this._addressAutosuggest.getAutoSuggests(this.asyncSelected);

        Promise.all([promise1, promise2]).then((results: [any, any]) => {
            let newresults = this.calculateResults(this.asyncSelected, results[0], results[1]);
            observer.next(newresults);
        });
    }

    /**
     * Adressen sollen zuerst angezeigt werden, wenn ein Straßenname angegeben wird und es auch keinen Ort gibt, der so anfängt
     *
     * @param {string} query
     * @param {Array} addresses
     * @param {Array} places
     * @returns {boolean}
     */
    private showAdressesBeforePlaces(query: string, addresses: any[], places: any[]): boolean {
        let normalize = (str: string) => {
            str = str.toLowerCase();
            str = str.replace(/stra?(ß|ss)?e?/gi, 'str');
            str = str.replace(/^\s/, '');
            str = str.replace(/\s+$/, '');
            return str;
        };

        let addressHasExactMatch = false,
            placeHasExactMatch = false,
            queryLc = normalize(query);

        places.slice(0, 5).forEach((entry) => {
            if (normalize(entry.name).indexOf(queryLc) == 0) {
                placeHasExactMatch = true;
            }
        });
        addresses.slice(0, 3).forEach((entry) => {
            if (normalize(entry.name).indexOf(queryLc) == 0) {
                addressHasExactMatch = true;
            }
        });

        return (addressHasExactMatch && !placeHasExactMatch);
    }

    private calculateResults(query: string, placesOrLast: any, addressAutosuggests: any) {
        let newresults = {
            entries: [],
        };

        if (this.myLocation) {
            let myLocation = new MyLocation(this.myLocation, false);
            newresults.entries.push(new TypeaheadMatch(
                myLocation,
                myLocation.getRoutingTitle(this.language),
                false,
                'routing-std'
            ));
        }

        if (this.customLocation) {
            newresults.entries.push(new TypeaheadMatch(
                this.customLocation,
                this.customLocation.getRoutingTitle(this.language),
                false,
                'routing-std'
            ))
        }

        if (addressAutosuggests.length > 0) {
            let showAddressesBefore = this.showAdressesBeforePlaces(query, addressAutosuggests, placesOrLast.entries);
            if (showAddressesBefore) {
                newresults.entries.push(new TypeaheadMatch(null, 'Adressen', true, 'head'));
                addressAutosuggests.slice(0, 3).forEach((entry) => {
                    newresults.entries.push(entry);
                });
            }

            if (placesOrLast.entries.length > 0) {
                newresults.entries.push(new TypeaheadMatch(null, 'Suchergebnisse', true, 'head'));
            }
            placesOrLast.entries.slice(0, 5).forEach((entry) => {
                newresults.entries.push(entry);
            });

            if (!showAddressesBefore) {
                newresults.entries.push(new TypeaheadMatch(null, 'Adressen', true, 'head'));
                addressAutosuggests.slice(0, 3).forEach((entry) => {
                    newresults.entries.push(entry);
                });
            }
        } else {
            if (placesOrLast.entries.length > 0) {
                newresults.entries.push(new TypeaheadMatch(null, 'Suchergebnisse', true, 'head'));
            }
            placesOrLast.entries.slice(0, 7).forEach((entry) => {
                newresults.entries.push(entry);
            });
        }
        return newresults;
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }) {
        if (changes.hasOwnProperty('routable')) {
            if (this.routable) {
                this.asyncSelected = this.routable.getRoutingTitle(this.language);
            }
        }
    }

    public clearInput(): void {
        this.asyncSelected = "";
        this.routable = null;
        this.routableChange.emit(null);
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    public typeaheadOnSelect(e: TypeaheadMatch): void {
        if (e.item && e.item.item) {
            this.routable = e.item.item;
            this.routableChange.emit(e.item.item);
        } else {
            this.routable = null;
            this.routableChange.emit(null);
        }
    }

    public onFocus() {
        if (this.routable && ["place", "my_location"].indexOf(this.routable.getClassType()) > -1) {
            this.asyncSelected = "";
        }
    }

    public onBlur() {
        if (this.asyncSelected == "" && this.routable) {
            this.asyncSelected = this.routable.getRoutingTitle(this.language);
        }
    }
}
