
export class TypeaheadMatch {

  public constructor(public item:any, public value:string = item, private header:boolean = false, private type?: string) {
  }

  public isHeader():boolean {
    return this.header;
  }

  public toString():string {
    return this.value;
  }
}
