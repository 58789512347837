import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {PlacesEntry} from "../classes/PlacesEntry";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Marker, Map, MarkerOptions } from 'mapbox-gl';


const MIN_ZOOM = 12;

//const MOBILITY_MIN_ZOOM = 14; // Synch map-carsharing, map-charging, map-bikesharing and map-mvv
//const MOBILITY_ALL_MIN_ZOOM = 17; // Synch map-carsharing, map-charging,map-bikesharing and map-mvv

@Injectable()
export class ChargingStationsLayerService {
    private map: Map;
    private foundChargingStations: PlacesEntry[] = [];

    private chargingActiveData: PlacesEntry = null;
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private markers: { [id: string]: Marker } = {};
    //private Z_INDEX_OFFSET: 0;

    constructor(private _mapState: MapStateService,
                private _appState: AppStateService,
                private _mvgState: MVGStateService,
                private _routingService: RoutingService,
                private _epBBLoader: EpBoundingboxLoaderService) {
    }

    public init(map: Map) {
        this.map = map;

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && ['charging_station', 'erdgas'].indexOf(shortInfoComponentActive.getClassType()) !== -1) {
                this.chargingActiveData = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.chargingActiveData = null;
            }
            this.markerCollection.setActiveElement(this.chargingActiveData);
        });

        this._epBBLoader.setListenerCategories('charging', []).subscribe((data) => {
            this.foundChargingStations = data.places;
            this._appState.placesLoaded(
                'charging_station',
                this.foundChargingStations.filter(place => place.getClassType() === 'charging_station')
            );
            this._appState.placesLoaded(
                'erdgas',
                this.foundChargingStations.filter(place => place.getClassType() === 'erdgas')
            );
            this.showCurrentMarkers();
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.chargingStationsActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.chargingStationsActive$.subscribe(this.showCurrentMarkers.bind(this));

        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        if (this._mvgState.chargingStationsActive$.getValue()) {
            if (this._mapState.zoom$.getValue() >= MIN_ZOOM) {
                this._epBBLoader.setListenerCategories('charging', ['mv.charging', 'gg.erdgas']);
            } else {
                this._epBBLoader.setListenerCategories('charging', []);
            }
        } else {
            this._epBBLoader.setListenerCategories('charging', []);
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }
    }

    public addInactiveMarker(station: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        icon.classList.add('charging-marker');
        icon.classList.add('inactive');

        icon.src = null;
        for (let i in station.categories) {
            if (station.categories[i].id == 'mv.charging') {
                // Eine Ladesäule kann sowohl available als auch nonavailable haben; ersteres hat Vorrang
                if (station.categories.filter(cat => cat.id === 'mv.charging.available').length > 0) {
                    icon.src = 'mvgmore/ic_marker_ladestation@3x.png';
                    icon.style.width = '35px';
                    icon.style.height = '44px';
                } else {
                    icon.src = 'mvgmore/ic_marker_ladestation_occupied@3x.png';
                    icon.style.width = '35px';
                    icon.style.height = '45px';
                }
            }

            if (station.categories[i].id === 'gg.erdgas.available') {
                icon.src = 'mvgmore/ic_cnggreen.png';
                icon.style.width = '30px';
                icon.style.height = '42px';
            }
            if (station.categories[i].id === 'gg.erdgas.notavailable') {
                icon.src = 'mvgmore/ic_cnggreen_empty.png';
                icon.style.width = '30px';
                icon.style.height = '42px';
            }
        }

        if (icon.src === null) {
            return;
        }
        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(station);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(station.position);
        marker.addTo(this.map);
        this.markers[station.id] = marker;
    }


    public addActiveMarker(station: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom-left';
        opts.offset = [-10, 5];
        icon.classList.add('charging-marker');
        icon.classList.add('active');

        icon.src = null;
        for (let i in station.categories) {
            if (station.categories[i].id == 'mv.charging') {
                // Eine Ladesäule kann sowohl available als auch nonavailable haben; ersteres hat Vorrang
                if (station.categories.filter(cat => cat.id === 'mv.charging.available').length > 0) {
                    icon.src = 'mvgmore/ic_marker_ladestation_selected@3x.png';
                    icon.style.width = '45px';
                    icon.style.height = '49px';
                } else {
                    icon.src = 'mvgmore/ic_marker_ladestation_occupied_selected@3x.png';
                    icon.style.width = '45px';
                    icon.style.height = '49px';
                }
            }
            if (station.categories[i].id === 'gg.erdgas.available') {
                icon.src = 'mvgmore/ic_cnggreen_selected.png';
                icon.style.width = '40px';
                icon.style.height = '47px';
            }
            if (station.categories[i].id === 'gg.erdgas.notavailable') {
                icon.src = 'mvgmore/ic_cnggreen_empty_selected.png';
                icon.style.width = '40px';
                icon.style.height = '47px';
            }
        }

        if (icon.src === null) {
            return;
        }
        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(station);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(station.position);
        marker.addTo(this.map);
        this.markers[station.id] = marker;
    }


    private showCurrentMarkers() {
        let active: boolean = this._mvgState.chargingStationsActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue()) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && ['charging_station', 'erdgas'].indexOf(from.getClassType()) !== -1) {
                collection.push(<PlacesEntry>from);
            }
            if (to && ['charging_station', 'erdgas'].indexOf(to.getClassType()) !== -1) {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (active && zoom >= MIN_ZOOM) {
                collection = this.foundChargingStations;
            }
        }
        if (this.chargingActiveData && collection.filter(place => place.id == this.chargingActiveData.id).length === 0) {
            collection.push(this.chargingActiveData);
        }
        this.markerCollection.setNewElementCollection(collection);
    }
}
