<div class="mvg-entry-list">
    <div class="departure-head head">
        <div *ngIf="stattautoCars === null">{{ messages.stattauto_loading }}</div>
    </div>
    <ol class="mvg-departure-list entry-list">
        <li *ngFor="let car of stattautoCars">
            <div class="license">
                {{ car.licensePlate }}
            </div>
            <div class="destination">
                {{ car.carType }}
            </div>
        </li>
    </ol>
</div>