import {IShortInfoComponentContent} from "./IShortInfoComponentContent";
import {IRoutable} from "./IRoutable";
import { LngLat } from 'mapbox-gl';

export class Lamppost implements IShortInfoComponentContent, IRoutable {
    public id: string;
    public title: string;
    public position: LngLat;
    public capabilities: string[] = [];
    public isBig: boolean;
    public hasWifi: boolean = false;
    public connectedSensorIds: string[] = [];

    constructor(data: any) {
        let coordinates = data.location.coordinates;
        this.id = data.id;
        this.title = data.name;
        this.position = new LngLat(coordinates[0], coordinates[1]);
        this.hasWifi = (data.properties && data.properties.wifi == true);
        this.isBig = (data.properties && data.properties.type && data.properties.type.indexOf('LM10') > -1);
        this.capabilities = [];
        if (data.connectedSensors) {
            data.connectedSensors.forEach((sensor) => {
                this.connectedSensorIds.push(sensor.connectedSensorId);
                if (sensor.properties && sensor.properties.measurementTypes) {
                    sensor.properties.measurementTypes.map(Lamppost.translateApiMeasurementToCapability).forEach((cap) => {
                        this.capabilities.push(cap);
                    });
                }
            });
        }
    }

    public static getCapabilityIconUrl(capability: string): string {
        switch (capability) {
            case 'finedust25':
                return './images/Icons-Wetter-Feinstaub2-5.svg';
            case 'finedust100':
                return './images/Icons-Wetter-Feinstaub10.svg';
            case 'no2':
                return './images/Icons-Wetter-NO2.svg';
            case 'so2':
                return './images/Icons-Wetter-SO2.svg';
            case 'co':
                return './images/Icons-Wetter-CO.svg';
            case 'ozone':
                return './images/Icons-Wetter-O3.svg';
            case 'temperature':
                return './images/Icons-Wetter-Temperatur.svg';
            case 'humidity':
                return './images/Icons-Wetter-Luftfeuchte.svg';
            case 'pressure':
                return './images/Icons-Wetter-Druck.svg';
            case 'windspeed':
                return './images/Icons-Wetter-Wind.svg';
            case 'rain':
                return './images/Icons-Wetter-Regen.svg';
            default:
                return '';
        }
    }

    public static getCapabilityName(capability: string, language: string): string {
        switch (capability) {
            case 'finedust25':
                return (language == 'de' ? '' : '');
            case 'finedust100':
                return (language == 'de' ? '' : '');
            case 'no2':
                return (language == 'de' ? '' : '');
            case 'so2':
                return (language == 'de' ? '' : '');
            case 'co':
                return (language == 'de' ? '' : '');
            case 'ozone':
                return (language == 'de' ? '' : '');
            case 'temperature':
                return (language == 'de' ? '' : '');
            case 'humidity':
                return (language == 'de' ? '' : '');
            case 'pressure':
                return (language == 'de' ? '' : '');
            case 'winddirection':
                return (language == 'de' ? '' : '');
            case 'windspeed':
                return (language == 'de' ? '' : '');
            case 'rain':
                return (language == 'de' ? '' : '');
            default:
                return '';
        }
    }

    public static translateApiMeasurementToCapability(measurement: string): string {
        switch (measurement) {
            case 'PM2,5 (micrograms per cubic meter)':
                return 'finedust25';
            case 'PM10 (micrograms per cubic meter)':
                return 'finedust100';
            case 'NO2 (micrograms per cubic meter)':
                return 'no2';
            case 'SO2 (micrograms per cubic meter)':
                return 'so2';
            case 'CO (micrograms per cubic meter)':
                return 'co';
            case 'Ozone (micrograms per cubic meter)':
                return 'ozone';
            case 'Temperature (degree celsius)':
                return 'temperature';
            case 'Relative Humidity (percent)':
                return 'humidity';
            case 'Air Pressure (mbar)':
                return 'pressure';
            case 'Wind Direction (Degree)':
                return 'winddirection';
            case 'Wind Speed (m/s)':
                return 'windspeed';
            case 'Rain Intensity (mm/h)':
                return 'rain';
            default:
                return '';
        }
    }

    getClassType(): string {
        return 'lamppost';
    }

    getId(): string {
        return this.id;
    }

    getShortInfoImage(): string {
        let iconName = 'lamppost_';
        iconName += (this.isBig ? '10m' : '3m');
        if (this.hasWifi) {
            iconName += '_wlan';
        }
        if (this.capabilities.length > 0) {
            iconName += '_sensors';
        }

        return iconName + '.svg';
    }

    getShortInfoAddress(): string {
        return this.title;
    }

    getShortInfoTitle(): string {
        return this.title;
    }

    getPosition(): LngLat {
        return this.position;
    }

    getUrlHash(): string {
        return "lamppost/" + this.id;
    }

    getRoutableObject(): IRoutable {
        return this;
    }

    getRoutingTitle(): string {
        return "Lichtmast: " + this.title;
    }

    needsRoutingPlaceholderIcon(): boolean {
        return false;
    }

    getMvgRoutingString(): string {
        return this.getPosition().lat + "," + this.getPosition().lng;
    }
}
