import {IShortInfoComponentContent} from "./IShortInfoComponentContent";
import {IRoutable} from "./IRoutable";
import { LngLat } from 'mapbox-gl';

export class SmartCityObject implements IShortInfoComponentContent, IRoutable {
    constructor(public type: string,
                public id: string,
                public title: string,
                public address: string,
                public position: LngLat) {
    }

    getClassType(): string {
        return 'smartcity';
    }

    getId(): string {
        return this.id;
    }

    getShortInfoImage(): string {
        return '';
    }

    getShortInfoAddress(): string {
        return this.address;
    }

    getShortInfoTitle(): string {
        return this.title;
    }

    getPosition(): LngLat {
        return this.position;
    }

    getUrlHash(): string {
        return "smartcity/" + this.id;
    }

    getRoutingTitle(): string {
        return this.title;
    }

    getRoutableObject(): IRoutable {
        return this;
    }

    needsRoutingPlaceholderIcon(): boolean {
        return false;
    }
    
    getMvgRoutingString(): string {
        return this.getPosition().lat + "," + this.getPosition().lng;
    }
}
