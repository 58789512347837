import {PlacesDetails} from "./PlacesDetails";

export class PlacesDetailsEScooter extends PlacesDetails {
    public getBatteryLevel(): number {
        if (this.payload && this.payload.batteryLevel) {
            return this.payload.batteryLevel;
        } else {
            return null;
        }
    }
}
