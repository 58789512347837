import {IMapObject} from "./IMapObject";

export class MarkerCollectionStore<EL extends IMapObject> {
    private collection: { [id: string]: EL } = {};
    private activeElement: EL = null;

    constructor(private addInactiveElCb: (element: EL) => void,
                private delInactiveElCb: (element: EL) => void,
                private addActiveElCb: (element: EL) => void,
                private delActiveElCb: (element: EL) => void) {
    }

    public setNewElementCollection(elements: EL[]) {
        let newIds: string[] = [];
        elements.forEach((element: EL) => {
            let id: string = element.id.toString();
            newIds.push(id);
            if (this.collection[id] === undefined) {
                if (this.activeElement && this.activeElement.id === id) {
                    this.addActiveElCb(element);
                } else {
                    this.addInactiveElCb(element);
                }
                this.collection[id] = element;
            }
        });

        if (this.activeElement && newIds.indexOf(this.activeElement.id) === -1) {
            newIds.push(this.activeElement.id);
        }

        for (let id in this.collection) {
            if (newIds.indexOf(id) == -1) {
                this.delInactiveElCb(this.collection[id]);
                delete(this.collection[id]);
            }
        }
    }

    public setActiveElement(element: EL) {
        if (this.activeElement && element && this.activeElement.id == element.id) {
            return;
        }

        if (this.activeElement && (element === null || this.activeElement.id != element.id)) {
            this.delActiveElCb(this.activeElement);
            this.addInactiveElCb(this.activeElement);
            this.activeElement = null;
        }

        if (element === null) {
            return;
        }

        if (this.collection[element.id] !== undefined) {
            let inactive = this.collection[element.id];
            this.delInactiveElCb(inactive);
        } else {
            this.collection[element.id] = element;
        }
        this.addActiveElCb(element);
        this.activeElement = element;
    }
}
