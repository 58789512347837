<div class="holder" [class.revealed]="revealed" (swipeup)="reveal()" (swipedown)="hide()" *ngIf="!hidden">
	<button type="button" class="reset-button" (click)="resetCategories()">Reset</button>
	
    <header (click)="toggle()">
        <h2>{{ messages.exploreTitle }}</h2>
    </header>
	
    <ul class="category_list">
        <li (click)="selectCategory('tourism')" [class.active]="categorySelected == 'tourism'">
	        <img [src]="categorySelected == 'tourism' ? '/images/Ansicht-Tourismus-High.svg' : '/images/Ansicht-Tourismus-Low.svg'"  alt="" />
            <span>{{ messages.topcatTourism }}</span>
        </li>
        <li (click)="selectCategory('mobility')" [class.active]="categorySelected == 'mobility'">
	        <img [src]="categorySelected == 'mobility' ? '/images/Ansicht-Verkehr-High.svg' : '/images/Ansicht-Verkehr-Low.svg'"  alt="" />
	        <span>{{ messages.topcatMobility }}</span>
        </li>
        <li (click)="selectCategory('smart')" [class.active]="categorySelected == 'smart'">
	        <img [src]="categorySelected == 'smart' ? '/images/Ansicht-SmartServices_High.svg' : '/images/Ansicht-SmartServices-Low.svg'"  alt="" />
	        <span>{{ messages.topcatSmartServices }}</span>
        </li>
    </ul>
	
    <div *ngIf="categorySelected == 'tourism'" class="item-list__holder">
        <ul class="item_list">
            <li (click)="selectItem('bb.2')" [class.active]="isItemSelected('bb.2')">
	            <img [src]="isItemSelected('bb.2') ? '/images/y_sehenswuerdigkeiten.svg' : '/images/sehenswuerdigkeiten_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catSights }}</span>
            </li>
            <li (click)="selectItem('bb.27400')" [class.active]="isItemSelected('bb.27400')">
	            <img [src]="isItemSelected('bb.27400') ? '/images/y_museen.svg' : '/images/museen_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catMuseum }}</span>
            </li>
            <li (click)="selectItem('bb.27416')" [class.active]="isItemSelected('bb.27416')">
	            <img [src]="isItemSelected('bb.27416') ? '/images/y_bauwerke.svg' : '/images/bauwerke_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catBuildings }}</span>
            </li>
            <li (click)="selectItem('bb.27482')" [class.active]="isItemSelected('bb.27482')">
	            <img [src]="isItemSelected('bb.27482') ? '/images/y_parks.svg' : '/images/sehenswuerdigkeiten_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catParks }}</span>
            </li>
        </ul>
    </div>
	
    <div *ngIf="categorySelected == 'mobility'" class="item-list__holder">
        <ul class="item_list">
            <li (click)="selectItem('publictransport')" [class.active]="isItemSelected('publictransport')">
	            <img [src]="isItemSelected('publictransport') ? '/images/t_oepnv.svg' : '/images/oepnv_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catPublicTransport }}</span>
            </li>
            <li (click)="selectItem('bikesharing')" [class.active]="isItemSelected('bikesharing')">
	            <img [src]="isItemSelected('bikesharing') ? '/images/t_mvg_rad.svg' : '/images/mvg_rad_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catBikesharing }}</span>
            </li>
            <li (click)="selectItem('charging')" [class.active]="isItemSelected('charging')">
	            <img [src]="isItemSelected('charging') ? '/images/t_ladesaeule.svg' : '/images/ladesaeule_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catCharging }}</span>
            </li>
            <li (click)="selectItem('carsharing')" [class.active]="isItemSelected('carsharing')">
	            <img [src]="isItemSelected('carsharing') ? '/images/t_carsharing.svg' : '/images/carsharing_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catCarsharing }}</span>
            </li>
            <li (click)="selectItem('taxi')" [class.active]="isItemSelected('taxi')">
	            <img [src]="isItemSelected('taxi') ? '/images/t_taxi.svg' : '/images/taxi_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catTaxi }}</span>
            </li>
        </ul>
    </div>
    <div *ngIf="categorySelected == 'smart'" class="item-list__holder">
        <ul class="item_list">
            <li (click)="selectItem('lampposts')" [class.active]="isItemSelected('lampposts')">
	            <img [src]="isItemSelected('lampposts') ? '/images/g_mwlan-footer-lichtmasten.svg' : '/images/lichtmasten_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catLampposts }}</span>
            </li>
            <li (click)="selectItem('bb.27445')" [class.active]="isItemSelected('bb.27445')">
	            <img [src]="isItemSelected('bb.27445') ? '/images/g_mwlan-footer-m-wlan.svg' : '/images/mwlan_inaktiv-01.svg'"  alt="" />
                <span>{{ messages.catMWlan }}</span>
            </li>

        </ul>
    </div>
</div>
