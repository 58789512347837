import {Injectable} from "@angular/core";
import {DEFAULT_TILES, LANGUAGES, RoutingTransportation} from "../interfaces";

@Injectable()
export class SettingsService {

    public static ENABLED = false;

    public setTileset(tile: number): void {
        if (SettingsService.ENABLED) {
            localStorage.setItem('tileset', tile.toString());
        }
    }

    public getTileset(): number {
        const defaultValue = DEFAULT_TILES;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let tiles = localStorage.getItem('tileset');
        if (tiles !== null) {
            return parseInt(tiles);
        } else {
            return defaultValue;
        }
    }


    public setPublicTransportActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("mvg_active", (active ? "1" : "0"));
        }
    }

    public getPublicTransportActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('mvg_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }


    public setBikeActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("bike_active", (active ? "1" : "0"));
        }
    }

    public getBikeActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('bike_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }



    public setEScooterActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("escooter_active", (active ? "1" : "0"));
        }
    }

    public getEScooterActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('escooter_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }


    public setCarActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("car_active", (active ? "1" : "0"));
        }
    }

    public getCarActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('car_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }


    public setChargingStationsActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("charging_active", (active ? "1" : "0"));
        }
    }

    public getChargingStationsActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('charging_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }


    public setRoutingTransportation(transportation: RoutingTransportation) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("routing_transportation", transportation.toString());
        }
    }

    public getRoutingTransportation(): RoutingTransportation {
        const defaultValue = RoutingTransportation.WALKING;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let transport = localStorage.getItem("routing_transportation");
        if (transport !== null) {
            return <RoutingTransportation>parseInt(transport);
        } else {
            return defaultValue;
        }
    }


    public setSmartCityActive(active: boolean) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("smartcity_active", (active ? "1" : "0"));
        }
    }

    public getSmartCityActive(): boolean {
        const defaultValue = true;
        if (!SettingsService.ENABLED) {
            return defaultValue;
        }
        let active = localStorage.getItem('smartcity_active');
        if (active !== null) {
            return (active == "1");
        } else {
            return defaultValue;
        }
    }


    public setLanguage(language: string) {
        if (SettingsService.ENABLED) {
            localStorage.setItem("language", language);
        }
    }

    private getBrowserLanguage(): string {
        return "de";
        /*
        if (navigator['languages']) {
            for (let i = 0; i < navigator['languages'].length; i++) {
                let first = navigator['languages'][i].split('-')[0].toLowerCase();
                if (LANGUAGES.indexOf(first) > -1) {
                    return first;
                }
            }
        }
        if (navigator.language) {
            let first = navigator.language.split('-')[0].toLowerCase();
            if (LANGUAGES.indexOf(first) > -1) {
                return first;
            }
        }

        return null;
        */
    }

    public getLanguage(): string {
        if (!SettingsService.ENABLED) {
            return this.getBrowserLanguage();
        }
        let language = localStorage.getItem('language');
        if (language !== null && LANGUAGES.indexOf(language) > -1) {
            return language;
        } else {
            return this.getBrowserLanguage();
        }
    }
}
