<div class="map-gl" id="map-gl"></div>

<!--
<div class="zoom-in-out">
    <button class="btn btn-md btn-default" type="button" (click)="onZoomIn()" [disabled]="zoomInDisabled">+</button>
    <button class="btn btn-md btn-default" type="button" (click)="onZoomOut()" [disabled]="zoomOutDisabled">-</button>
</div>
-->

<div class="orientation-button" (click)="onResetOrientation()">
    <button class="btn btn-md btn-default" type="button" (click)="onResetOrientation()">
        <div [style.transform]="'rotateX(' + currPitch + 'deg)'">
            <img src="images/nordung-01.svg" alt="Nach Norden ausrichten" class="icon"
                 [style.transform]="'rotate(' + (-1 * currBearing) + 'deg)'"
            >
        </div>
    </button>
</div>
