import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {AddressAutosuggestEntry} from "../classes/AddressAutosuggestEntry";
import {HttpClient, HttpParams} from "@angular/common/http";
import { LngLat } from 'mapbox-gl';

@Injectable()
export class AddressAutosuggestService {
    private url = 'https://muenchenapis.de/proxies/fahrinfo/v1/proxy.php';

    constructor(private http: HttpClient) {
    }

    loadFirstAutosuggest(query: string): Observable<AddressAutosuggestEntry> {
        let params = new HttpParams()
            .set('query', query)
            .set('limit', '20')
            .set('stations', '0');
        return this.http.get(this.url, {params: params}).pipe(map(body => {
            const locations = body['locations'].filter(location => !location['poi']);
            if (locations.length === 0) {
                throw 'Nothing found';
            } else {
                const location = locations[0];
                let name = location['street'] + " ";
                if (location['houseNumber']) {
                    name += location['houseNumber'];
                }

                return new AddressAutosuggestEntry(
                    new LngLat(location.center[0], location.center[1]), location.id, name
                );
            }
        }));
    }

    getAutoSuggests(query: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let params = new HttpParams()
                .set('query', query)
                .set('limit', '20')
                .set('stations', '0');
            this.http.get(this.url, {params: params}).pipe(map(body => {
                const locations = body['locations'].filter(location => !location['poi']);

                return locations.map(location => {
                    let name = location['street'] + " ";
                    if (location['houseNumber']) {
                        name += location['houseNumber'];
                    }

                    let address: AddressAutosuggestEntry = new AddressAutosuggestEntry(
                        new LngLat(location['longitude'], location['latitude']), location['street'], name
                    );
                    return {
                        'type': 'address-autosuggest',
                        'id': location['street'],
                        'name': name,
                        'item': address
                    };
                });
            })).subscribe((results: any) => {
                resolve(results);
            });
        });
    }

    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error['message'] ? error['message'] : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }
}
