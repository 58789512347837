import {PlacesDetails} from "./PlacesDetails";

export interface ChargingStation {
    name: string;
    format: string;
    standard: string;
    id: string;
    available: boolean;
}

export class MobilityStationDetails extends PlacesDetails {
    public mobilityEntries: PlacesDetails[];

    private _publicTransport: PlacesDetails = null;
    private _all_bikes: PlacesDetails[] = null;
    private _bikes: PlacesDetails[] = null;
    private _etrikes: PlacesDetails[] = null;
    private _pedelecs: PlacesDetails[] = null;
    private _cars: PlacesDetails[] = null;
    private _chargingPoints: PlacesDetails[] = null;
    private _districtbox: PlacesDetails[] = null;

    public static AREA_OUTLINES: { [id: string]: [number, number][] } = {
        "mv.mobilityWEST_KREU": [[11.441493393388646, 48.14847928861537], [11.442901116742007, 48.14767922158937], [11.441731319890364, 48.14764971979287], [11.441493393388646, 48.14847928861537]],
        "mv.mobilityWIES_STRA": [[11.413590789690936, 48.14568317352819], [11.413082321062824, 48.14655386271302], [11.414896159246016, 48.14676056798269], [11.415150384913185, 48.14587666267168], [11.413590789690936, 48.14568317352819]],
        "mv.mobilityAUBING": [[11.414102713984951, 48.1554689126911], [11.413804733643016, 48.155273332705264], [11.413514760907718, 48.1552684504512], [11.412097481672856, 48.15595575651671], [11.412120590528021, 48.15616015066896], [11.416007218594483, 48.155785008766514], [11.415890472657484, 48.15531117651771], [11.414102713984951, 48.1554689126911]],
        "mv.mobilityNEUAUBING": [[11.423702106057366, 48.143488923407965], [11.424079651739556, 48.142479645011605], [11.422667503978422, 48.14229893284294], [11.42240681260898, 48.14331900718682], [11.423702106057366, 48.143488923407965]],
        "mv.mobilityMAIN_STRA": [[11.434761335009638, 48.14882432680592], [11.433361990354127, 48.14884808568658], [11.433353941381458, 48.149580035938044], [11.434740442257256, 48.149801345513374], [11.434761335009638, 48.14882432680592]],
        "mv.mobilityFREI_STRA": [[11.419938673168794, 48.147695129467124], [11.420123083890036, 48.14713089439319], [11.419184230484586, 48.14692528722017], [11.41900416810871, 48.147554230291156], [11.419938673168794, 48.147695129467124]],
        "mv.mobilityLEIE_STRA": [[11.427425250484822, 48.15521594563428], [11.428800757283868, 48.15552238683673], [11.429639894589526, 48.154181731872825], [11.427577169210009, 48.15436555118207], [11.427425250484822, 48.15521594563428]],
        "mv.mobilityFREIHAM": [[11.4102858407406, 48.13985972067231], [11.411149322138533, 48.13996968605665], [11.411668687765186, 48.13903267029433], [11.410953618554872, 48.138845162614935], [11.4102858407406, 48.13985972067231]],
        "mv.mobilityHARRAS": [[11.541978553295767, 48.11713086710938], [11.542050208025074, 48.116504702741146], [11.541231289435496, 48.11654229648111], [11.541251080853655, 48.1171756341419], [11.541978553295767, 48.11713086710938]],
        "mv.mobilityAM_GLOCK": [[11.567725264088702, 48.12664250524617], [11.567841188581985, 48.12615948594436], [11.566380781678967, 48.125663729284476], [11.566280720187578, 48.12667792612208], [11.567725264088702, 48.12664250524617]],
        "mv.mobilityGOET_NORD": [[11.558341457660541, 48.12902074704008], [11.557703121136194, 48.1293281151926], [11.558840500154458, 48.129836362440734], [11.559100689512821, 48.12944808808462], [11.558954282619403, 48.12901969243433], [11.558341457660541, 48.12902074704008]]
    };

    private initProperties() {
        if (this._bikes !== null) {
            return;
        }

        this._all_bikes = [];
        this._bikes = [];
        this._etrikes = [];
        this._pedelecs = [];
        this._cars = [];
        this._chargingPoints = [];
        this._districtbox = [];

        this.mobilityEntries.forEach((entry) => {
            entry.categories.forEach((cat) => {
                switch (cat.id) {
                    case 'mv.public':
                        this._publicTransport = entry;
                        break;
                    case 'mv.bike':
                        this._bikes.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.etrike':
                        this._etrikes.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.pedelec':
                        this._pedelecs.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.car':
                        this._cars.push(entry);
                        break;
                    case 'mv.charging':
                        this._chargingPoints.push(entry);
                        break;
                    case 'mv.districtbox':
                        this._districtbox.push(entry);
                        break;
                }
            })
        });
    }

    public hasPublicTransport(): boolean {
        this.initProperties();
        return this._publicTransport !== null;
    }

    public hasAnyBike(): boolean {
        this.initProperties();
        return this._all_bikes.length > 0;
    }

    public hasBikes(): boolean {
        this.initProperties();
        return this._bikes.length > 0;
    }

    public hasPedelecs(): boolean {
        this.initProperties();
        return this._pedelecs.length > 0;
    }

    public hasEtrikes(): boolean {
        this.initProperties();
        return this._etrikes.length > 0;
    }

    public hasCars(): boolean {
        this.initProperties();
        return this._cars.length > 0;
    }

    public hasChargingPoints(): boolean {
        this.initProperties();
        return this._chargingPoints.length > 0;
    }

    public hasDistrictbox(): boolean {
        this.initProperties();
        return this._districtbox.length > 0;
    }

    public getPublicTransportStation(): PlacesDetails {
        this.initProperties();
        return this._publicTransport;
    }

    public getStaticBikes(): PlacesDetails[] {
        this.initProperties();
        return this._bikes.filter((bike) => {
            return bike.categories.filter(cat => cat.id === 'mv.bike.station').length > 0;
        });
    }

    public getPedelecs(): PlacesDetails[] {
        this.initProperties();
        return this._pedelecs;
    }

    public getEtrikes(): PlacesDetails[] {
        this.initProperties();
        return this._etrikes;
    }

    public getDistrictbox(): PlacesDetails[] {
        this.initProperties();
        return this._districtbox;
    }

    public getVolatileBikes(): PlacesDetails[] {
        this.initProperties();
        return this._bikes.filter((bike) => {
            return bike.categories.filter(cat => cat.id === 'mv.bike.free').length > 0;
        });
    }

    public getStaticCars(): PlacesDetails[] {
        this.initProperties();
        return this._cars.filter((car) => {
            return car.categories.filter(cat => cat.id === 'mv.car.stattauto').length > 0;
        });
    }

    public getVolatileCars(): PlacesDetails[] {
        this.initProperties();
        return this._cars.filter((car) => {
            return car.categories.filter(cat => cat.id === 'mv.car.stattauto').length === 0;
        });
    }

    public static parseChargingStationPayload(entry: PlacesDetails): ChargingStation[] {
        if (entry.payload === undefined || entry.payload.chargePoints === undefined) {
            return [];
        }

        let stations: ChargingStation[] = [];
        entry.payload.chargePoints.forEach((chargepoint) => {
            if (chargepoint.connectors === undefined) {
                return;
            }
            chargepoint.connectors.forEach((connector) => {
                stations.push({
                    id: chargepoint.id,
                    name: entry.getName(),
                    format: connector.format,
                    standard: connector.standard,
                    available: (chargepoint.status.toLowerCase() === 'available'),
                });
            });
        });
        return stations;
    }

    public getChargingStations(): PlacesDetails[] {
        this.initProperties();
        return this._chargingPoints;
    }
}
